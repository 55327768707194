import React from "react";
import { MdOutlineClose } from "react-icons/md";
import OfferItemImg from "../assets/img/bloggerbuy/offer-item.png";
import { LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,  TelegramShareButton,  TelegramIcon, ViberShareButton, ViberIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';


const SpecialOfferPopup = (props) => {


  return (
    <section className="overflow-hidden relative sm:-[20px]">
      <div className="container">
        <div className="Background3 flex md:flex-col p-[40px] md:p-[20px] bg-[#BD9EFF33]">
          <MdOutlineClose className="ButtonText absolute cursor-pointer right-7 top-3 text-3xl sm:text-2xl" onClick={() => props.closePopUp()} />
          <img
            className="object-cover w-[200px] h-[200px] md:w-[120px] md:h-[120px] mr-[45px] md:mx-auto"
            src={`https://directus.${window.location.host}/assets/${props.offer.image}`}
            alt=""
          />
          <div className="mt-[20px]">
            <h3 className="font-semibold text-xl mb-12  md:mb-5 text-left max-w-[270px]">
              <T>{props.offer.name}</T>
            </h3>
            <p className="text-lightGray mb-5 text-left text-color-theme max-w-[580px]">
              <T>{props.offer.text}</T>
              {" "}
            </p>
            <div className="flex mr-[60px] sm:mr-[20px] sm:flex-wrap mb-5">
              <div className="font-semibold mr-[5px] mt-[4px]"><T uk = "Поширити:" en = "Share:">Поделиться:</T></div>
              <div className="place-content-center mr-[5px]">
              <FacebookShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                quote={props.offer.name}
                hashtag="#personalshares"
              >
                <FacebookIcon size={32} round></FacebookIcon>
              </FacebookShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <TwitterShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                quote={props.offer.name}
                hashtag="#personalshares"
              >
                <TwitterIcon size={32} round></TwitterIcon>
              </TwitterShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <ViberShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                title={props.offer.name}
              >
                <ViberIcon size={32} round></ViberIcon>
              </ViberShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <WhatsappShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                title={props.offer.name}
              >
                <WhatsappIcon size={32} round></WhatsappIcon>
              </WhatsappShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <RedditShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                title={props.offer.name}
              >
                <RedditIcon size={32} round></RedditIcon>
              </RedditShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <LinkedinShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                title={props.offer.name}
                source="PersonalShares"
              >
                <LinkedinIcon size={32} round></LinkedinIcon>
              </LinkedinShareButton>
              </div>
              <div className="place-content-center">
              <TelegramShareButton
                url={`https://personalshares.com/bloggerbuy/${props.personId}?scrollTo=option-${props.offer.id}`}
                title={props.offer.name}
              >
                <TelegramIcon size={32} round></TelegramIcon>
              </TelegramShareButton>
              </div>
            </div>
              <button className="ButtonColor AlternativeButtonText btn bg-primary w-[140px] h-[39px] text-sm md:mx-auto pt-[10px]" onClick={() => props.closePopUp()}><T uk = "ЗАКРИТИ" en = "CLOSE">ЗАКРЫТЬ</T></button>
            </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialOfferPopup;
