import React, { useRef , useState, useEffect, useContext} from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { LanguageSwitcher } from '../Translation'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Registration from "../pages/Registration";
import Singin from "../pages/Singin";
import Select from "react-select";
import ThemeSelect from "../components/ThemeSelect"

import { MdOutlineClose } from "react-icons/md";

import {LoginContext} from "../App.js"

const HeaderMobile = ({ menuList, showMenu, setShowMenu }) => {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);

  const ref = useRef();
  const reff = useRef();
  const closeTooltip = () => ref.current.close();
  const closeTooltipp = () => reff.current.close();
  const location = useLocation()
  React.useEffect(() => {
    //if user is not logged in
    if (!isLoggedIn) {
      closeTooltip();
      closeTooltipp();
  }
    setShowMenu(false);
  }, [location])

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      height: "28px",
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isSelected ? "#ffffff" : "#757575",
      backgroundColor: state.isSelected ? "transparent" : "transparent",
      borderRadius: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#757575",
      backgroundColor: "transparent",
    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      color: "#757575",
      borderColor: "none",
      borderWidth: "0",
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 0,
      paddingTop: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8) !important",
      backdropFilter: "blur(4px) !important",
      overflow: "scroll",
    }),
    input: (provided) => ({
      ...provided,
      color: "transparent",
      boxShadow: "none",
    }),
  };

  return (
    <div
      className={`${
        showMenu ? "translate-x-0" : "translate-x-full"
      } fixed top-0 duration-300 bg-black/90 w-full h-screen z-50`}
    >
      <MdOutlineClose
        onClick={() => setShowMenu(false)}
        className="Logo absolute cursor-pointer right-5 top-5 text-5xl sm:text-4xl"
      />
      <ul className="flex flex-col justify-center items-center h-full gap-7 mt-[-50px]">
      <div className="flex">
          <ThemeSelect customStyles={customStyles}/>
          <LanguageSwitcher customStyles = {customStyles}/>
        </div>
        {menuList.map((item) => (
          <li key={item.title}>
            <Link
              onClick={() => setShowMenu(false)}
              className="AlternativeText text-3xl duration-300 hover:text-primary sm:text-xl"
              to={item.url}
            >
              <T>{item.title}</T>
            </Link>
          </li>
        ))}
        <li>
          <a
            className="AlternativeText text-3xl duration-300 hover:text-primary sm:text-xl"
            href={Blog("tag/baza-znanii")}
            rel="noopener noreferrer"
          >
            <T uk = "Як користуватись" en = "How to use">Как пользоваться</T>
          </a>
        </li>
        <li>
          <a
            className="AlternativeText text-3xl duration-300 hover:text-primary sm:text-xl"
            href={Blog("tag/news")}
            rel="noopener noreferrer"
          >
            <T uk = "Новини" en = "News">Новости</T>
          </a>
        </li>
        <div className="flex mt-[10px]">
          {
            (!isLoggedIn) ?
            //<>
            //  <Link
            //    className="px-[30px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold"
            //    to="/singin"
            //    >
            //      Вход
            //  </Link>
            //  <Link
            //    className="underline-offset-2 underline py-1 text-primary font-bold text-sm"
            //    to="/registration"
            //  >
            //      Регистрация
            //                  </Link>

            //</>
                          <>
                              <Popup ref={ref} onOpen={() => setShowMenu(false)} trigger={<button className="AlternativeText px-[30px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold"> <T uk = "Вхід" en = "Sign in">Вход</T></button>} modal nested className="testbl">
                                  {close => (
                                      <div className="modal">
                                          <div className="content">
                                              <Singin closePopUp={close}/>
                                          </div>
                                      </div>
                                  )}
                              </Popup>
                              <Popup ref={reff} onOpen={() => setShowMenu(false)}  trigger={<button className="px-[30px] border-x border-[#2B2B2B] underline-offset-2 underline py-1 text-primary font-bold text-sm"> <T uk = "Реєстрація" en = "Sign up">Регистрация</T></button>} modal nested className="testbl">
                                  {close => (
                                      <div className="modal">
                                          <div className="content">
                                              <Registration closePopUp={close}/>
                                          </div>
                                      </div>

                                  )}
                              </Popup>
                          </>
                :
            <>
            <a
              className="AlternativeText px-[30px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold"
              href={`https://${window.location.host}/api/logout`}
            >
              <T uk = "Вийти" en = "Exit">Выйти</T>
            </a>
              <Link reloadDocument className="AlternativeText px-[5px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold" to="/user">
                <T uk = "Моя сторінка" en = "My page">Моя страница</T>
              </Link>
            </>
          }
        </div>

      </ul>
    </div>
  );
};

export default HeaderMobile;
