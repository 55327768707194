window.CONFIG = {
  prod: {
    directus: {
      authtoken: "mx7DPEhBOozNm2fmTKXdo-e1E8asI7my"
    }
  },
  local: {
    directus: {
      authtoken: "mx7DPEhBOozNm2fmTKXdo-e1E8asI7my"
      // authtoken: "rdovC5LhocYksrUVnGn6pcXsrqp67csZ"
    }
  }
}
