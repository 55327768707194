import React from "react";
import { useForm} from "react-hook-form";
import {useState, useEffect, useRef} from "react";
import { useLocation } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
// const [requiredField, setRequiredField] = useState("Обязательное поле");
// const [matchesField, setMatchesField] = useState("Спецсимволы недопустимы в поле");




const Request = (props) => {

  const [requiredField, setRequiredField] = useState("Обязательное поле");
  const [matchesField, setMatchesField] = useState("Спецсимволы недопустимы");

  const language = useStore(x => x.language.language)
  const [requestSuccessMessage, setRequestSuccessMessage] = useState('Ваши данные успешно отправлены, мы свяжемся с вами в ближайшее время');
  const [requestErrorMessage, setRequestErrorMessage] = useState('Ошибка!');

  useEffect(() => {

    if (language == 'ru'){
      setRequestSuccessMessage('Ваши данные успешно отправлены, мы свяжемся с вами в ближайшее время');
      setRequestErrorMessage('Ошибка!');
    };

    if (language == 'uk'){
      setRequestSuccessMessage("Ваші дані успішно відправлені, ми зв'яжемося з Вами найближчим часом");
      setRequestErrorMessage('Помилка!');
    };

    if (language == 'en'){
      setRequestSuccessMessage('Your data has been successfully sent, we will contact you as soon as possible');
      setRequestErrorMessage('Error!');
    };

  }, [language]);

  const schema = yup.object().shape({
      name: yup
      .string()
      .required("Обязательное поле")
      .matches(/^[\p{L}'-]+$/u, { message: matchesField}),
      surname: yup
      .string()
      .required("Обязательное поле")
      .matches(/^[\p{L}'-]+$/u, { message: matchesField}),
      socialLink1: yup
      .string()
      .url("Некорректная ссылка")
      .required("Обязательное поле"),
      socialLink2: yup
      .string()
      .url("Некорректная ссылка"),
      email: yup
      .string()
      .email("Введите корректный Email")
      .when('phone', {
        is: (phone) => !phone || phone.length === 0,
        then: yup.string().required("Введите Email или телефон"),
        otherwise: yup.string()
      }),
      phone: yup
      .string()
      .matches(phoneRegExp, {message: 'Введите корректный телефон', excludeEmptyString: true})
      .when('email', {
        is: (email) => !email || email.length === 0,
        then: yup.string().required("Введите Email или телефон"),
        otherwise: yup.string()
      }),
      comments: yup
      .string()
  }, [ [ 'email', 'phone' ] ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (x) => {
    let response = await fetch(`https://${window.location.host}/api/join-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        first_name: x.name,
        last_name: x.surname,
        Phone_number: x.phone,
        Email: x.email,
        Social_media_link: x.socialLink1,
        Comments: x.comments,
      })
    })

    if (!response.ok) {
      submitAlert2(); //Вывести сообщение об ошибке
    } else {
      submitAlert1(); //Вывести сообщение об успешной отправке
      props.closePopUp();
    }


  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: true,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert1 = () => {
    Toast.fire({
      icon: 'success',
      title: requestSuccessMessage
    })
  }

  const submitAlert2 = () => {
    Toast.fire({
      icon: 'error',
      title: requestErrorMessage
    })
  }

  const watchName = watch("name");
  const watchSurname = watch("surname");
  const watchSocialLink1 = watch("socialLink1");
  const watchSocialLink2 = watch("socialLink2");
  const watchEmail = watch("email");
  const watchPhone = watch("phone");
  const watchComments = watch("comments");

  return (
    <section className="overflow-hidden relative sm:-[30px]">
      <div className="container">
        <h2 className="AlternativeText font-semibold mb-5 text-[44px] text-center sm:text-3xl mb-[60px]" onClick={() => props.closePopUp()}>
          <T uk = "Залишити заявку" en = "Leave a request">Оставить заявку</T>
        </h2>
        <form
          className="bg-darkGray p-[60px] w-[560px] mx-auto grid grid-cols-2 gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <MdOutlineClose className="absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl" onClick={() => props.closePopUp()} />
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="name"
          >
            <T uk = "Ім'я" en = "Name">Имя</T>
            <input
              className={`InputField
              ${errors?.name ? "border-[#D81F26]" : "border-gray"}
              ${
                watchName && !errors?.name ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="name"
              id="name"
              name="name"
              autoComplete="off"
              {...register("name")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.name?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="surname"
          >
            <T uk = "Прізвище" en = "Surname">Фамилия</T>
            <input
              className={`InputField
              ${errors?.surname ? "border-[#D81F26]" : "border-gray"}
              ${
                watchSurname && !errors?.surname ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="surname"
              id="surname"
              name="surname"
              autoComplete="off"
              {...register("surname")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.surname?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor="socialLink1"
          >
            <T uk = "Посилання на соцмережу" en = "Link to the social network">Ссылка на соцсеть</T>
            <input
              className={`InputField
              ${errors?.socialLink1 ? "border-[#D81F26]" : "border-gray"}
              ${
                watchSocialLink1 && !errors?.socialLink1 ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="socialLink1"
              id="socialLink1"
              name="socialLink1"
              autoComplete="off"
              {...register("socialLink1")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.socialLink1?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor="socialLink2"
          >
            <T uk = "Посилання на соцмережу" en = "Link to the social network">Ссылка на соцсеть</T>
            <input
              className={`InputField
              ${errors?.socialLink2 ? "border-[#D81F26]" : "border-gray"}
              ${
                watchSocialLink2 && !errors?.socialLink2 ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="socialLink2"
              id="socialLink2"
              name="socialLink2"
              autoComplete="off"
              {...register("socialLink2")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.socialLink2?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="email"
          >
            <T uk = "Ваша пошта" en = "Your email">Ваша почта</T>
            <input
              className={`InputField
              ${errors?.email ? "border-[#D81F26]" : "border-gray"}
              ${
                watchEmail && !errors?.email ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              {...register("email")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.email?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="phone"
          >
            <T uk = "Номер телефону" en = "Phone number">Номер телефона</T>
            <input
              className={`InputField
              ${errors?.phone ? "border-[#D81F26]" : "border-gray"}
              ${
                watchPhone && !errors?.phone ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="phone"
              id="phone"
              name="phone"
              autoComplete="off"
              {...register("phone")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.phone?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor="comments"
          >
            <T uk = "Коментарі" en = "Comments">Комментарии</T>
            <input
              className={`InputField
              ${errors?.comments ? "border-[#D81F26]" : "border-gray"}
              ${
                watchComments && !errors?.comments ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="comments"
              id="comments"
              name="comments"
              autoComplete="off"
              {...register("comments")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.comments?.message}</T>
            </p>
          </label>
          <button
            className="ButtonText btn btn-primary col-span-2 text-xs cursor-pointer"
            type="submit"
          >
            <T uk = "Залишити заявку" en = "Leave a request">Оставить заявку</T>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Request;
