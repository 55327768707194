import React from "react";
import {useState, useEffect, useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Navigation, Manipulation } from "swiper";
import { useSwiper, useSwiperSlide } from "swiper/react";


import Popup from 'reactjs-popup';
import SpecialOfferPopup from "./SpecialOfferPopup";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/swiper-blog.css";

import OfferItemImg from "../assets/img/bloggerbuy/offer-item.png";
import ArrowRightImg from "../assets/img/arrow-right.png";

import SpecialOfferEdit from "./SpecialOfferEdit";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const Offers = (props) => {
  const ref = useRef(null);
  const ref2 = useRef(null); //editOffer

  const [selectedOffer, setSelectedOffer] = useState(null);
  const closeTooltip = () => ref.current.close();

  //Pop-up для изменения спецпредложения
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  function shortenText(text) {
    if (text.length > 120) {
      return text.substring(0, 120) + "...";
    } else {
      return text;
    }
  }

  //Меню с тремя точками
  function MeatballsMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const swiper = useSwiper()
    const swiperSlide = useSwiperSlide()
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const editClickHandler = async () => {
      setOpen(true)
      setSelectedOffer(props.item);
    }
    const deleteClickHandler = async () => {

      //Удалить предложение из таблицы Offer в Directus
      //Для корректной работы должно быть включено каскадное удаление в Directus
      let response = await fetch(`https://${window.location.host}/api/items/offer/${props.item.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }});

      let result = await response;

      //Заново отрисовать страницу пользователя после удаления предложения
      props.rer();
      //Закрыть меню
      handleClose();
    }


    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{color: "grey"}}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={editClickHandler}><T uk = "Змінити" en = "Edit">Изменить</T></MenuItem>
          <MenuItem onClick={deleteClickHandler}><T uk = "Видалити" en = "Delete">Удалить</T></MenuItem>
        </Menu>
      </div>
    );
  }


  return (
    <section className="py-[20px] md:py-[20px] sm:py-[20px]">
    <Popup
        ref={ref2}
        open={open}
        onClose={closeModal}
        modal
        nested
        className="testbl">
        {close => (
          <div className="modal">
            <div className="content">
              <SpecialOfferEdit offer={selectedOffer} closePopUp={close} rer={props.rer}/>
            </div>
          </div>
        )}
      </Popup>
      <div className="container" id={`offers-container`}>
        <h2 className="font-semibold text-3xl mb-[50px]">
          { props.isUserpage ?
          (<T uk = "Мої спеціальні пропозиції" en = "My special offers">Мои специальные предложения</T>) :
          (<>
            <T uk = "Спеціальні пропозиції від" en = "Special offers from">Специальные предложения от</T> {props.name}
          </>)
        }
        </h2>
        <Swiper
          breakpoints={{
            "@0.75": {
              slidesPerView: 2.3,
              spaceBetween: 15,
            },
            "@1.00": {
              slidesPerView: 4.2,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 4.2,
              spaceBetween: 16,
            },
          }}
          slidesPerView={1.3}
          spaceBetween={16}
          grabCursor={true}
          navigation={true}
          modules={[Navigation, Manipulation]}
          className="swiper-blog"
        >
          <div>
            {props.offers ? (props.offers.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col">
                  {props.isUserpage && <MeatballsMenu rer={props.rer} item={item} />}
                  <div className="flex flex-col p-[30px] w-[231px] h-[480px] bg-[#BD9EFF33]/20">
                    <img
                      className="object-cover w-[120px] h-[120px] mb-[30px]"
                      src={`https://directus.${window.location.host}/assets/${item.image}`}
                      alt=""
                    />
                    <h3 className="font-semibold text-xl mb-4 text-left h-[60px] overflow-hidden overflow-ellipsis">
                      <T>{item.name}</T>
                    </h3>
                    <Popup nested ref={ref} modal className="testbl"
                      trigger={
                        <p className="text-lightGray mb-8 text-left h-[140px] overflow-hidden overflow-ellipsis">
                          <T>{shortenText(item.text)}</T>
                        </p>
                      }
                      position="right center">
                        {close => (<SpecialOfferPopup offer={item} personId={props.personId} closePopUp={close}></SpecialOfferPopup>)}
                    </Popup>
                    <Popup nested ref={ref} modal className="testbl"
                      trigger={
                        <div className="flex items-center gap-[10px] cursor-pointer" id={`option-${item.id}`}>
                          <img className="w-[16px]" src={ArrowRightImg} alt="" />
                          <h3><T uk = "Детальніше" en = "Read more">Подробнее</T></h3>
                        </div>
                      }
                      position="right center">
                        {close => (<SpecialOfferPopup offer={item} personId={props.personId} closePopUp={close}></SpecialOfferPopup>)}
                    </Popup>
                  </div>
                </div>
              </SwiperSlide>
            ))) : (
              <div>
              {[...Array(4)].map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col p-[30px] bg-[#BD9EFF33]/20">
                <img
                  className="object-cover w-[120px] h-[120px] mb-[30px]"
                  src={OfferItemImg}
                  alt=""
                />
                <h3 className="font-semibold text-xl mb-4 text-left">
                  <T uk = "Відвідування моїх концертів безкоштовно" en = "Attending my concerts is free">Посещение моих концертов бесплатно</T>
                </h3>
                <p className="text-lightGray mb-8 text-left text-color-theme">
                  <T uk = "Вкладись у 1000 акцій і відвідуй усі мої концерти безкоштовно!" en = "Invest in 1000 shares and attend all my concerts for free!">Вложись в 1000 акций и посещай все мои концерты бесплатно!</T>
                  {" "}
                </p>
                <Popup nested ref={ref} modal className="testbl"
                  trigger={
                    <div className="flex items-center gap-[10px] cursor-pointer">
                      <img className="w-[16px]" src={ArrowRightImg} alt="" />
                      <h3><T uk = "Детальніше" en = "Read more">Подробнее</T></h3>
                    </div>
                  }
                  position="right center">
                    {close => (<SpecialOfferPopup closePopUp={close}></SpecialOfferPopup>)}
                </Popup>
              </div>
            </SwiperSlide>
          ))}
              </div>
            )
          }
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default Offers;
