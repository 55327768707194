import React from "react";
import {useState, useEffect, useRef} from "react";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Popup from 'reactjs-popup';
import Avatar from "react-avatar-edit";
import StockAvatar from "../assets/img/stock_avatar.png";
import { Link } from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';

function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

const schema = yup.object().shape({
    name: yup
    .string()
    .required("Обязательное поле"),
}, []);


const PersonContent = (props) => {
  const [contentFile, setContentFile] = useState();

  const language = useStore(x => x.language.language)
  const [shortErrorMessage, setShortErrorMessage] = useState('Ошибка!');
  const [longErrorMessage, setLongErrorMessage] = useState("Ошибка. Формат файла не поддерживается.");
  const [contentErrorMessage, setContentErrorMessage] = useState("Вы не загрузили контент");
  const [contentSuccessMessage, setContentSuccessMessage] = useState("Контент загружен");

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {

    if (language == 'ru'){
      setShortErrorMessage('Ошибка!');
      setLongErrorMessage("Ошибка. Формат файла не поддерживается.");
      setContentErrorMessage("Вы не загрузили контент");
      setContentSuccessMessage("Контент загружен");
    };

    if (language == 'uk'){
      setShortErrorMessage('Помилка!');
      setLongErrorMessage("Помилка. Формат файлу не підтримується.");
      setContentErrorMessage("Ви не завантажили контент");
      setContentSuccessMessage("Контент завантажено");
    };

    if (language == 'en'){
      setShortErrorMessage('Error!');
      setLongErrorMessage("Error. The file format is not supported.");
      setContentErrorMessage("You have not uploaded the content");
      setContentSuccessMessage("Content uploaded");
    };

  }, [language]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  const Toast2 = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: true,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert = () => {
    Toast.fire({
      icon: 'warning',
      title: contentErrorMessage
    })
  }

  const submitAler2 = () => {
    Toast2.fire({
      icon: 'success',
      title: contentSuccessMessage
    })
  }

  const submitAlert3 = () => {
    Toast.fire({
      icon: 'warning',
      title: longErrorMessage
    })
  }

  const submitErrorAlert = (m) => {
    Toast.fire({
      icon: 'error',
      title: m,
    })
  }

  const handleContentChange = (e) => {
    const file = e.target.files[0];
    console.log(file)
    setContentFile(file)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (x) => {
    if (!contentFile) {
      //Выдать ошибку если файл не загружен
      submitAlert();
      return;
    }

    let contentFormData = new FormData();
    contentFormData.append('file', contentFile);
    //Поддерживаемые форматы файлов
    const allowedTypes = ['video/mp4', 'image/jpeg', 'image/png']
    if (!allowedTypes.includes(contentFile.type)) {
      //Выдать ошибку если формат файла не поддерживается
      submitAlert3();
      return;
    }

    //Сделать кнопку отправки формы неактивной, чтобы форма не отправлялась
    //несколько раз при повторных нажатиях
    setSubmitButtonDisabled(true);
    //Сохранить контент в хранилище файлов в directus
    let r = await fetch(`https://${window.location.host}/api/files`, {
      method: 'POST',
      body: contentFormData,
    })

    if (!r.ok) {
      setSubmitButtonDisabled(false);
      switch(r.status) {
        case 401:
          submitErrorAlert(shortErrorMessage);
          return;
          break;
        default:
        submitErrorAlert(shortErrorMessage);
          return;
      }
    }

    r = (await r.json()).data;

    await fetch(`https://${window.location.host}/api/items/Instrss`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        iduser: props.person,
        title: x.name,
        image: r.id,
      })
    })
    submitAler2();
    props.closePopUp();
    //Выйти из режима редактирования после добавления контента
    props.handleCancel();
  };


  const watchName = watch("name");

  const [isLoaded, setIsLoaded] = useState(true);


  const [uploaded, setUploaded] = useState(false);

  if( !isLoaded ) {
    return (<></>)
  };

  return (
    <section className="overflow-hidden relative sm:-[20px]">
      <div className="container">
        <h2 className="AlternativeText font-semibold text-[33px] text-center sm:text-3xl mb-[30px] md:sm:text-xl md:mb-[20px]" onClick={() => props.closePopUp()}>
          <T uk = "Додати контент" en = "Add content">Добавить контент</T>
        </h2>
        <form
          className="Background3 bg-darkGray px-[40px] py-[30px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-8 sm:md:pt-5 md:gap-[20px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <MdOutlineClose className="ButtonText absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl md:right-[10px]" onClick={() => props.closePopUp()} />
          <label
            className="MainText flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="name"
          >
            <T uk = "Заголовок" en = "Title">Заголовок</T>
            <input
              className={`InputField
              ${errors?.name ? "border-[#D81F26]" : "border-gray"}
              ${
                watchName && !errors?.name ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="name"
              id="name"
              name="name"
              autoComplete="off"
              {...register("name")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.name?.message}</T>
            </p>
          </label>
          <label
            className="MainText flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="content"
          >
            <input
              // ref={backImgInputRef}
              className="VideoInput_input"
              id="content"
              name="content"
              type="file"
              onChange={handleContentChange}
              accept="image/png,image/jpeg,video/mp4"
              />
          </label>
            <button
            className="ButtonColor AlternativeButtonText btn btn-primary col-span-2 text-xs cursor-pointer"
            type="submit"
            disabled={submitButtonDisabled}
            >
            <T uk = "Зберегти" en = "Save">Сохранить</T>
            </button>
        </form>
      </div>
    </section>
  );
};

export default PersonContent;
