import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LineChart from '../components/LineChart';
import UsikImg from "../assets/img/usik.png";
import ShevchenkoImg from "../assets/img/shevchenko.png";
import DorofeevaImg from "../assets/img/dorofeeva.png";
import ChartImg from "../assets/img/chart.png";
import ArrowRightImg from "../assets/img/arrow-right.png";

const Table = () => {
  // const [showMore, setShowMore] = React.useState(false);
  const [persons, setPersons] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect( () => {
    const fetchData = async () => {
      const response = (await directus.items('Person').readByQuery({
        limit: 10,
        fields: ['*', 'activity.directus_files_id', 'category.person_category_id.name'],
        filter: {
          is_approved: {
            '_eq': true,
          }
        }
      })).data;
      response.forEach( (x) => {

        let person = {};

        person.id = x.id;
        person.img = `https://directus.${window.location.host}/assets/${x.head_photo}`;
        person.name = x.name || x.first_name + ' ' + x.last_name;
        person.categories = x.category.map(item => (item.person_category_id.name)).join(', ');
        person.amount = x.share_quantity;
        person.price = x.share_price;
        person.cash = x.capitalisation;

        setPersons(prevState => [...prevState, person]);

      });

      setIsLoaded(x => true);

    }

    fetchData();
  }, []);

  const tableList = [
    {
      img: UsikImg,
      name: "Александр Усик ",
      work: "Боксер",
      cash: "$244,40",
      price: "$2.751.132",
      chart: ChartImg,
    },
    {
      img: DorofeevaImg,
      name: "DOROFEEVA ",
      work: "Певица",
      cash: "$122,20",
      price: "$1.942.021",
      chart: ChartImg,
    },
    {
      img: ShevchenkoImg,
      name: "Андрей Шевченко ",
      work: "Футболист",
      cash: "$183,54",
      price: "$2.267.159",
      chart: ChartImg,
    },
  ];

  if( !isLoaded ) {
    return (<></>)
  };
  return (
    <>
      <section className="Background2">
        <div className="container">
          <div className="py-[80px] md:py-[50px] sm:py-[30px]">
            <div className="flex items-start mb-[60px] md:mb-[30px]">
              <h2 className="text-[28px] pl-[72px] font-semibold mr-2 text-2xl md:pl-0">
                <T uk = "Ринки для трейдерів" en = "Markets for Traders">Рынки для трейдеров</T>
              </h2>
              <span className="ButtonText px-2 py-1 bg-danger rounded-md font-bold text-xs">
                PRO
              </span>
            </div>
            <div className="mx-auto mb-[60px]">
              <div className="w-full mx-auto overflow-auto">
                <table className="table-auto w-full text-left whitespace-no-wrap">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl pl-[72px]">
                        <T uk = "Персона" en = "Person">Личность</T>
                      </th>
                      <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                        <T uk = "Остання ціна" en = "Last price">Последняя цена</T>
                      </th>
                      <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                        <T uk = "Капіталізація" en = "Capitalization">Капитализация</T>
                      </th>
                      <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                        <T uk = "Динаміка за 24г" en = "Dynamics in 24h">Динамика за 24ч</T>
                      </th>
                      <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br pr-[72px]"></th>
                    </tr>
                  </thead>
                  {persons.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td className="px-4 py-3 md:ml-[10px] border-y border-white/10 pl-[72px] md:pl-[7px]">
                          <div className="flex gap-[25px]">

                            <img onClick={(e) => {e.preventDefault();window.location.href=`/bloggerbuy/${item.id}`;}} className="w-[53px] h-[53px] md:w-[40px] md:h-[40px] lg:w-[45px] lg:h-[45px] imghover" src={item.img} alt="" />

                            <div className="flex flex-col gap-1">
                            <Link to={`/bloggerbuy/${item.id}`}>
                              <h3 className="text-sm"><T>{item.name}</T></h3>
                              </Link>
                              <p className="text-[#929496] text-xs">
                                <T>{item.categories}</T>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 border-y border-white/10 text-sm">
                          {item.price}
                        </td>
                        <td className="px-4 py-3 border-y border-white/10 text-sm">
                          {item.cash}
                        </td>
                        <td className="px-4 py-3 text-lg text-gray-900 border-y border-white/10">
                        <div className= "w-[200px]">
                          <LineChart height = {60} lineColor = {"#32CD32"} altLineColor = {"#DC143C"}/>
                        </div>
                        </td>
                        <td className="w-10 text-center border-y border-white/10 pr-[72px] md:pr-0">
                          <Link className="ButtonText btn btn-green w-[100px]" to={`/bloggerbuy/${item.id}`}>
                            <T uk = "Купити" en = "Buy">Купить</T>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            {/* <div className="flex items-center justify-center gap-[10px] cursor-pointer">
              <img className="w-[16px] h-[16px]" src={ArrowRightImg} alt="" />
              <h3>Смотреть всех </h3>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Table;
