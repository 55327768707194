import "./App.css";
import "./config.js";
import { Routes, Route } from "react-router-dom";

import { withErrorBoundary } from 'react-error-boundary'

import MainLayout from "./layouts/MainLayout";
import SinginLayout from "./layouts/SinginLayout";
import RegistrationLayout from "./layouts/RegistrationLayout";
import Home from "./pages/Home";
import Singin from "./pages/Singin";
import ResetPassword from "./pages/ResetPassword";
import Registration from "./pages/Registration";
import Personalities from "./pages/Personalities";
import Bloggers from "./pages/Bloggers";
import User from "./pages/User";
import Bloggerbuy from "./pages/bloggerbuy/index";
import About from "./pages/About";
import LoginRedirect from "./pages/LoginRedirect";
import GhostContentAPI from "@tryghost/content-api";
import posthog from 'posthog-js'
import './Store'
import './Translation'

import {useEffect, useState, createContext} from 'react';
import MainTitle from "./assets/img/main-title.png";

posthog.init('phc_1NYlxE0xCU3yYqbc9FADnlAMA4VNQamAM6XzaO5I1AZ', { api_host: 'https://eu.posthog.com', autocapture: {
  // event_allowlist: ['click'], // DOM events from this list ['click', 'change', 'submit']
  // url_allowlist: ['posthog.com\.\/docs\/.*'], // strings or RegExps
  // element_allowlist: ['button'], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
  // css_selector_allowlist: ['[ph-autocapture]'] // List of CSS selectors
} })
window.posthog = posthog
import { Directus } from '@directus/sdk';
import UserStock from "./components/UserStock";

window.Blog = (path = "") =>
  "https://blog." + window.location.host + (path ? "/" + path : "");

window.GhostAPI = new GhostContentAPI({
  url: Blog(),
  key: "c86b5b841d50cb56551cd92b61",
  version: "v5.0",
});

if (window.location.host.endsWith("personalshares.local")) {
  window.authtoken = CONFIG.local.directus.authtoken;
} else {
  window.authtoken = CONFIG.prod.directus.authtoken;
}

window.directus = new Directus(`https://${window.location.host}/directus`, {
  transport: { headers: { "Cache-Control": "no-store" } },
});

// window.directus_admin = new Directus(`https://${window.location.host}/directus`, {
//   transport: { headers: { "Cache-Control": "no-store" } },
//   auth: {staticToken: window.authtoken},
//   storage: {prefix: "directusadmin"},
// });

export const LoginContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    async function checkLoginStatus() {
      //Проверить авторизован ли пользователь
      let response = await fetch(`https://${window.location.host}/api/current-user`)
      if (response.ok) {
        console.log('User is logged in...')
        setIsLoggedIn(true)
      } else {
        console.log('User is NOT logged in...')
        setIsLoggedIn(false)
      }
    }

    checkLoginStatus()

  if (localStorage.getItem('userData') === null) {

    const userData = {
      balance: 4000,
      shares: {
        DRFVA: 0,
        MNTK: 2,
        BRSKH: 1,
        ALNA: 3,
        USYK: 0
      }
  };
      localStorage.setItem('userData', JSON.stringify(userData));
  }
  }, [])

  return (
    <>
      <LoginContext.Provider value={[isLoggedIn, setIsLoggedIn]}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/stock" element={<UserStock />} />
            <Route path="/bloggerbuy" element={<Bloggerbuy />} />
            <Route path="/bloggerbuy/:id" element={<Bloggerbuy />} />
            <Route path="/personalities" element={<Personalities />} />
            <Route path="/market" element={<Bloggers />} />
            <Route path="/user" element={<User />} />
            <Route path="/user/:id" element={<User />} />
            </Route>
            <Route path="/loginredirect" element={<LoginRedirect />} />
            <Route path="/resetpw" element={<ResetPassword />} />
            <Route path="/singin" element={<SinginLayout />}>
            <Route index element={<Singin />} />
            </Route>
            <Route path="/registration" element={<RegistrationLayout />}>
            <Route index element={<Registration />} />
            </Route>
        </Routes>
      </LoginContext.Provider>
    </>
  );
}

export default withErrorBoundary(App, {
    fallback: <div className="grid place-content-center mt-[10%]">
                    <img
                        className="w-[200px] h-[100px]"
                        src={MainTitle}
                        alt="Personal shares"
                     />
                     <p className="mt-[20px] text-lg font-semibold">Что-то пошло не так</p>
                </div>,
    onError(error, errorInfo) {
      const somethingconst = async() => {
        await fetch(`https://${window.location.host}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({'error': `${error}`,'errorinfo': `${errorInfo.componentStack.replace('@','%0A%0A')}`}),
      });
      }
      somethingconst();

    },
});
