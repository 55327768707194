import React from "react";
import Popup from 'reactjs-popup';

const Pay = () => {
    return(
        <>
        <section className="overflow-hidden relative fadeIn">
            <div className="container">
            <h2 className="AlternativeText font-semibold mb-5 text-[30px] text-center sm:text-3xl" onClick={() => props.closePopUp()}>
          <T uk ="Поповнення балансу" en ="Balance replenishment">Пополнение баланса</T>
        </h2>
        <p className="text-white/70 text-center w-[560px] mx-auto mb-[60px] sm:w-full" onClick={() => props.closePopUp()}>
            <T
              uk = "Для поповнення балансу введіть суму поповнення та натисніть кнопку `Поповнити`"
              en = "To replenish the balance, enter the amount of the replenishment and press the button `Replenish`"
              >Для пополнения баланса введите суму пополнения и нажмите кнопку "Пополнить"
            </T>
          </p>
                <form
                className="bg-darkGray p-[60px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12"
                >
                   <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
          >
            <T uk = "Сума поповнення" en = "Top-up amount">Сума пополнения</T>
            <input
              className={`InputField
              bg-gray p-3 text-white outline-none border-gray`}
              autoComplete="off"
            />
          </label>

        </form>
        <Popup nested modal className="testbl" trigger={<button className="ButtonColor AlternativeButtonText btn bg-primary w-[160px] text-l mt-[15px] mx-auto"><T uk = "Поповнити" en = "Replenish">Пополнить</T></button>} position="center">
                            <p className="text-white/70 text-center w-[560px] mx-auto mb-[60px] sm:w-full">
            <T
              uk = "Поповнення тимчасово недоступне."
              en = "Top-up is temporarily unavailable."
              >Пополнение временно не доступно.
            </T>
          </p>
                    </Popup>
            </div>
        </section>
        
        
        
        </>
    );
};

export default Pay;
