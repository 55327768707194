import React from "react";
import idbKvStore from 'idb-kv-store'
window.idb = new idbKvStore('ps')
import * as R from 'ramda'
import create from 'zustand'

export const useStore = create(set => {
  return {
    language: {
      language: 'ru',
      change: (language = 'ru') => {
        const langs = ['ru', 'uk', 'en']
        if (!langs.includes(language)) {
          throw `${language} is not supported. Accepted values: ${langs}`
        }
        SET({language: {language}})
        idb.set('language', language)
      }
    },

    translations: {
      en: {"О платформе": 'About the platform',
      "Личности": 'Persons',
      "Рейтинг": 'Rating',
      'Боксёр': 'Boxer',
      'Александр Усик': 'Oleksandr Usyk',
      'Макс Барских': 'Max Barskih',
      'Alyona Alyona': 'Alyona Alyona',
      'Monatik': 'Monatik',
      'DOROFEEVA': 'DOROFEEVA',
      'Певец': 'Male singer',
      'Исполнитель': 'Male performer',
      'Исполнительница': 'Female performer',
      'Боксер': 'Boxer',
      'Певица': 'Female singer',
      'Футболист': 'Footballer',
      'Актёр': 'Male actor',
      'Блогер': 'Blogger',
      'Спортсмен': 'Athlete',
      'Актриса': 'Female actor',
      'Танцор': 'Dancer',
      'Хореограф': 'Choreographer',
      'Телеведущий': 'Male TV presenter',
      'Телеведущая': 'Female TV presenter',
      'Дизайнер': 'Designer',
      'Видеоблогер': 'Video blogger',
      'Композитор': 'Composer',
      'Модель': 'Model',
      'Продюсер': 'Producer',
      'Радиоведущий': 'Male radio host',
      'Радиоведущая': 'Female radio host',
      'Диджей': 'DJ',
      'Начинающие звезды': 'Beginning stars',
      'Новые пользователи': 'New users',
      'Зарабатывай просто': 'Make money easy',
      'Получай бенефиты': 'Get benefits',
      'Выпускай акции': "Issue shares",
      'Это почти также просто, как купить кофе. Покупай и продавай персональные акции, зарабатывай на ценовой разнице': "It's almost as easy as buying coffee. Buy and sell personal shares, earn on the price difference",
      'Приобретай персональные акции и получай уникальные бенефиты. Чем больше персональных акций тем больше преимуществ': "Buy personal shares and get unique benefits. The more personal shares, the more benefits",
      'Твоя задача убедить людей, в том, что ты будешь популярным и успешным и они смогут на тебе заработать. Таких людей будет становиться все больше': "Your task is to convince people that you will be popular and successful and they will be able to make money on you. There will be more and more people like this",
      "Обязательное поле": "Required field",
      "Спецсимволы недопустимы": "Special characters are not allowed",
      "Некорректная ссылка": "Invalid link",
      "Ссылка должна вести на сайт Twitter": "The link should lead to the Twitter site",
      "Ссылка должна вести на сайт YouTube": "The link should lead to the YouTube site",
      "Ссылка должна вести на сайт Instagram": "The link should lead to the Instagram site",
      "Ссылка должна вести на сайт TikTok": "The link should lead to the TikTok site",
      "Введите корректный Email": "Enter the correct Email",
      'Введите корректный телефон': "Enter the correct phone number",
      "Введите Email или телефон": "Enter your Email or phone number",

    },
      ru: {},
      uk: {'О платформе': 'Про платформу',
      'Личности': 'Персони',
      'Рейтинг': 'Рейтинг',
      'Боксёр': 'Боксер',
      'Александр Усик': 'Олександр Усик',
      'Макс Барских': 'Макс Барських',
      'Alyona Alyona': 'Alyona Alyona',
      'Monatik': 'Monatik',
      'DOROFEEVA': 'DOROFEEVA',
      'Певец': 'Співак',
      'Исполнитель': 'Виконавець',
      'Исполнительница': 'Виконавиця',
      'Боксер': 'Боксер',
      'Певица': 'Співачка',
      'Футболист': 'Футболіст',
      'Актёр': 'Актор',
      'Блогер': 'Блогер',
      'Спортсмен': 'Спортсмен',
      'Актриса': 'Актрорка',
      'Танцор': 'Танцюрист',
      'Хореограф': 'Хореограф',
      'Телеведущий': 'Телеведучий',
      'Телеведущая': 'Телеведуча',
      'Дизайнер': 'Дизайнер',
      'Видеоблогер': 'Відеоблогер',
      'Композитор': 'Композитор',
      'Модель': 'Модель',
      'Продюсер': 'Продюсер',
      'Радиоведущий': 'Радіоведучий',
      'Радиоведущая': 'Радіоведуча',
      'Диджей': 'Діджей',
      'Начинающие звезды': 'Зірки-початківці',
      'Новые пользователи': 'Нові користувачі',
      'Зарабатывай просто': 'Заробляй просто',
      'Получай бенефиты': 'Отримуй бенефіти',
      'Выпускай акции': "Випускай акції",
      'Это почти также просто, как купить кофе. Покупай и продавай персональные акции, зарабатывай на ценовой разнице': 'Це майже так само просто, як купити каву. Купуй і продавай персональні акції, заробляй на ціновій різниці',
      'Приобретай персональные акции и получай уникальные бенефиты. Чем больше персональных акций тем больше преимуществ': "Купуй персональні акції та отримуй унікальні бенефіти. Чим більше персональних акцій тим більше переваг",
      'Твоя задача убедить людей, в том, что ты будешь популярным и успешным и они смогут на тебе заработать. Таких людей будет становиться все больше': "Твоє завдання переконати людей у тому, що ти будеш популярним і успішним і вони зможуть на тобі заробити. Таких людей ставатиме дедалі більше",
      "Обязательное поле": "Обов'язкове поле",
      "Спецсимволы недопустимы": "Спецсимволи неприпустимі",
      "Некорректная ссылка": "Некоректне посилання",
      "Ссылка должна вести на сайт Twitter": "Посилання має вести на сайт Twitter",
      "Ссылка должна вести на сайт YouTube": "Посилання має вести на сайт YouTube",
      "Ссылка должна вести на сайт Instagram": "Посилання має вести на сайт Instagram",
      "Ссылка должна вести на сайт TikTok": "Посилання має вести на сайт TikTok",
      "Введите корректный Email": "Введіть коректний Email",
      'Введите корректный телефон': "Введіть коректний телефон",
      "Введите Email или телефон": "Введіть Email або телефон",
    },
    }
  }
})


window.useStore = useStore
window.GET = useStore.getState

window.SET = x => {
  const s = R.mergeDeepRight(GET(), x)
  useStore.setState(s)
  return s
}

idb.get('language').then(GET().language.change)
