import React, {useContext, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Popup from 'reactjs-popup';
import Singin from "../pages/Singin";
import {useStore} from '../Store';

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import facebook from "../assets/img/social_media_buttons/facebook.png";
import google from "../assets/img/social_media_buttons/google.png";
import telegram from "../assets/img/social_media_buttons/telegram.png";
import twitter from "../assets/img/social_media_buttons/twitter.png";

import {LoginContext} from "../App.js"


const Registration = (props) => {
  const [passShow, setPassShow] = React.useState("password");
  const navigate = useNavigate();
  const [passConfirmationShow, setPassConfirmationShow] =
    React.useState("password");
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const language = useStore(x => x.language.language)
  const [shortErrorMessage, setShortErrorMessage] = useState('Ошибка!');
  const [longErrorMessage, setLongErrorMessage] = useState('Ошибка! Данный email уже используется.');
  const [emailField, setEmailField] = useState("Введите корректный Email");
  const [minEmailField, setMinEmailField] = useState("Слишком короткий Email");
  const [minPasswordField, setMinPasswordField] = useState("Слишком короткий пароль");
  const [requiredField, setRequiredField] = useState("Обязательное поле");
  const [repeatPasswordField, setRepeatPasswordField] = useState("Пароль не совпадает");

  useEffect(() => {

    if (language == 'ru'){
      setEmailField("Введите корректный Email");
      setMinEmailField("Слишком короткий Email");
      setMinPasswordField("Слишком короткий пароль");
      setRequiredField("Обязательное поле");
      setRepeatPasswordField("Пароль не совпадает");
    };

    if (language == 'uk'){
      setEmailField("Введіть коректний Email");
      setMinEmailField("Занадто короткий Email");
      setMinPasswordField("Занадто короткий пароль");
      setRequiredField("Обов'язкове поле");
      setRepeatPasswordField("Пароль не збігається");
    };

    if (language == 'en'){
      setEmailField("Enter the correct Email");
      setMinEmailField("Email is too short");
      setMinPasswordField("The password is too short");
      setRequiredField("Required field");
      setRepeatPasswordField("The password doesn't match");
    };

  }, [language]);


  const schema = yup.object().shape({
    email: yup
      .string()
      .email(emailField)
      .min(2, minEmailField)
      .required(requiredField),
    password: yup
      .string()
      .min(6, minPasswordField)
      .required(requiredField),
    passwordConfirmation: yup
      .string()
      .min(6, minPasswordField)
      .oneOf([yup.ref("password"), null], repeatPasswordField),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert = (t) => {
    Toast.fire({
      icon: 'error',
      title: t,
    })
  }

  useEffect(() => {

    if (language == 'ru'){
      setShortErrorMessage('Ошибка!');
      setLongErrorMessage('Ошибка! Данный email уже используется.');
    };
  
    if (language == 'uk'){
      setShortErrorMessage('Помилка!');
      setLongErrorMessage('Помилка! Цей email вже використовується.');
    };
  
    if (language == 'en'){
      setShortErrorMessage('Error!');
      setLongErrorMessage('Error! This email is already in use.');
    };
  
  }, [language]);

  const onSubmit = async (data) => {

    posthog.identify(data.email)

    let response = await fetch(`https://${window.location.host}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    })

    if (response.ok) {
      setIsLoggedIn(true);
      props.closePopUp();
      navigate('/user')
    } else {
      switch (response.status) {
        case 400:
          submitAlert(longErrorMessage);
          break;
        default:
          submitAlert(shortErrorMessage);
      }
    }

  };

  const watchEmail = watch("email");
  const watchPassword = watch("password");
  const watchPasswordConfirmation = watch("passwordConfirmation");

  let txt_rus = "Зарегистрировать аккаунт";
  let txt_ukr = "Зареєструвати аккаунт";
  let txt_eng = "Register an account";
  if (props.buyButtonPopUp) {
    txt_rus = "Зарегистрируйтесь или войдите для покупки"
    txt_ukr = "Зареєструйтесь або увійдіть в акаунт";
    txt_eng = "Register or log in to your account";
  }

  return (
    <section className="overflow-hidden relative fadeIn">
      <div className="container">
        <h2 className="AlternativeText font-semibold mb-5 text-[30px] text-center sm:text-3xl" onClick={() => props.closePopUp()}>
          <T uk = {txt_ukr} en = {txt_eng}>{txt_rus}</T>
        </h2>
          <p className="text-white/70 text-center w-[560px] mx-auto mb-[60px] sm:w-full" onClick={() => props.closePopUp()}>
            <T
              uk = "Купуй та продавай персональні акції публічних осіб на єдиній у світі біржі персональних акцій."
              en = "Buy and sell personal shares of public persons on the world's only personal shares market."
              >Покупай и продавай персональные акции публичых личностей на единственной в мире бирже персональных акций.
            </T>
          </p>
        <form
          className="bg-darkGray p-[60px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12"
          onSubmit={handleSubmit(onSubmit)}
              >
          <MdOutlineClose className="ButtonText absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl" onClick={() => props.closePopUp()} />
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="email"
          >
            <T uk = "Ваша пошта" en = "Your mail">Ваша почта</T>
            <input
              className={`InputField
              ${errors?.email ? "border-[#D81F26]" : "border-gray"}
              ${
                watchEmail && !errors?.email ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              {...register("email")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.email?.message}
            </p>
          </label>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="password"
          >
            <T uk = "Пароль" en = "Password">Пароль</T>
            <input
              className={`InputField
              ${errors?.password ? "border-[#D81F26]" : "border-gray"}
              ${
                watchPassword && !errors?.password ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border tracking-[0.4em]`}
              type={passShow}
              id="password"
              name="password"
              autoComplete="off"
              {...register("password")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.password?.message}
            </p>
            {passShow === "password" ? (
              <AiOutlineEye
                onClick={() => setPassShow("text")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => setPassShow("password")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            )}
          </label>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="passwordConfirmation"
          >
            <T uk = "Повторіть пароль" en = "Repeat password">Повторите пароль</T>
            <input
              className={`InputField
              ${
                errors?.passwordConfirmation
                  ? "border-[#D81F26]"
                  : "border-gray"
              }
              ${
                watchPasswordConfirmation && !errors?.passwordConfirmation
                  ? "!border-[green]"
                  : ""
              } bg-gray p-3 text-white outline-none border tracking-[0.4em]`}
              type={passConfirmationShow}
              id="passwordConfirmation"
              name="passwordConfirmation"
              autoComplete="off"
              {...register("passwordConfirmation")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.passwordConfirmation?.message}
            </p>
            {passConfirmationShow === "password" ? (
              <AiOutlineEye
                onClick={() => setPassConfirmationShow("text")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => setPassConfirmationShow("password")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            )}
          </label>
          <button
            className="ButtonColor AlternativeButtonText btn btn-primary text-xs cursor-pointer"
            type="submit"
          >
            <T uk = "СТВОРИТИ АКАУНТ" en = "CREATE AN ACCOUNT">СОЗДАТЬ АККАУНТ</T>
          </button>
          {props.buyButtonPopUp && <button onClick={props.togglePopUp} type="button"> <T uk = "Вхід" en = "Sign in">Вход</T></button>}
          <div className="m-auto">
            <button type="button" className="h-10 w-10 mr-2"> <a href={`https://login.${window.location.host}/facebook?success=${encodeURIComponent(`https://${window.location.host}/api/smlogin`)}&failure=${encodeURIComponent(`https://${window.location.host}/singin/api/smlogin`)}`}><img src={facebook}/></a> </button>
            <button type="button" className="h-10 w-10 mr-2"> <a href={`https://login.${window.location.host}/google?success=${encodeURIComponent(`https://${window.location.host}/api/smlogin`)}&failure=${encodeURIComponent(`https://${window.location.host}/singin/api/smlogin`)}`}><img src={google}/></a> </button>
            {/*<button type="button" className="h-10 w-10 mr-2"> <img src={telegram}/> </button>*/}
          </div>
          <p className="text-xs text-lightGray">
            <T
              uk = 'Натискаючи кнопку "Зареєструватися", ви автоматично погоджуєтесь з умовами'
              en = "By clicking the “Register” button, you automatically accept the terms"
            >Нажимая кнопку “Зарегистрироваться”, вы автоматически принимаете условия</T>
            {" "}
            {/*<Link className="text-primary underline" to="/privacy-policy">*/}
              <div className="MainButtonText underline"><T uk = "Політики обробки персональних даних" en = "Personal data processing policies">Политики обработки персональных данных</T></div>
            {/*</Link>*/}
          </p>
        </form>
      </div>
    </section>
  );
};

export default Registration;
