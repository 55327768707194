import React from "react";
import {useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import LineChart from '../components/LineChart';

import "swiper/css";
import "swiper/css/navigation";

import "../assets/css/swiper.css";

import { Emoji, EmojiStyle } from 'emoji-picker-react';


import { Link } from "react-router-dom";


const Catalog = () => {
  const [persons, setPersons] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect( () => {
    const fetchData = async () => {
      const response = (await directus.items('Person').readByQuery({
        limit: 10,
        fields: ['*', 'activity.directus_files_id', 'category.person_category_id.name'],
        filter: {
          is_approved: {
            '_eq': true,
          },
          is_ipo: {
            '_eq': false,
          }
        }
      })).data;
      response.forEach( (x) => {

        let person = {};

        person.id = x.id;
        person.img = `https://directus.${window.location.host}/assets/${x.head_photo}`;
        person.title = x.name || x.first_name + ' ' + x.last_name;
        person.subtitle = x.category.map(item => (item.person_category_id.name)).join(', ');
        person.amount = x.share_quantity;
        person.price = x.share_price;
        person.cash = x.capitalisation;
        person.is_ipo = x.is_ipo
        person.listing_date = x.listing_date;
        person.purchase_price = x.purchase_price;
        person.listing_price = x.listing_price;
        person.token_sold = x.token_sold ;
        person.token_amount = x.token_amount;

        setPersons(prevState => [...prevState, person]);

      });

      setIsLoaded(x => true);

    }

    fetchData();
  }, []);

  function getStockData(personName) {
    const data = JSON.parse(localStorage.getItem('stockData'));
    return data[personName];
  }

  if( !isLoaded ) {
    return (<></>)
  };

  return (
    <>
      <section className="Background2 bg-[#191919] sm:py-[30px]">
        <div className="flex items-center pl-[72px] gap-[30px] lg:pl-5 sm:flex-col sm:pl-0">
          <div className="flex flex-col w-[300px]">
            <h2 className="font-semibold text-[28px] mb-[40px] sm:text-3xl">
              <T uk = "Каталог особистостей" en = "Personalities catalog">Каталог личностей</T>
            </h2>
            <p className="mb-[64px]">
              <T
                uk = "Інвестувати в популярну персону легко, надійно та прибутково. Тисни на персону та полетіли"
                en = "Investing in a popular person is easy, reliable and profitable. Click on the person and let's fly"
              >
                Инвестировать в популярную личность легко, надежно и прибыльно. Жми на персону и полетели
              </T><Emoji emojiStyle={EmojiStyle.GOOGLE} unified="1f680" size="20" />
            </p>
            <Link
              className="ButtonColor AlternativeButtonText font-medium btn btn-primary w-[150px] text-sm sm:w-full"
              to="/personalities" onClick={() => posthog.capture('people')}>
              <T uk = "Дивитися всіх" en = "Watch all">Смотреть всех</T>
            </Link>
          </div>
          <Swiper
            breakpoints={{
              "@0.75": {
                slidesPerView: 1.4,
                spaceBetween: 0,
              },
              "@1.00": {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              "@1.50": {
                slidesPerView: 3.2,
                spaceBetween: 0,
              },
            }}
            slidesPerView={1.4}
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            className="swiper-catalog"
          >
            <div className="py-[60px]">
              {persons.map((item, index) => (
                <SwiperSlide
                  className="border-x border-white/10 py-[75px] sm:py-5 sm:px"
                  key={`${item.title}_${index}`}
                >
                  <div className="flex flex-col bg-transparent items-center">
                  <Link to={`/bloggerbuy/${item.id}`}>
                    <img
                      className="w-[110px] h-[110px] mb-[18px]"
                      src={item.img}
                      alt={item.title}
                    />
                     </Link>
                    <Link to={`/bloggerbuy/${item.id}`}>
                    <h2 className="mb-1 text-white font-medium">
                      <T>{item.title}</T>
                    </h2>
                    </Link>
                    <h3 className="MainText text-xs text-white/50 mb-6">
                      <T>{item.subtitle}</T>
                    </h3>
                    <div className="">
                      <LineChart marginLeft = {"0px"} width = {270} height = {100} lineColor = {"#32CD32"} altLineColor = {"#DC143C"} chartData = {getStockData(item.title)}/>
                    </div>
                    <p className="text-xs mb-[10px]">
                      <T uk = "Ціна за акцію" en = "Price per share">Цена за акцию</T>
                    </p>
                    <h3 className="text-[28px] font-semibold mb-[25px]">
                      $ {item.listing_price}
                    </h3>
                    <p className="MainText text-xs mb-7">
                      <span className="MainText text-white/50">
                        <T uk = "Капіталізація" en = "Capitalization">Капитализация</T>
                      </span>{" "}
                      $ {round(item.listing_price * item.token_sold, 2)}
                    </p>
                    <Link className="MainButtonText btn btn-transparent w-[125px]" to={`/bloggerbuy/${item.id}`}>
                      <T uk = "Детальніше" en = "Read more">Подробнее</T>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Catalog;
