import React from "react";
import { Link } from "react-router-dom";
import AboutChartImg from "../assets/img/about-chart.png";
import ArrowRightImg from "../assets/img/arrow-right.png";
import About1Img from "../assets/img/about-1.png";
import About2Img from "../assets/img/about-2.png";
import About3Img from "../assets/img/about-3.png";
import Fade from 'react-reveal/Fade';

const About = () => {

  const aboutList = [
    {
      img: About1Img,
      title: "Зарабатывай просто",
      subtitle:
        "Это почти также просто, как купить кофе. Покупай и продавай персональные акции, зарабатывай на ценовой разнице",
    },
    {
      img: About2Img,
      title: "Получай бенефиты",
      subtitle:
        "Приобретай персональные акции и получай уникальные бенефиты. Чем больше персональных акций тем больше преимуществ",
    },
    {
      img: About3Img,
      title: "Выпускай акции",
      subtitle:
        "Твоя задача убедить людей, в том, что ты будешь популярным и успешным и они смогут на тебе заработать. Таких людей будет становиться все больше",
    },
  ];

  return (
    <>
     <section className="Background5 py-[80px] bg-black md:py-[50px] sm:py-[30px]">
        <div className="container">
        <div className="flex gap-[80px] mb-[100px] md:mb-[20px] md:flex-col sm:gap-7">
            <div className="flex flex-col">
            <h2 className="text-[28px] font-semibold mb-[40px] sm:text-3xl">
                <T
                  uk = "Про платформу"
                  en = "About the platform"
                >
                  О платформе
                </T>
              </h2>
              <p className="MainText text-white/70 mb-[62px]">
                <T
                  uk = "Відкрий для себе світ персональних акцій, і дізнайся, як Personal Shares конвертує талант і популярність людей у цінні активи. Торгуючи персональними акціями, ти не тільки заробляєш сам та отримуєш цінні нагороди, а й допомагаєш відомим людям, артистам, впливовим підприємцям і ще не розкрученим талантам, реалізовувати круті проекти."
                  en = "Discover the world of personal shares, and find out how Personal Shares converts people's talent and fame into valuable assets. By trading personal shares, you not only earn yourself and receive valuable awards, but also help famous people, artists, influential entrepreneurs and untwisted talents to implement cool projects."
                >
                  Открой для себя мир персональных акций, и узнай, как Personal Shares
                  конвертирует талант и известность людей в ценные активы. Торгуя
                  персональными акциями, ты не только зарабатываешь сам и
                  получаешь ценные награды, но и помогаешь известным людям,
                  артистам, влиятельным предпринимателям и еще не раскрученным
                  талантам, реализоваться крутым проектам.
                </T>
              </p>
              <Link to="/about" className="flex items-center gap-[10px]" onClick={() => window.scrollTo(0, 0)}>
                <img className="w-[16px]" src={ArrowRightImg} alt="" />
                <h3 className="">
                  <T
                    uk = "Детальніше про платформу"
                    en = "More about the platform"
                  >
                    Подробнее о платформе
                  </T>
                </h3>
              </Link>
            </div>
            <img
              className="w-[587px] h-[268px] md:mx-auto sm:w-full sm:h-auto"
              src={AboutChartImg}
              alt="About Chart"
            />
          </div>
          <Fade bottom> <h2 className="MainText text-[34px] font-semibold text-center mb-[100px] sm:mb-10 sm:text-3xl mx-auto">
            <T
              uk = "Переваги персональних акцій"
              en = "Benefits of personal shares"
            >
              Преимущества персональных акций
            </T>
          </h2>
          <div className="flex justify-center gap-[110px] md:gap-12 sm:flex-wrap">
              {aboutList.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center text-center w-[272px] md:w-[calc(100%_-_40px)] sm:w-full"
                >
                  <img
                    className="mb-[60px] w-[110px] h-[110px] sm:mb-5"
                    src={item.img}
                    alt=""
                  />
                  <h4 className="mb-5 text-xl font-bold"><T>{item.title}</T></h4>
                  <p className="MainText text-white/50 text-sm"><T>{item.subtitle}</T></p>
                </div>
              ))}
          </div></Fade>
        </div>
      </section>
    </>
  );
};

export default About;
