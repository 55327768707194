import React from "react";

import {useState, useEffect, useMemo, fetchData, setState} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from '../components/LineChart';
import { HashLink } from 'react-router-hash-link';


const BloggersList = (item, index) => {

    function getStockData(personName) {
        const data = JSON.parse(localStorage.getItem('stockData'));
        return data[personName];
      }

    return(
    <tbody key={index}>

        <div className="abouthidden Background3 flex md:flex-col mt-[20px] bg-[#313132] h-auto md:w-full">
            <div className="w-[350px] md:mx-auto mobileL:w-[300px] ml-[20px]">
            <div className="flex">
               <Link to={`/bloggerbuy/${item.id}`}>
                    <img
                        className="h-[70px] w-[70px] bg-cover bg-center mt-[38px]"
                        src={item.img}
                        alt=""
                    />
               </Link>
               <Link to={`/bloggerbuy/${item.id}`}>
                  <h2 className="text-[18px] ml-[30px] font-semibold mt-[50px] ml-[10px] text-center">
                    <T>{item.title}</T>
                  </h2>
                  <h2 className="text-[14px] ml-[30px] font-roboto mt-[5px] text-center opacity-50">
                <T>{item.subtitle}</T>
              </h2>
               </Link>
            </div>

              <div className= "max-w-[320px] mx-auto">
                <LineChart width = {270} height = {100} lineColor = {"#0ec40e"} altLineColor = {"#E32636"} chartData = {getStockData(item.title)}/>
              </div>
              <div className="columns-3 mt-[26px] mb-[10px]">
                <h2 className="text-[11px] font-roboto text-center opacity-50">
                    <T uk = "Остання ціна" en = "Last price">Последняя цена</T>
                </h2>
                <h2 className="text-[16px] font-semibold text-center">
                    $ {item.listing_price}
                </h2>
                <h2 className="text-[11px] font-roboto text-center opacity-50">
                    <T uk = "Капіталізація" en = "Capitalization">Капитализация</T>
                </h2>
                <h2 className="text-[16px] font-semibold text-center">
                    $ {round(item.listing_price * item.token_sold, 2).toFixed(2)}
                </h2>
              <HashLink className="ButtonText btn w-[100px] bg-[#0ec40e] text-[16px] mx-auto" to={`/bloggerbuy/${item.id}#converter`}>
                  <T uk = "Купити" en = "Buy">Купить</T>
              </HashLink>
              </div>
            </div>

          </div>

        <tr className="md:hidden">
            <td className="px-4 py-3 border-y border-white/10 pl-[72px] md:pl-0">
                <div className="flex gap-[25px]">
                <img onClick={(e) => {e.preventDefault();window.location.href=`/bloggerbuy/${item.id}`;}} className="w-[50px] sm:w-[40px] sm:h-[40px] imghover" src={item.img} alt="" />
                    <div className="flex flex-col gap-1">
                        <Link to={`/bloggerbuy/${item.id}`}>
                        <h3 className="text-sm"><T>{item.title}</T></h3>
                        </Link>
                        <p className="text-[#929496] text-xs">
                            <T>{item.subtitle}</T>
                        </p>
                    </div>
                </div>
            </td>
            <td className="md:text-center px-4 py-3 border-y border-white/10 text-sm">
                $ {item.listing_price}
            </td>
            <td className="md:text-center px-4 py-3 border-y border-white/10 text-sm">
                $ {round(item.listing_price * item.token_sold, 2).toFixed(2)}
            </td>
            <td className="px-4 py-3 text-lg text-gray-900 border-y border-white/10">
                <div className= "w-[200px]">
                    <LineChart height={60}
                        lineColor = {"#0ec40e"} altLineColor = {"#E32636"} chartData = {getStockData(item.title)}/>
                </div>
            </td>
            <td className="w-10 text-center border-y border-white/10 pr-[72px] md:pr-0">
                <HashLink className="ButtonText btn bg-[#0ec40e] w-[100px]" to={`/bloggerbuy/${item.id}#converter`}>
                    <T uk = "Купити" en = "Buy">Купить</T>
                </HashLink>
            </td>
        </tr>
    </tbody>
);
};

export default BloggersList;
