import React, { Component } from "react"
import Disqus from "disqus-react"

export default class extends Component {
  render() {
    const disqusShortname = "personalshares"
    const disqusConfig = {
        url: window.location.href,
        identifier: window.location.href,
        title: window.location.href
    }

      return (
      <section className="py-[40px] md:py-[20px] sm:py-[20px]">
          <div className="container">
              <div className="flex flex-col">
                  <div className="article-container">
                        <Disqus.DiscussionEmbed
                          shortname={disqusShortname}
                          config={disqusConfig}
                        />
                  </div>
              </div>
          </div>
      </section>
    )
  }
}
