import React from "react";
import * as R from 'ramda'
import {useState, useEffect, useRef} from "react";
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Popup from 'reactjs-popup';
import Avatar from "react-avatar-edit";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Link } from "react-router-dom";

import { MdOutlineClose } from "react-icons/md";
import Select, { AriaOnFocus } from 'react-select';
import Request from "../components/Request";
import PersonContent from "../components/PersonContent"
import SpecialOffer from "../components/SpecialOffer";
import ThemeSelect from "../components/ThemeSelect"

import StockAvatar from "../assets/img/stock_avatar.png";
import DefaultAvatar from "../assets/img/default_avatar.png";
import BackImg from "../assets/img/stock_background.png";
import Offers from "../components/Offers";
import LineChart from '../components/LineChart';
import Biography from "../components/Biography";

import YoutubeIconImg from "../assets/img/bloggerbuy/youtube-logo.png";
import TwitterIconImg from "../assets/img/bloggerbuy/twitter-logo.png";
import TikTokIconImg from "../assets/img/bloggerbuy/tiktok-logo.png";
import InstagramIconImg from "../assets/img/bloggerbuy/instagram-logo.png";
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Manipulation } from "swiper";
import { useSwiper, useSwiperSlide } from "swiper/react";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const User = () => {

  const language = useStore(x => x.language.language)

  const [checked, setChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [person, setPerson] = useState([]);
  const [defaultCategoryList, setDefaultCategoryList] = useState([]);
  const [userCategoryList, setCategoryList] = useState();
  const [backImgSource, setBackImgSource] = useState(BackImg);
  const [backgroundSelected, setBackgroundSelected] = useState(false);
  const [backImgFile, setBackImgFile] = useState();
  const [videoSource, setVideoSource] = React.useState();
  const [videoFile, setVideoFile] = useState();

  const [isEditing, setIsEditing] = useState(false);//чи сторінка зараз редагується
  const [isFirstEdit, setIsFirstEdit] = useState(false);//чи це перше редагування сторінки. Перше редагування - редагування выдразу після реєстрації коли користувач обов'язково має залити дані. Якщо редагув не перше то з'являється кнопка "Скасувати редагуванняя"
  const [isApproved, setIsApproved] = useState(false);//чи затверджений юзер як персона

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const location = useLocation()
  const navigate = useNavigate();

  const [requiredField, setRequiredField] = useState("Обязательное поле");
  const [matchesField, setMatchesField] = useState("Спецсимволы недопустимы");
  const [urlField, setUrlField] = useState("Некорректная ссылка");
  const [urlMatchesField, setUrlMatchesField] = useState("Ссылка должна вести на сайт");


  const schema = yup.object().shape({
    name: yup
    .string()
    .required(requiredField)
    .matches(/^[\p{L}'-]+$/u, { message: matchesField}),
    surname: yup
    .string()
    .required(requiredField)
    .matches(/^[\p{L}'-]+$/u, { message: matchesField}),
    username: yup
    .string()
    .required(requiredField),
    twitterLink: yup
    .string()
    .url(urlField)
    .matches(/(twitter)/,{ message: `${urlMatchesField} Twitter`, excludeEmptyString: true}),
    youtubeLink: yup
    .string()
    .url(urlField)
    .matches(/(youtube)/,{ message: `${urlMatchesField} YouTube`, excludeEmptyString: true}),
    instagramLink: yup
    .string()
    .url(urlField)
    .matches(/(instagram)/,{ message: `${urlMatchesField} Instagram`, excludeEmptyString: true}),
    tiktokLink: yup
    .string()
    .url(urlField)
    .matches(/(tiktok)/,{ message: `${urlMatchesField} TikTok`, excludeEmptyString: true}),
    aboutme: yup
    .string()
}, []);


  //Управление поп-апом для добавления спецпредложения
  const [openAddSpecialOfferPopUp, setOpenAddSpecialOfferPopUp] = useState(false);
  const closeAddSpecialOfferPopUpModal = () => {setOpenAddSpecialOfferPopUp(false)};

  //Управление поп-апом для добавления контента
  const [openAddContentPopUp, setOpenAddContentPopUp] = useState(false);
  const closeAddContentPopUpModal = () => {setOpenAddContentPopUp(false)};
  const addContentPopUpRef = useRef();

  let currentUserPersonID = null;

  //Нужно для рендеринга страницы вручную
  const [rerender, setRerender] = useState(false);
  const rer = () => setRerender(x=> !x)

  const handleVideoMessageDelete = async () => {
    //alert('Удаление видео');
    await fetch(`https://${window.location.host}/api/files/${person.video_message}`, {
      method: 'DELETE'
    })
    setVideoSource(null);
    setIsEditing(false);
  }

  function DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    }

    //Меню с тремя точками
    function MeatballsMenu() {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const swiper = useSwiper()
      const swiperSlide = useSwiperSlide()
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const deleteClickHandler = async () => {
        // console.log(person.instRSSContent[swiper.clickedIndex])
        // console.log(person.instRSSContent.filter(x => x.id != person.instRSSContent[swiper.clickedIndex].id))

        //Удалить из Instrss
        let response = await fetch(`https://${window.location.host}/api/items/Instrss/${person.instRSSContent[swiper.clickedIndex].id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }});

        let result = await response;
        // console.log(result)

        //Заново отрисовать страницу пользователя после удаления предложения
        rer();
        //Закрыть меню
        handleClose();
      }

      return (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{color: "grey"}}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={deleteClickHandler}><T uk = "Видалити" en = "Delete">Удалить</T></MenuItem>
          </Menu>
        </div>
      );
    }

  React.useEffect(() => {
    if(ref.current) closeTooltip();
  }, [location])


  React.useEffect((а) => {

    const fetchData = async () => {
      let response = await fetch(`https://${window.location.host}/api/current-user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        }
      });
      if (!response.ok) {
        navigate('/')
      }
      currentUserPersonID = (await response.json()).person
      console.log(currentUserPersonID)
      let personResponse = await directus.items('Person').readOne(currentUserPersonID, {
        fields: [
          '*',
          'category.person_category_id.*',
          'offers.offer_id.*',
        ]
      });

      const response2 = ((await directus.items('Instrss').readByQuery({filter: {iduser: {'_eq': currentUserPersonID}}, fields: ['id', 'image.filename_disk, title, urlpost']})).data).reverse();
      personResponse.instRSSContent = response2;
      personResponse.act = response2.map(item => `https://directus.${window.location.host}/assets/${item.image.filename_disk}`);
      personResponse.act2 = response2.map(title => `${title.title}`);
      personResponse.act3 = response2.map(urlpost => `${urlpost.urlpost}`);
      personResponse.offers.reverse();

      let allCategories = await directus.items('person_category').readByQuery();
      setDefaultCategoryList(allCategories.data); //Все категории
      setCategoryList(personResponse.category.map(x => x.person_category_id)) //Категории текущего

      personResponse.sm = [];
      let sm = [
        {name: "instagram", link: personResponse.Instagram, logo: InstagramIconImg},
        {name: "tiktok", link: personResponse.tiktok, logo: TikTokIconImg},
        {name: "twitter", link: personResponse.twitter, logo: TwitterIconImg},
        {name: "youtube", link: personResponse.youtube, logo: YoutubeIconImg},
      ];

      sm.forEach((item, i) => {
        if (item.link) personResponse.sm.push(item);
      });

      if (!personResponse.nickname){ //
        setIsFirstEdit(true);//Якщо у користувача все ще нема юзернейму, то він тільки зареєструвався і йому треба показати сторінку редагування без можливості вийти з неї
        setIsEditing(true);
      }

      // Розкоментити те що нижче
      if (personResponse.is_approved){ //перевіряємо чи користувач підтверджений нами як персона
        setIsApproved(true);
      }

      if (personResponse.background) {
        setBackImgSource(`https://directus.${window.location.host}/assets/${personResponse.background}`)
        setBackgroundSelected(true);
      };
      if (personResponse.video_message) setVideoSource(`https://directus.${window.location.host}/assets/${personResponse.video_message}`);
      //setStoreImage(`https://directus.${window.location.host}/assets/${prsn.head_photo}`)
      setPerson(personResponse);

      setIsLoaded(x => true);
    }
    fetchData();
  }, [isEditing, rerender])

  useEffect( () => {
    const getTheme = theme => {
      if(theme == "Dark"){
        return "theme-dark"
      }
      if(theme == "Light") {
        return "theme-light"
      } if(theme == "Blue") {
        return "theme-blue"
      } if(theme == "Pink") {
        return "theme-pink"
      } if(theme == "Green") {
        return "theme-green"
      }if(theme == "Red") {
        return "theme-red"
      }if(theme == "Orange") {
        return "theme-orange"
      }if(theme == "Purple") {
        return "theme-purple"
      }if(theme == "Yellow") {
        return "theme-yellow"
      }if(theme == "Pink") {
        return "theme-pink"
      }
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // is mobile..
      const videos = document.getElementsByTagName('video');

      for(let i = 0; i < videos.length; i++) {
        videos[i].setAttribute("autoplay", "autoplay");
        videos[i].setAttribute("playsinline", "playsinline");
      }
    }
    const themes = ["theme-dark","theme-light","theme-blue","theme-green","theme-red","theme-orange","theme-purple","theme-yellow","theme-pink"];
    const savedTheme = JSON.parse(window.localStorage.getItem('Current_theme'));
    //Применить пользоваельскую тему для этой страницы, если она указана в настройках
    if (person.theme) {
      document.querySelector('body').classList.remove(...themes);
      document.querySelector('body').classList.add(getTheme(person.theme));
      window.localStorage.setItem('preventThemeChange', true)
    }

    return () => {
      //Если тема страницы отличается от темы сайта, то при выходе из страницы вернуть тему сайта обратно
      if (person.theme) {
        window.localStorage.setItem('preventThemeChange', false)
        document.querySelector('body').classList.remove(...themes);
        document.querySelector('body').classList.add(getTheme(savedTheme.label));
        document.querySelector('body').classList.add(getTheme(JSON.parse(window.localStorage.getItem('Current_theme')).label));
      }
    }
  }, [isLoaded, person]);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleCancel = () => {
    setVideoSource(null); //Сбросить видеопредставление на форме

    //Сбросить аватарку на форме
    setStoreImage(x => []);
    setImgCrop(false);
    setAvatarFilename([]);

    setCategoryInput(null); //Сбросить категорию личности на форме

    //Сбросить фоновое изображение на форме
    setBackgroundSelected(false);
    setBackImgSource(BackImg);
    setBackImgFile(null);
    reset(); //Сбросить значения формы
    setIsLoaded(false);
    setIsEditing(false); //Выйти из режима редактирования
  }

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: true,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const [dataSaveMessage, setDataSaveMessage] = useState('Данные успешно сохранены');
  const [shortErrorMessage, setShortErrorMessage] = useState('Ошибка!');

  useEffect(() => {

    if (language == 'ru'){
      setShortErrorMessage('Ошибка!');
      setDataSaveMessage('Данные успешно сохранены');
    };

    if (language == 'uk'){
      setShortErrorMessage('Помилка!');
      setDataSaveMessage('Дані успішно збережені');
    };

    if (language == 'en'){
      setShortErrorMessage('Error!');
      setDataSaveMessage('Data saved successfully');
    };

  }, [language]);

  const submitAlert = () => {
    Toast.fire({
      icon: 'success',
      title: dataSaveMessage
    })
  }

  const submitErrorAlert = (m) => {
    Toast.fire({
      icon: 'error',
      title: m,
    })
  }

  const onSubmit = async (x) => {
    let userCategories = userCategoryList.map(x => {return {person_category_id: x}})
    let response = await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
          first_name: x.name,
          last_name: x.surname,
          nickname: x.username,
          biography: x.aboutme,
          tiktok: x.tiktokLink,
          Instagram: x.instagramLink,
          twitter: x.twitterLink,
          youtube: x.youtubeLink,
          category: userCategories,
          theme: theme?.value,
        })
    })
    if (!response.ok) {
      switch(response.status) {
        case 401:
          submitErrorAlert(shortErrorMessage);
          return;
          break;
        default:
        submitErrorAlert(shortErrorMessage);
          return;
      }
    }

    if (imgCrop) {
      //Сохранить аватарку
      let avatarFormData = new FormData();
      avatarFormData.append('file', DataURIToBlob(imgCrop), avatarFilename);

      if (person.head_photo === null) {
        // alert('No avatar!')

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: avatarFormData
        })

        x = (await x.json()).data;
        console.log(x)
        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            head_photo: x.id
            })
        })

      } else {
        // alert('Updating avatar!')

        await fetch(`https://${window.location.host}/api/files/${person.head_photo}`, {
          method: 'DELETE'
        })

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: avatarFormData
        })

        x = (await x.json()).data;

        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            head_photo: x.id
            })
        })
      }
    }

    if (backImgFile) {
      //Сохранить задний фон
      let backgroundFormData = new FormData();
      backgroundFormData.append('file', backImgFile);

      if (person.background === null) {
        //alert('No background!')

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: backgroundFormData
        })

        x = (await x.json()).data;
        console.log(x)
        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            background: x.id
            })
        })

      } else {
        //alert('Updating background!')

        await fetch(`https://${window.location.host}/api/files/${person.background}`, {
          method: 'DELETE'
        })

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: backgroundFormData
        })

        x = (await x.json()).data;

        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            background: x.id
            })
        })
      }
    }

    if (videoFile) {
      //Сохранить видеопредставление
      let videoMessageFormData = new FormData();
      videoMessageFormData.append('file', videoFile);

      if (person.video_message === null) {
        //alert('No video message!')

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: videoMessageFormData
        })

        x = (await x.json()).data;
        console.log(x)
        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            video_message: x.id
            })
        })

      } else {
        //alert('Updating video message!')

        await fetch(`https://${window.location.host}/api/files/${person.video_message}`, {
          method: 'DELETE'
        })

        let x = await fetch(`https://${window.location.host}/api/files`, {
          method: 'POST',
          body: videoMessageFormData
        })

        x = (await x.json()).data;

        await fetch(`https://${window.location.host}/api/items/Person/${person.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            video_message: x.id
            })
        })
      }
    }

    submitAlert();

    //Обновить состояние после сохранения данных
    let personResponse = await directus.items('Person').readOne(person.id, {
      fields: [
        '*',
        'category.person_category_id.*',
        'offers.offer_id.*',
      ]
    });

    const response2 = ((await directus.items('Instrss').readByQuery({filter: {iduser: {'_eq': currentUserPersonID}}, fields: ['id', 'image.filename_disk, title, urlpost']})).data).reverse();
    personResponse.instRSSContent = response2;
    personResponse.act = response2.map(item => `https://directus.${window.location.host}/assets/${item.image.filename_disk}`);
    personResponse.act2 = response2.map(title => `${title.title}`);
    personResponse.act3 = response2.map(urlpost => `${urlpost.urlpost}`);
    personResponse.offers.reverse();

    personResponse.sm = [];
    let sm = [
      {name: "instagram", link: personResponse.Instagram, logo: InstagramIconImg},
      {name: "tiktok", link: personResponse.tiktok, logo: TikTokIconImg},
      {name: "twitter", link: personResponse.twitter, logo: TwitterIconImg},
      {name: "youtube", link: personResponse.youtube, logo: YoutubeIconImg},
    ];
    sm.forEach((item, i) => {
      if (item.link) personResponse.sm.push(item);
    });

    setPerson(x => ({...person, ...personResponse}));

    setIsFirstEdit(false);
    setIsEditing(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const watchName = watch("name");
  const watchSurname = watch("surname");
  const watchUsername = watch("username");
  const watchAboutMe = watch("aboutme");
  const watchTiktokLink = watch("tiktokLink");
  const watchInstagramLink = watch("instagramLink");
  const watchYoutubeLink = watch("youtubeLink");
  const watchTwitterLink = watch("twitterLink");

  const [imgCrop, setImgCrop] = useState(false);
  const [avatarFilename, setAvatarFilename] = useState([]);
  const [storeImage, setStoreImage] = useState([]);

  const onCrop = view => {
    setImgCrop(view);
  }

  const onFileLoad = file => {
    setAvatarFilename(file.name)
  }

  const saveImage = async () => {
    setStoreImage([...storeImage, {imgCrop}]);
  }


  const profileImageShow = storeImage.map( item => item.imgCrop );

  const [categoryInput, setCategoryInput] = useState();
  const [themeInput, setThemeInput] = useState();
  const [theme, setTheme] = useState();

  const onCategorySubmit = () => {
    //ID категории в БД
    let categoryID;

    //Если введена категория и она не повторяется в списке
    if (categoryInput && !userCategoryList.some(x => x.name === categoryInput.label)) {
      defaultCategoryList.forEach((item, i) => {

        //Если введённая категория уже есть в БД, то получить её ID
        if (item.name == categoryInput.label) {
          categoryID = item.id;
          return;
        }
      });
      //alert(categoryID)

      setCategoryList([...userCategoryList, {id: categoryID, name: categoryInput.label}]);
      setCategoryInput('');
    }
  }

  const handleCategoryChange = (e) => {
    setCategoryInput(e);
  }

  const handleThemeChange = (e) => {
    setTheme(e);
  }

  const categoryInputOptions = [];

  const themeOptions = [
    { label: "Default", value: '' },
    { label: "Dark", value: 'Dark' },
    { label: "Light", value: 'Light' },
    { label: "Blue", value: 'Blue' },
    { label: "Pink", value: 'Pink' },
    { label: "Green", value: 'Green' },
    { label: "Orange", value: 'Orange' },
    { label: "Red", value: 'Red' },
    { label: "Purple", value: 'Purple' },
    { label: "Yellow", value: 'Yellow' },
  ];

  defaultCategoryList.forEach(element => {categoryInputOptions.push(
  {
      "label": element.name,
      "value": element.name.toLowerCase()
  }
)});

  const removeElement = (index) => {
    const newCategories = userCategoryList.filter((_, i) => i !== index);
    setCategoryList(newCategories);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const categoriesInputStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold !important" : "normal !important",
      color: state.isSelected ? "var(--button-color) !important" : "var(--color) !important",
      backgroundColor: state.isFocused ? "var(--button-color) !important" : "transparent !important",
      color: state.isFocused ? "var(--alt-button-text-color) !important" : "var(--color) !important",
      cursor: state.isFocused ? "pointer !important" : "default !important",
      borderRadius: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--color) !important",
      backgroundColor: "transparent !important",
    }),
    control: (base, state) => ({
      ...base,
      background: "var(--input) !important",
      color: "var(--color) !important",
      borderColor: "none",
      borderWidth: "0",
      padding: "3",
      height: "42px",
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      paddingRight: 0,
      paddingTop: 0,
      backgroundColor: "var(--input) !important",
    }),
    input: (provided) => ({
      ...provided,
      color: "var(--color) !important",
      boxShadow: "none",
    }),
  };

  const backImgInputRef = React.useRef();



  const handleBackImgChange = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setBackgroundSelected(true);
    setBackImgSource(url);
    setBackImgFile(file);
  };

  const handleBackImgChoose = (e) => {
    backImgInputRef.current.click();
  };


  const videoInputRef = React.useRef();


  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setVideoSource(url);
    setVideoFile(file);
  };

  const handleVideoChoose = (e) => {
    videoInputRef.current.click();
  };
  function getStockData(personName) {
    const data = JSON.parse(localStorage.getItem('stockData'));
    return data[personName];
  }

  if( !isLoaded ) {
    return (<></>)
  };


  return (
    <div className="container py-10 sm:py-[30px] fadeIn md:mt-[70px] sm:mt-[64px]">
    <Popup
          ref={ref}
          open={openAddSpecialOfferPopUp}
          onClose={closeAddSpecialOfferPopUpModal}
          modal
          className="testbl">
        {close => (
            <div className="modal">
                <div className="content">
                    <SpecialOffer person={person.id} closePopUp={close}/>
                </div>
            </div>
        )}
      </Popup>
      <Popup
            ref={addContentPopUpRef}
            open={openAddContentPopUp}
            onClose={closeAddContentPopUpModal}
            modal
            className="testbl">
          {close => (
              <div className="modal">
                  <div className="content">
                      <PersonContent person={person.id} closePopUp={close} handleCancel={handleCancel}/>
                  </div>
              </div>
          )}
        </Popup>
    <h2 className="font-semibold mb-19 text-[44px] text-center sm:text-3xl mb-[60px]">
          <T uk = "Моя сторінка" en = "My page">Моя страница</T>
    </h2>
    {isEditing ?
      (<>
        <input
          ref={backImgInputRef}
          className="VideoInput_input hidden"
          type="file"
          onChange={handleBackImgChange}
          accept=".png,.jpg"
        />
        {backgroundSelected ?
          <img
          className="h-[240px] w-full flex bg-cover bg-center bg-darkGray"
          src={backImgSource}
          onClick={handleBackImgChoose}
        />
          :
          <div
          className="Background3 h-[240px] w-full bg-cover bg-center bg-darkGray p-[5px]"
          src={backImgSource}
          onClick={handleBackImgChoose}
        ><div className="BackgroundimageBorder w-full border-2 border-white border-dashed h-[230px]">
          <img className="Logo mx-auto h-full md:mt-[-30px] md:h-[180px]" src={backImgSource} alt="" />
        </div>
        </div>
        }
        <form
          className="Background3 bg-darkGray pt-6 pb-10 p-[60px] mx-auto grid grid-cols-4 gap-[30px] relative z-10 md:p-[20px] md:grid-cols-1 md:flex md:flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Popup
              ref={ref}
              trigger={ <div className="flex items-center justify-between ml-[12px] mt-[-80px] h-[60px] w-full md:px-3 md:py-3 md:mx-auto md:mb-[40px]">
              <img
                  className="w-[200px] h-[200px] rounded-full mr-10 object-cover md:w-[180px] md:h-[180px] md:mx-auto"
                  src={profileImageShow.length ? profileImageShow[profileImageShow.length - 1] : (person.head_photo === null) ? StockAvatar : `https://directus.${window.location.host}/assets/${person.head_photo}`}
                  alt=""
              />
              </div>}
              modal
              className="testbl">

            {close => (
                <div className="modal">
                    <div className="content">
                    <div className="confirmation-content flex flex-col items-center">
                      <div className="flex flex-col items-center mt-5 w-12">
                        <div className="flex flex-col justify-around w-12 mt-4 items-center">
                          <MdOutlineClose className="ButtonText absolute cursor-pointer right-[-170px] top-1 text-3xl sm:text-2xl md:right-[10px]" onClick={() => close()} />
                          <Avatar width={400} height={300} onCrop={onCrop} onFileLoad={onFileLoad} labelStyle={{color:"var(--alt-text-color)",fontSize:"25px"}}/>
                          <button onClick={() => { saveImage(); close();}} className="ButtonText btn btn-primary col-span-1 mt-2 w-[90px] text-xs cursor-pointer"><T uk = "Обрізати зображення" en = "Crop image">Обрезать изображение</T></button>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
            )}
          </Popup>
            <label
              className="flex flex-col col-span-1 gap-1 text-xs text-lightGray relative"
              htmlFor="name"
            >
            <T uk = "Ім'я" en = "Name">Имя</T>
            <input
              className={`InputField
              ${errors?.name ? "border-[#D81F26]" : "border-gray"}
              ${
                watchName && !errors?.name ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="name"
              defaultValue={person.first_name}
              id="name"
              name="name"
              autoComplete="off"
              {...register("name")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.name?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-1 gap-1 text-xs text-lightGray relative"
            htmlFor="surname"
            >
            <T uk = "Прізвище" en = "Surname">Фамилия</T>
            <input
              className={`InputField
              ${errors?.surname ? "border-[#D81F26]" : "border-gray"}
              ${
                watchSurname && !errors?.surname ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="surname"
              defaultValue={person.last_name}
              id="surname"
              name="surname"
              autoComplete="off"
              {...register("surname")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.surname?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-1 gap-1 text-xs text-lightGray relative"
            htmlFor="username"
            >
            <T uk = "Нікнейм" en = "Username">Никнейм</T>
            <input
              className={`InputField
              ${errors?.username ? "border-[#D81F26]" : "border-gray"}
              ${
                watchUsername && !errors?.username ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="username"
              defaultValue={person.nickname}
              id="username"
              name="username"
              autoComplete="off"
              {...register("username")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.username?.message}</T>
            </p>
          </label>
            <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor={`tiktokLink`}
            >
            <T uk = {`Посилання на TikTok`} en = {`Link to TikTok`}>{`Ссылка на TikTok`}</T>
            <input
              className={`InputField
              ${errors?.tiktokLink ? "border-[#D81F26]" : "border-gray"}
              ${
                watchTiktokLink && !errors?.tiktokLink ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="tiktokLink"
              defaultValue={person.tiktok}
              id="tiktokLink"
              name="tiktokLink"
              autoComplete="off"
              {...register(`tiktokLink`)}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.tiktokLink?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor={`instagramLink`}
            >
            <T uk = {`Посилання на Instagram`} en = {`Link to Instagram`}>{`Ссылка на Instagram`}</T>
            <input
              className={`InputField
              ${errors?.instagramLink ? "border-[#D81F26]" : "border-gray"}
              ${
                watchInstagramLink && !errors?.instagramLink ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="instagramLink"
              defaultValue={person.Instagram}
              id="instagramLink"
              name="instagramLink"
              autoComplete="off"
              {...register(`instagramLink`)}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.instagramLink?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor={`youtubeLink`}
            >
            <T uk = {`Посилання на YouTube`} en = {`Link to YouTube`}>{`Ссылка на YouTube`}</T>
            <input
              className={`InputField
              ${errors?.youtubeLink ? "border-[#D81F26]" : "border-gray"}
              ${
                watchYoutubeLink && !errors?.youtubeLink ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="youtubeLink"
              defaultValue={person.youtube}
              id="youtubeLink"
              name="youtubeLink"
              autoComplete="off"
              {...register(`youtubeLink`)}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.youtubeLink?.message}</T>
            </p>
          </label>
          <label
            className="flex flex-col col-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor={`twitterLink`}
            >
            <T uk = {`Посилання на Twitter`} en = {`Link to Twitter`}>{`Ссылка на Twitter`}</T>
            <input
              className={`InputField
              ${errors?.twitterLink ? "border-[#D81F26]" : "border-gray"}
              ${
                watchTwitterLink && !errors?.twitterLink ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="twitterLinkk"
              defaultValue={person.twitter}
              id="twitterLink"
              name="twitterLink"
              autoComplete="off"
              {...register(`twitterLink`)}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
            <T>{errors?.twitterLink?.message}</T>
            </p>
          </label>
          {!isFirstEdit && isApproved && (<><label
              className="flex flex-col col-span-4 row-span-2 gap-1 text-xs text-lightGray relative"
              >
              <T uk = "Категорії" en = "Categories">Категории</T>
              <div
                className={`InputField flex gap-[10px] flex-wrap bg-gray p-3 text-white outline-none border border-gray min-h-[42px] relative`}
              >
                {userCategoryList.map((item, index) => {
            return (
                <span key={index} className={`ButtonColor AlternativeButtonText rounded-lg px-2 py-1.5 text-white outline-none relative`} onClick= {() => removeElement(index)}><T>{item.name}</T></span>
                  );
                })}
              </div>
            </label>
              <div className="flex col-span-2 md:flex-col">
              <label htmlFor="categoriesinput" className="flex flex-col gap-1 text-xs text-lightGray relative w-full whitespace-nowrap">
                <T uk = "Вибери категорію зі списку або додай свою:" en = "Choose a category from the list or add your own:">Выбери категорию из списка или добавь свою:</T>
              <Select
                value={categoryInput}
                styles = {categoriesInputStyles}
                inputId="categoriesinput"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={categoryInputOptions}
                onChange={handleCategoryChange}
              /></label>
              <div className="col-span-1">
                <div className="h-[20px]">
                </div>
                <a className="ButtonColor AlternativeButtonText btn btn-primary text-xs cursor-pointer h-[42px] md:mt-[-15px]" onClick={onCategorySubmit}>
                  <T uk = "Зберегти" en = "Save">Добавить</T>
                </a>
                </div>
              </div>
              <div className="flex col-span-2 md:flex-col">
              <label htmlFor="themesinput" className="flex flex-col gap-1 text-xs text-lightGray relative w-full whitespace-nowrap">
                <T uk = "Тема:" en = "Theme:">Тема:</T>
                <Select
                  inputId="themesinput"
                  value={themeInput}
                  defaultValue={themeOptions.find((option) => option.value === person.theme) || themeOptions.find((option) => option.value === "")}
                  styles = {categoriesInputStyles}
                  inputId="themesinput"
                  name="aria-live-color"
                  options={themeOptions}
                  onChange={handleThemeChange}
                />
                </label>
              </div>
            </>)}
          <label
            className="flex flex-col col-span-4 row-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor="aboutme"
            >
            <T uk = "Про мене" en = "About me">Обо мне</T>
            <textarea
              className={`InputField
              ${errors?.aboutme ? "border-[#D81F26]" : "border-gray"}
              ${
                watchAboutMe && !errors?.aboutme ? "!border-[green]" : ""
              } bg-gray p-3 h-[140px] text-white outline-none border md:h-[140px]`}
              type="aboutme"
              defaultValue={person.biography}
              id="aboutme"
              name="aboutme"
              autoComplete="off"
              {...register("aboutme")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              <T>{errors?.aboutme?.message}</T>
            </p>
          </label>
          {!isFirstEdit && isApproved &&(<>
            <button
              className="ButtonColor AlternativeButtonText btn btn-primary col-span-2 text-xs cursor-pointer md:col-span-4"
              type="button"
              onClick={() => setOpenAddSpecialOfferPopUp(o => !o)}
            >
              <T uk = "Додати спеціальну пропозицію" en = "Add a special offer">Добавить специальное предложение</T>
            </button>
            <button
              className="ButtonColor AlternativeButtonText btn btn-primary col-span-2 text-xs cursor-pointer md:col-span-4"
              type="button"
              onClick={() => setOpenAddContentPopUp(o => !o)}
            >
              <T uk = "Додати контент" en = "Add a content">Добавить контент</T>
            </button>
            </>
          )}
          {!isFirstEdit && isApproved &&(
          <>
            <input
            ref={videoInputRef}
            className="VideoInput_input hidden"
            type="file"
            onChange={handleVideoFileChange}
            accept=".mov,.mp4"
            />
            <button
                  className="ButtonColor AlternativeButtonText btn btn-primary col-span-4 text-xs cursor-pointer md:col-span-4"
                  onClick={handleVideoChoose}
                  type="button"
            >
                  <T uk = "Додати відеопредставлення на платформі" en = "Add a video representation on the platform">Добавить видеопредставление на платформе</T>
            </button>
          </>
        )}
        {!isFirstEdit && videoSource && isApproved &&(
                <video
                  className="VideoInput_video col-span-4 max-w-[80vw] max-h-[80vh]"
                  controls
                  src={videoSource}
                />
        )}
        {!isFirstEdit && person.video_message && isApproved && (<button
            className="ButtonText btn bg-[#E32636] col-span-4 text-xs cursor-pointer"
            onClick={handleVideoMessageDelete}
            type="button"
          >
            <T uk = "Видалити відеопредставлення" en = "Delete video representation">Удалить видеопредставление</T>
          </button>)}
          <div className="inline-flex flex-auto gap-5 col-span-4">
          {!isFirstEdit && (<button
              className="ButtonText btn bg-[#E32636] text-xs cursor-pointer items-center"
              onClick={handleCancel}
              type="button"
            >
              <T uk = "Скасувати редагування" en = "Cancel Editing">Отменить редактирование</T>
            </button>)}
          <button
            className="AlternativeButtonText btn bg-[#0ec40e] btn-primary text-xs cursor-pointer items-center"
            type="submit"
          >
            <T uk = "Зберегти" en = "Save">Сохранить</T>
          </button>
          </div>
        </form>
        <div className="Background3 bg-darkGray pt-6 pb-10 p-[60px] mx-auto grid grid-cols-4 gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12">
          {!isApproved && !person.join_request &&(
          <>
            <div className="flex col-span-4 gap-1 text-xs text-lightGray relative">
              <input
                className="checkbox"
                type="checkbox"
                id="checkbox"
                name="checkbox"
                checked={checked}
                onChange={handleChange}
              />
              <label
              className="flex flex-col gap-1 text-xs text-lightGray relative"
              ><T uk = "Хочу стати публічною персоною на Personal Shares"
                  en = "I want to become a public person on Personal Shares">
                  Хочу стать публичной личностью на Personal Shares
                </T>
              </label>
            </div>
            <Popup
                  ref={ref}
                  trigger={ checked && (<button
                  className="ButtonColor AlternativeButtonText btn btn-primary col-span-1 text-xs cursor-pointer md:col-span-4"
                  >
                  <T uk = "Залишити заявку" en = "Leave a request">Оставить заявку</T>
                  </button>
                  )}
                  modal
                  className="testbl">

                {close => (
                    <div className="modal">
                        <div className="content">
                            <Request person={person.id} closePopUp={close}/>
                        </div>
                    </div>
                )}
              </Popup>
          </>
          )}
        </div>
      </>) :
      <>
        <div
          className="h-[240px] w-full flex bg-cover bg-center bg-no-repeat items-end"
          style={{ backgroundImage: `url(https://directus.${window.location.host}/assets/${person.background})` }}
        >
          <div className="flex items-center justify-between px-[60px] bg-darkGray/60 h-[60px] w-full md:px-5 md:h-auto md:py-3">
            <div className="flex items-center">
              <img
                className="w-[200px] h-[200px] rounded-full mr-10 md:w-[100px] md:h-[100px] md:mr-4"
                src={(person.head_photo === null) ? DefaultAvatar : `https://directus.${window.location.host}/assets/${person.head_photo}`}
                alt=""
              />
              <div className="flex items-center sm:flex-col md:gap-4 sm:items-start sm:gap-2">
                <div className="flex">
                  {person.sm.map((social, index) => (
                    <a key={index} href={social.link} target="_blank">
                      <img src={social.logo} className="h-[50px] w-[50px]" alt="" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Background3 bg-darkGray pt-6 pb-10">
          <div className="flex justify-between pl-[300px] pr-[60px] mb-10 md:pl-5 sm:flex-col sm:text-center sm:pr-0">
            <div className="flex flex-col sm:gap-3">
              <h2 className="font-semibold text-4xl mb-1">
              {`${person.first_name} ${person.last_name}`}
                </h2>
                {isApproved &&(
              <p className="text-xs sm:mb-5">
                  <T uk = "Категорія:" en = "Category:">Категория:</T>{" "}
                <span className="MainText text-white/50">
                  {<T>{person.category.map(item => item.person_category_id.name).join(', ')}</T>}
                </span>
              </p>
                )}
            </div>
            {isApproved &&(
              <div className="w-[330px] md:w-[300px]">
                <LineChart className="mb-5"
                height = {100}
                backgroundColor = {"#313132"}
                lineColor = {"#0ec40e"}
                altLineColor = {"#E32636"}
                chartData = {getStockData(person.name || person.first_name + ' ' + person.last_name)}/>
            </div>
            )}
          </div>
          <div className="h-[1px] bg-white/10 mx-auto w-[calc(100%_-_120px)] mb-10 md:w-[calc(100%_-_40px)]"></div>
          <div className="flex justify-between px-[60px] lg:flex-col lg:gap-5 md:px-5">
            {isApproved &&(
              <>
                <div className="flex gap-12 md:justify-between sm:flex-wrap">
                  <div
                    className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
                  >
                    <h4 className="text-lightGray text-xs">
                      <T uk = "Капіталізація:" en = "Capitalization:">Капитализация:</T>
                    </h4>
                    <h3 className="font-semibold text-xl">$ {round(person.listing_price * person.token_sold, 2) ? round(person.listing_price * person.token_sold, 2) : "----"}</h3>
                  </div>
                </div>
                <div className="flex gap-12 md:justify-between sm:flex-wrap">
                  <div
                    className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
                  >
                    <h4 className="text-lightGray text-xs">
                      <T uk = "К-сть акцій:" en = "Number of shares:">К-во акций:</T>
                    </h4>
                    <h3 className="font-semibold text-xl">{round(person.token_amount - person.token_sold, 2) ? round(person.token_amount - person.token_sold, 2) : "----"}</h3>
                  </div>
                </div>
                <div className="flex gap-12 md:justify-between sm:flex-wrap">
                  <div
                    className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
                  >
                    <h4 className="text-lightGray text-xs">
                      <T uk = "Ціна за акцію:" en = "Price per share:">Цена за акцию:</T>
                    </h4>
                    <h3 className="font-semibold text-xl">$ {round(person.listing_price, 2) ? round(person.listing_price, 2) : "----"}</h3>
                  </div>
                </div>
                <div className="flex gap-12 md:justify-between sm:flex-wrap">
                  <div
                    className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
                  >
                    <h4 className="text-lightGray text-xs">
                      <T uk = "Акцій продано, %:" en = "Shares sold, %:">Акций продано, %:</T>
                    </h4>
                    <h3 className="font-semibold text-xl">{round(100 / person.token_amount * person.token_sold, 2) ? round(100 / person.token_amount * person.token_sold, 2) : "----"}%</h3>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center gap-4">
              <button
                className="ButtonColor AlternativeButtonText btn btn-primary col-span-2 col-start-2 text-xs cursor-pointer"
                onClick={() =>{setIsEditing(true)}}
                >
                <T uk = "Редагувати" en = "Edit">Редактировать</T>
              </button>
              <Link
                to={`/stock`}
                className="ButtonColor AlternativeButtonText btn btn-primary col-span-2 col-start-2 text-xs cursor-pointer w-[200px]"
              >
                <T uk = "Мій баланс" en = "My balance">Мой баланс</T>
              </Link>
            </div>
          </div>
        </div>
        <section className="py-[40px] md:py-[40px] sm:py-[20px]">
        {person.video_message && isApproved && (
          <section className="py-[20px] md:py-[20px] sm:py-[20px]">
            <h2 className="font-semibold text-3xl mb-[50px]">
              <T uk = "Моє відеопредставлення" en = "My video representation">Моё видеопредставление</T>
            </h2>
            <video
              className="VideoInput_video col-span-4 max-w-[80vw] max-h-[80vh]"
              controls
              onMouseOver={event => {
                let isMouseOver = true;

                event.target.onmouseout = () => {
                  isMouseOver = false;
                  event.target.pause();
                  event.target.currentTime = 0;
                };

                if (event.target.readyState > 2) {
                  event.target.play();
                } else {
                  event.target.oncanplaythrough = () => {
                    if (isMouseOver) {
                      event.target.play();
                    }
                  };
                }
              }}
              muted='muted'
              src={`https://directus.${window.location.host}/assets/${person.video_message}`}
            />
          </section>
        )}
        {person.biography && <Biography content={person.biography}/>}
        {(isApproved && (person.instRSSContent.length != 0)) && <><h2 className="font-semibold text-3xl mt-[30px] mb-[60px]">
          <T uk = "Активність " en = "Activity ">Активность </T>
          {person.name || person.first_name + ' ' + person.last_name}:
        </h2>
        <Swiper
          breakpoints={{
            "@0.75": {
              slidesPerView: 2.3,
              spaceBetween: 15,
            },
            "@1.00": {
              slidesPerView: 4.2,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 4.2,
              spaceBetween: 16,
            },
          }}
          slidesPerView={1.3}
          spaceBetween={16}
          grabCursor={true}
          navigation={true}
          modules={[Navigation, Manipulation]}
          className="swiper-blog"
        >
          <div>
          {person.instRSSContent.map((item, index) =>  {
            const filetype = R.head(R.filter(x => x, R.values(R.mapObjIndexed((pattern, type) => R.test(pattern, item.image.filename_disk) && type, {'image': /jpg$|jpeg$|png$/, 'video': /avi$|mpeg$|mp4$/ }))))

            let element = {
            image: <img className="object-cover w-[231px] h-[380px]" src={`https://directus.${window.location.host}/assets/${item.image.filename_disk}`}/>,
            video:
              <video
                controls
                onMouseOver={event => {
                  let isMouseOver = true;

                  event.target.onmouseout = () => {
                    isMouseOver = false;
                    event.target.pause();
                    event.target.currentTime = 0;
                  };

                  if (event.target.readyState > 2) {
                    event.target.play();
                  } else {
                    event.target.oncanplaythrough = () => {
                      if (isMouseOver) {
                        event.target.play();
                      }
                    };
                  }
                }}
                muted='muted'
                loop
                className="object-cover w-[231px] h-[380px]"
              >
              <source src={`https://directus.${window.location.host}/assets/${item.image.filename_disk}`}/></video>
            }[filetype]

            return <SwiperSlide key={item.id}>
              <div className="flex flex-col">
              <MeatballsMenu/>
              {element}
               <div className="columns-1 w-[231px]">
                  <h2 className="text-left text-[14px] font-roboto opacity-80">
                  <a href={`${item.urlpost}`} target="_blank">{item.title}</a>
                  </h2>
                </div>
               </div>
             </SwiperSlide>
           })}
          </div>
        </Swiper></>}
        {(isApproved && (person.offers.length != 0)) &&(
            <Offers rer={rer} offers={person.offers.map( x => x.offer_id)} isUserpage={true} name={person.name}/>
        )}
        </section>
      </>
      }
    </div>
  );
};
export default User;
