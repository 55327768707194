import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { Helmet } from 'react-helmet';
import Image from "./assets/img/main.png";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy and sell personal shares of public persons.</title>
        <meta name="description" content="Discover the world of personal shares, and find out how Personal Shares converts people's talent and fame into valuable assets." />

        <meta property="og:title" content="Buy and sell personal shares of public persons." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://personalshares.com" />
        <meta property="og:image" content= {Image}  />
        <meta property="og:description" content="Discover the world of personal shares, and find out how Personal Shares converts people's talent and fame into valuable assets." />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@personalshares" />
        <meta name="twitter:title" content="Buy and sell personal shares of public persons." />
        <meta name="twitter:description" content="Discover the world of personal shares, and find out how Personal Shares converts people's talent and fame into valuable assets." />
        <meta name="twitter:image" content={Image} />
      </Helmet>
      <App />
    </BrowserRouter>
);
