import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {LanguageSwitcher} from '../Translation'


import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";

import LogoImg from "../assets/img/footer-logo.png";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: state.isSelected ? "var(--color)" : "#fffffff",
    backgroundColor: state.isSelected ? "transparent" : "transparent",
    borderRadius: "4px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "var(--color)",
    backgroundColor: "transparent",
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    color: "#ffffff",
    borderColor: "none",
    borderWidth: "0",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 0,
    backgroundColor: "rgba(49,49,49,0.25) !important",
    backdropFilter: "blur(4px) !important",
  }),
  input: (provided) => ({
    ...provided,
    color: "transparent",
    boxShadow: "none",
  }),
};

const Footer = () => {
  const socials = [
    { icon: <FaFacebookF />, url: "/" },
    { icon: <FaTwitter />, url: "/" },
    { icon: <FaLinkedin />, url: "/" },
    { icon: <FaYoutube />, url: "/" },
    { icon: <FaInstagram />, url: "/" },
  ];
  function topFunction() {
    document.body.scrollTop = 0; // Для Safari
    document.documentElement.scrollTop = 0; // Для Chrome, Firefox, IE и Opera
  }
  return (
    <footer className="Background10 pt-[60px] pb-[35px] bg-black sm:py-[30px]">
      <div className="container">
        <div className="flex flex-col gap-[87px] md:gap-[40px]">
          <div className="flex justify-between md:flex-col sm:flex-wrap">
            <div className="flex gap-[110px] md:gap-[70px] sm:flex-wrap sm:gap-7">
              <div className="flex flex-col gap-[40px] sm:w-full">
                <Link to= "/" onClick={topFunction}>
                <img
                  className="Logo w-[140px] h-[69px] sm:w-[150px] sm:mx-auto sm:h-auto"
                  src={LogoImg}
                  alt=""
                />
                </Link>
                <div className="mb-[45px] mt-[-20px] md:mb-[80px] sm:w-[122px] w-[90%] sm:mx-auto"><LanguageSwitcher customStyles = {customStyles}/></div>
              </div>
              <ul className="flex flex-col gap-5 text-sm font-medium mt-5 mx-auto">
                <Link className="MainText duration-300 hover:text-primary" to="/about" onClick={topFunction}>
                  <T uk = "Про платформу" en = "About the platform">О платформе</T>
                </Link>
                <Link className="MainText duration-300 hover:text-primary" to="/personalities" onClick={topFunction}>
                  <T uk = "Персони" en = "Persons">Личности</T>
                </Link>
                <Link className="MainText duration-300 hover:text-primary" to="/market" onClick={topFunction}>
                  <T uk = "Рейтинг" en = "Rating">Рейтинг</T>
                </Link>
              </ul>
              <ul className="flex flex-col gap-5 text-sm font-medium mt-5 mx-auto">
                <Link className="MainText duration-300 hover:text-primary" to="https://blog.personalshares.com/tag/baza-znanii/">

                  <T uk = "Як користуватись" en = "How to use">Как пользоваться</T>
                </Link>
                <Link className="MainText duration-300 hover:text-primary" to="https://blog.personalshares.com/tag/news/">
                  <T uk = "Новини" en = "News">Новости</T>
                </Link>
              </ul>
            </div>
            <div className="flex gap-[52px] mt-5 md:mt-12 sm:mx-auto">
              {socials.map((social, index) => (
                <Link
                  className="MainText text-xl duration-300 hover:text-primary"
                  to={social.url}
                  key={index}
                >
                  {social.icon}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center sm:flex-col sm:gap-5 sm:mx-auto">
            <div className="flex items-center md:gap-5">
              <p className="MainText text-xs text-white/50 mr-[90px] md:mr-[0px]">
                2022 Personal Shares Copyright
              </p>
             {/* <Link className="text-xs text-white/50 mr-[40px] sm:mr-0" to="/">*/}
              <div className="MainText text-xs text-white/50 mr-[40px] md:mr-[0px] sm:mr-15"><T  uk = "Політика конфіденційності" en = "Privacy policy">Политика конфиденциальности</T></div>
              {/*</Link>*/}
              {/*<Link className="text-xs text-white/50" to="/">*/}
              <div className="MainText text-xs text-white/50"><T uk = "Умови використання сайту" en = "Terms of use">Условия использования сайта</T></div>
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
