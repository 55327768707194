import React from "react";
import {useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import LazyLoad from 'react-lazy-load';
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import * as R from 'ramda'

import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/swiper.css";
import "../assets/css/swiper-personalitiess.css";

import LineChart from '../components/LineChart';
import Timer from '../components/Timer';

const Pers = (item, index) => {


  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "August, 30, 2023, 20:00:00 GMT";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);


  const [person, setPerson] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [num, setNum] = useState(0);

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect( () => {
    const FetchData = async() => {
      const response = ((await directus.items('Instrss').readByQuery({filter: {iduser: {'_eq': item.id}}, fields: ['image.filename_disk, title, urlpost'] })).data).reverse();
      response.act = response.map(item => `https://directus.${window.location.host}/assets/${item.image.filename_disk}`);
      response.act2 = response.map(title => `${title.title}`);
      response.act3 = response.map(urlpost => `${urlpost.urlpost}`);
      setPerson(response);
      setIsLoaded(x => true);

    }
    FetchData();
    setNum(randomNumberInRange(300, 898));

  }, [])

  function getStockData(personName) {
    const data = JSON.parse(localStorage.getItem('stockData'));
    return data[personName];
  }

  if( !isLoaded ) {
    return (<></>)
  };

  return (
          <div className="Background3 flex md:flex-col mb-[40px] bg-[#313132] h-auto md:w-full">
           {item.is_ipo ?  <div className="w-[400px] md:mx-auto mobileL:w-[300px] ml-[20px]">
              <h2 className="text-[20px] font-semibold  mt-[20px] text-center">
                <T uk = "Передпродаж" en = "Pre-sale">Предпродажа</T>
              </h2>
               <Link to={`/bloggerbuy/${item.id}`}>
                    <img
                        className="h-[110px] w-[110px] bg-cover bg-center mt-[20px] mx-auto"
                        src={item.img}
                        alt=""
                    />
               </Link>
               <Link to={`/bloggerbuy/${item.id}`}>
                  <h2 className="text-[28px] font-semibold mt-[25px] text-center">
                    <T>{item.title}</T>
                  </h2>
               </Link>
              <h2 className="text-[14px] font-roboto mt-[10px] text-center opacity-50">
                <T>{item.subtitle}</T>
              </h2>
              <div className="columns-2">
                <h2 className="text-[15px] font-roboto text-center">
                  <T uk = "Назва:" en = "Name:">Название:</T>
                </h2>
                <h2 className="text-[16px] font-semibold text-center">
                  <T>{item.title}</T>Token
                </h2>
                <h2 className="text-[15px] font-roboto text-center">
                  <T uk = "Продано:" en = "Sold:">Продано:</T>
                </h2>
                <h2 className="text-[16px] font-semibold text-center">
                  {item.token_sold}/{item.token_amount}
                </h2>
              </div>

              <div className="flex">
              <h2 className="text-[15px] font-roboto mt-[15px] text-center">
                <T uk = "Ціна після лістингу::" en = "Price after listing:">Цена после листинга:</T>
              </h2>
              <h2 className="text-[20px] sm:text-[17px] font-semibold ml-[20px] mt-[15px] text-[#32CD32]">
                {item.listing_price} $
              </h2>
              </div>
              <div className="flex items-start">
                <h2 className="text-[15px] font-roboto mt-[25px] text-center">
                  <T uk = "Ціна зараз:" en = "Price now:">Цена сейчас:</T>
                </h2>
                <h2 className="line-through text-[20px] sm:text-[17px] font-semibold ml-[15px] sm:ml-[10px] mt-[25px] text-[#DC143C]">
                  {item.listing_price} $
                </h2>
                <h2 className="text-[20px] sm:text-[18px] font-semibold ml-[5px] mt-[25px] text-[#32CD32]">
                  {item.purchase_price} $
                </h2>
                <span className="ButtonText ml-[5px] bg-danger rounded-md font-bold text-[16px] xs:text-[10px]">
                  -{Math.round(100-(item.purchase_price*100)/item.listing_price, 2)}%
                </span>
              </div>

              <h2 className="text-[15px] font-roboto  mt-[20px] text-center">
                <T uk = "Стадія розміщення починається через:" en = "The placement stage starts after:">Стадия размещения начинается через:</T>
              </h2>
              <Timer person={item}/>

              <HashLink
                to={`/bloggerbuy/${item.id}/#converter`}
                className="ButtonColor AlternativeButtonText btn bg-primary w-[160px] text-xs mt-[35px] mx-auto mb-[10px]"
              >
                {" "}
                <T uk = "КУПИТИ" en = "BUY">КУПИТЬ</T>
              </HashLink>
            </div>
            :
            <div className="w-[350px] md:mx-auto mobileL:w-[300px] ml-[20px]">
               <Link to={`/bloggerbuy/${item.id}`}>
                    <img
                        className="h-[110px] w-[110px] bg-cover bg-center mt-[25px] mx-auto"
                        src={item.img}
                        alt=""
                    />
               </Link>
               <Link to={`/bloggerbuy/${item.id}`}>
                  <h2 className="text-[28px] font-semibold mt-[25px] text-center">
                    <T>{item.title}</T>
                  </h2>
               </Link>
              <h2 className="text-[14px] font-roboto mt-[5px] text-center opacity-50">
                <T>{item.subtitle}</T>
              </h2>
              <div className= "max-w-[320px] mr-auto ml-[10px]">
                <LineChart width = {270} height = {100} lineColor = {"#0ec40e"} altLineColor = {"#E32636"} chartData = {getStockData(item.title)}/>
              </div>
              <div className="flex justify-between">
                <div className="ml-[10px]">
                  <h2 className="text-[12px] font-roboto text-center opacity-50">
                    <T uk = "Акцій на продаж:" en = "Shares for sale:">Акций на продажу:</T>
                  </h2>
                  <h2 className="text-[16px] font-semibold text-center">
                    {round(item.token_amount - item.token_sold, 2)}
                  </h2>
                </div>
                <div className="mr-[10px]">
                  <h2 className="text-[12px] font-roboto text-center opacity-50">
                  <T uk = "Ціна за акцію:" en = "Price per share:">Цена за акцию:</T>
                  </h2>
                  <h2 className="text-[16px] font-semibold text-center">
                    $ {item.listing_price}
                  </h2>
                </div>
              </div>
              <Link
                to={`/bloggerbuy/${item.id}`}
                className="ButtonColor AlternativeButtonText btn bg-primary w-[160px] text-xs mt-[25px] mx-auto mb-[25px]"
              >
                {" "}
                <T uk = "ДЕТАЛЬНІШЕ" en = "READ MORE">ДЕТАЛЬНЕЕ</T>
              </Link>
            </div>}

            {item.is_ipo ? <div className="lg:w-[370px] w-full md:hidden my-auto">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  }
                }}
                slidesPerView={1}
                grabCursor={true}
                navigation={true}
                modules={[Navigation]}
                className="swiper-perss max-w-4xl"
              >
                {person.act.map((itemm, index) => {
                  const filetype = R.head(R.filter(x => x, R.values(R.mapObjIndexed((pattern, type) => R.test(pattern, itemm) && type, {'image': /jpg$|jpeg$|png$/, 'video': /avi$|mpeg$|mp4$/ }))))

                  let element = {
                  image: <LazyLoad offset={300}><img className="object-cover w-[231px] h-[380px]" src={itemm}/></LazyLoad>,
                      video: <LazyLoad offset={300}>
                        <video
                          onMouseOver={event => {
                            let isMouseOver = true;

                            event.target.onmouseout = () => {
                              isMouseOver = false;
                              event.target.pause();
                              event.target.currentTime = 0;
                            };

                            if (event.target.readyState > 2) {
                              event.target.play();
                            } else {
                              event.target.oncanplaythrough = () => {
                                if (isMouseOver) {
                                  event.target.play();
                                }
                              };
                            }
                          }}
                          loop controls muted='muted'
                          className="object-cover w-[231px] h-[380px]"
                        >
                        <source src={itemm}/></video></LazyLoad>
                  }[filetype]

                  return <SwiperSlide key={`${itemm.title}_${index}`}>
                    <div className="flex flex-col">
                    <a href={`${person.act3[index]}`} target="_blank">
                        {element}
                        </a>
                      <div className="columns-1 w-[231px]">
                        <h2 className="text-left text-[14px] font-roboto opacity-80">
                        {person.act2[index]}
                        </h2>
                      </div>
                    </div>
                  </SwiperSlide>
                })}
              </Swiper>
            </div>
            :
            <div className="lg:w-[370px] w-full md:hidden mt-[15px]">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  }
                }}
                slidesPerView={1}
                grabCursor={true}
                navigation={true}
                modules={[Navigation]}
                className="swiper-perss max-w-4xl"
              >
                {person.act.map((itemm, index) => {
                  const filetype = R.head(R.filter(x => x, R.values(R.mapObjIndexed((pattern, type) => R.test(pattern, itemm) && type, {'image': /jpg$|jpeg$|png$/, 'video': /avi$|mpeg$|mp4$/ }))))

                  let element = {
                  image: <LazyLoad offset={300}><img className="object-cover w-[231px] h-[380px]" src={itemm}/></LazyLoad>,
                      video: <LazyLoad offset={300}>
                        <video
                          onMouseOver={event => {
                            let isMouseOver = true;

                            event.target.onmouseout = () => {
                              isMouseOver = false;
                              event.target.pause();
                              event.target.currentTime = 0;
                            };

                            if (event.target.readyState > 2) {
                              event.target.play();
                            } else {
                              event.target.oncanplaythrough = () => {
                                if (isMouseOver) {
                                  event.target.play();
                                }
                              };
                            }
                          }}
                          loop controls muted='muted'
                          className="object-cover w-[231px] h-[380px]"
                        >
                        <source src={itemm}/></video></LazyLoad>
                  }[filetype]

                  return <SwiperSlide key={`${itemm.title}_${index}`}>
                    <div className="flex flex-col">
                    <a href={`${person.act3[index]}`} target="_blank">
                        {element}
                        </a>
                      <div className="columns-1 w-[231px]">
                        <h2 className="text-left text-[14px] font-roboto opacity-80">
                        {person.act2[index]}
                        </h2>
                      </div>
                    </div>
                  </SwiperSlide>
                })}
              </Swiper>
            </div>
            }

          </div>
  );
};
export default Pers;
