import React, { useRef , useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { LanguageSwitcher } from '../Translation'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Registration from "../pages/Registration";
import Singin from "../pages/Singin";
import Select from "react-select";




const ThemeSelect = (props) => {

  const getTheme = theme => {
    if(theme == "Dark"){
      return "theme-dark"
    }
    if(theme == "Light") {
      return "theme-light"
    } if(theme == "Blue") {
      return "theme-blue"
    } if(theme == "Pink") {
      return "theme-pink"
    } if(theme == "Green") {
      return "theme-green"
    }if(theme == "Red") {
      return "theme-red"
    }if(theme == "Orange") {
      return "theme-orange"
    }if(theme == "Purple") {
      return "theme-purple"
    }if(theme == "Yellow") {
      return "theme-yellow"
    }if(theme == "Pink") {
      return "theme-pink"
    }
  }

    const [theme, setTheme] = useState(()=>{
        if(!window.localStorage.getItem('Current_theme')){
          window.localStorage.setItem('Current_theme',JSON.stringify({ label: "Dark", value: 'dark' }))
        }
        window.localStorage.setItem('preventThemeChange', false)
        const savedTheme = JSON.parse(window.localStorage.getItem('Current_theme'));
        document.querySelector('body').classList.add(getTheme(savedTheme.label));
        return savedTheme;
    });

    const [isSearchable] = useState(false);

      const themes = ["theme-dark","theme-light","theme-blue","theme-green","theme-red","theme-orange","theme-purple","theme-yellow","theme-pink"];


      useEffect( () => {
        const savedTheme = window.localStorage.getItem('Current_theme');
        setTheme(JSON.parse(savedTheme));
      },[]
      )

      useEffect( () => {
        window.localStorage.setItem('Current_theme',JSON.stringify(theme))
      },[theme]
      )

      const options = [
        { label: "Dark", value: 'dark' },
        { label: "Light", value: 'light' },
        { label: "Blue", value: 'blue' },
        { label: "Pink", value: 'pink' },
        { label: "Green", value: 'green' },
        { label: "Orange", value: 'orange' },
        { label: "Red", value: 'red' },
        { label: "Purple", value: 'purple' },
        { label: "Yellow", value: 'yellow' },
      ];

      const handleThemeChange = (e) => {
        let d = window.localStorage.getItem('preventThemeChange') ? JSON.parse(window.localStorage.getItem('preventThemeChange')) : false
        if (!d) document.querySelector('body').classList.remove(...themes);
        setTheme(e);
        if (!d) document.querySelector('body').classList.add(getTheme(e.label));
      }

      return(
        <Select
              className="textcolor"
              onChange={handleThemeChange}
              value={theme}
              styles={props.customStyles}
              options={options}
              isSearchable={isSearchable}
              closeMenuOnScroll={e => {
                if (e.target === document) {
                  return true
                } else {
                  return false
                }
              }}
            />
      )
}
export default ThemeSelect;
