import React from "react";

import {useState, useRef, useMemo} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import searchimg from "../assets/img/search.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from '../components/LineChart';
import UserStockList from '../components/UserStockList';
import Pay from '../components/Pay';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from "react-select";
import { CiSearch } from "react-icons/ci"
import { FaSort, FaSortUp, FaSortDown} from "react-icons/fa"
import {useCollapse} from 'react-collapsed';
import useLocalStorageState from 'use-local-storage-state';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from "react-chartjs-2";

import dorofeeva from "../assets/img/dorofeeva.png";
import monatik from "../assets/img/monatik.png";
import barskih from "../assets/img/barskih.png";
import alyona from "../assets/img/alyona.png";
import usik from "../assets/img/usik.png";
import { MdMoney } from "react-icons/md";

const UserStock = () => {
    const ref = useRef();

    if (localStorage.getItem('userData') === null) {

        const userData = {
            balance: 4000,
            shares: {
                DRFVA: 0,
                MNTK: 2,
                BRSKH: 1,
                ALNA: 3,
                USYK: 0
            }
        };
        localStorage.setItem('userData', JSON.stringify(userData));
    }
    const retrievedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(retrievedData);

    function generateData() {
        let data = [];

        let personalities = [
            {name: "DRFVA", title: "DOROFEEVA", image: dorofeeva, quantity: parsedData.shares.DRFVA, cost: 176, is_ipo: true},
            {name: "MNTK", title: "Monatik", image: monatik, quantity: parsedData.shares.MNTK, cost: 133, is_ipo: false},
            {name: "BRSKH", title: "Макс Барских", image: barskih, quantity: parsedData.shares.BRSKH, cost: 111, is_ipo: false},
            {name: "ALNA", title: "Alyona Alyona", image: alyona, quantity: parsedData.shares.ALNA, cost: 169, is_ipo: false},
            {name: "USYK", title: "Александр Усик", image: usik, quantity: parsedData.shares.USYK, cost: 198, is_ipo: false},
        ];

        for(let i = 1; i <= 5; i++) {
            let cost = 300 + Math.random() * 700;
            let quantity = Math.floor(Math.random() * 30) + 1;

            data.push({
                id: i,
                title: personalities[i-1].title,
                image: personalities[i-1].image,
                tokenName: personalities[i-1].name,
                cost: personalities[i-1].cost,
                quantity: personalities[i-1].quantity,
                is_ipo: personalities[i-1].is_ipo,
            });
        }

        return data;
    }
      const originalData = generateData();

    const calculateTotalValue = sharesData => {
        let totalValue = 0;

        for (let share of sharesData) {
            totalValue += share.cost * share.quantity;
        }

        return totalValue;
    }


    const [totalValue] = useState(calculateTotalValue(originalData));
    const [sharesData, setSharesData] = useState([...originalData]);
    const [originalSharesData] = useState([...originalData]);
    const [quantitySortState, setQuantitySortState] = useState('natural');
    const [costSortState, setCostSortState] = useState('natural');
    const [valueSortState, setValueSortState] = useState('natural');
    const [nameSortState, setNameSortState] = useState('natural');
    const [is30d, setIs30d] = useState(true);

    const sortByQuantity = () => {
        setCostSortState('natural');
        setValueSortState('natural');
        setNameSortState('natural');
        let sortedData;
        switch(quantitySortState) {
            case 'natural':
                sortedData = [...sharesData].sort((a, b) => a.quantity - b.quantity);
                setQuantitySortState('asc');
                break;
            case 'asc':
                sortedData = [...sharesData].sort((a, b) => b.quantity - a.quantity);
                setQuantitySortState('desc');
                break;
            case 'desc':
                sortedData = [...originalSharesData];
                setQuantitySortState('natural');
                break;
            default:
                break;
        }
        setSharesData(sortedData);
    }

    const sortByCost = () => {
        setQuantitySortState('natural');
        setValueSortState('natural');
        setNameSortState('natural');
        let sortedData;
        switch(costSortState) {
            case 'natural':
                sortedData = [...sharesData].sort((a, b) => a.cost - b.cost);
                setCostSortState('asc');
                break;
            case 'asc':
                sortedData = [...sharesData].sort((a, b) => b.cost - a.cost);
                setCostSortState('desc');
                break;
            case 'desc':
                sortedData = [...originalSharesData];
                setCostSortState('natural');
                break;
            default:
                break;
        }
        setSharesData(sortedData);
    }

    const sortByValue = () => {
        setQuantitySortState('natural');
        setCostSortState('natural');
        setNameSortState('natural');
        let sortedData;
        switch(valueSortState) {
            case 'natural':
                sortedData = [...sharesData].sort((a, b) => (a.cost * a.quantity) - (b.cost * b.quantity));
                setValueSortState('asc');
                break;
            case 'asc':
                sortedData = [...sharesData].sort((a, b) => (b.cost * b.quantity) - (a.cost * a.quantity));
                setValueSortState('desc');
                break;
            case 'desc':
                sortedData = [...originalSharesData];
                setValueSortState('natural');
                break;
            default:
                break;
        }
        setSharesData(sortedData);
    }

    const sortByName = () => {
        setQuantitySortState('natural');
        setCostSortState('natural');
        setValueSortState('natural');
        let sortedData;
        switch(nameSortState) {
            case 'natural':
                sortedData = [...sharesData].sort((a, b) => a.tokenName.localeCompare(b.tokenName));
                setNameSortState('asc');
                break;
            case 'asc':
                sortedData = [...sharesData].sort((a, b) => b.tokenName.localeCompare(a.tokenName));
                setNameSortState('desc');
                break;
            case 'desc':
                sortedData = [...originalSharesData];
                setNameSortState('natural');
                break;
            default:
                break;
        }
        setSharesData(sortedData);
    }
    const [textQuery, setTextQuery] = useState('');


    function getFilteredByTextList() {

        if (textQuery.length < 1){
            return sharesData;
        }

        return sharesData.filter(share => share.tokenName.toLowerCase().includes(textQuery.toLowerCase()));

    };

    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    function saveInput(){
        console.log('Saving data');
    }

    const processChange = debounce(() => saveInput());

    function handleTextChange(event){
        setTextQuery(event.target.value);
    }

    const filteredList = getFilteredByTextList().filter(item => item.quantity > 0);



    Chart.register(ChartDataLabels);

    function generateTestData() {
        let data = [
            { date: '01-06-23', balance: 1178.42 },
            { date: '02-06-23', balance: 981.97 },
            { date: '03-06-23', balance: 658.46 },
            { date: '04-06-23', balance: 883.01 },
            { date: '05-06-23', balance: 1200 },
            { date: '06-06-23', balance: 1178.66 },
            { date: '07-06-23', balance: 1156.21 },
            { date: '08-06-23', balance: 959.06 },
            { date: '09-06-23', balance: 747.4 },
            { date: '10-06-23', balance: 667.55 },
            { date: '11-06-23', balance: 848.1 },
            { date: '12-06-23', balance: 934.8 },
            { date: '13-06-23', balance: 1149.76 },
            { date: '14-06-23', balance: 1234.75 },
            { date: '15-06-23', balance: 1344.46 },
            { date: '16-06-23', balance: 1141.08 },
            { date: '17-06-23', balance: 1036.84 },
            { date: '18-06-23', balance: 912.56 },
            { date: '19-06-23', balance: 890.37 },
            { date: '20-06-23', balance: 778.47 },
            { date: '21-06-23', balance: 566.62 },
            { date: '22-06-23', balance: 853.46 },
            { date: '23-06-23', balance: 936.1 },
            { date: '24-06-23', balance: 641.18 },
            { date: '25-06-23', balance: 720.78 },
            { date: '26-06-23', balance: 917.74 },
            { date: '27-06-23', balance: 729.44 },
            { date: '28-06-23', balance: 625.16 },
            { date: '29-06-23', balance: 432.64 },
            { date: '30-06-23', balance: 1000 }
          ];
        // let today = new Date();
        // let balance = totalValue;

        // for(let i = 0; i < 30; i++) {
        //     let date = new Date();
        //     date.setDate(today.getDate() - i);
        //     let year = date.getFullYear().toString().slice(-2);
        //     let month = (date.getMonth() + 1).toString().padStart(2, '0');
        //     let day = date.getDate().toString().padStart(2, '0');
        //     let dateString = `${day}-${month}-${year}`;

        //     let randomAdjustment = balance * ((Math.random() - 0.5) * 0.05);
        //     balance = balance - randomAdjustment;

        //     // Make sure balance stays within the desired range
        //     if(balance < totalValue * 0.8) {
        //         balance = totalValue * 0.8;
        //     } else if(balance > totalValue * 1.2) {
        //         balance = totalValue * 1.2;
        //     }

        //     data.unshift({date: dateString, balance: parseFloat(balance.toFixed(2))});
        // }

        data[data.length - 1].balance = totalValue;

        return data;
    }

    function getLastSevenDaysData(testData) {
        let lastSevenDaysData = testData.slice(-7);
        return lastSevenDaysData;
    }

    const chartData30d  = generateTestData();

    const chartData7d  = getLastSevenDaysData(chartData30d);

    const changeData = () => {
        setIs30d(!is30d);
    }

    let number = Math.round(chartData30d[chartData30d.length-1].balance) - Math.round(chartData30d[0].balance);
    let width, height, gradient;

    const [userData, setUserData] = useState({
        labels: chartData30d.map((data) => data.date),
        datasets: [
        {
            label: "",
            data: chartData30d.map(data => Math.round(data.balance)),
            backgroundColor: "transparent",
            cubicInterpolationMode: 'monotone',
            pointStyle: 'circle',
            borderColor: () => {
                if (number > 0) {
                return "#32CD32";
            } else if (number == 0){
                return "#32CD32";
            } else {
                return "#DC143C";
            }},
            pointBackgroundColor: () => {
                return "white";
            },
            pointBorderColor: () => {
                return "white";
            },
            pointHoverBackgroundColor:() => {
                return "white";},
            pointHoverBorderColor: () => {
                return "white";},
            borderWidth: 3,
            pointHitRadius: 6,
            pointRadius: () => {
                let arr = Array(chartData30d.length).fill(1);
                arr[arr.length - 1] = 3;
                return arr;
              },
            pointHoverRadius: 6,
            fill: 'start',
            datalabels: {
            align: 'right',
            }
        },
        ],
    });

    const [userData2, setUserData2] = useState({
        labels: chartData7d.map((data) => data.date),
        datasets: [
        {
            label: "",
            data: chartData7d.map(data => Math.round(data.balance)),
            backgroundColor: "transparent",
            cubicInterpolationMode: 'monotone',
            pointStyle: 'circle',
            borderColor: () => {
                if (number > 0) {
                return "#32CD32";
            } else if (number == 0){
                return "#32CD32";
            } else {
                return "#DC143C";
            }},
            pointBackgroundColor: () => {
                return "white";
            },
            pointBorderColor: () => {
                return "white";
            },
            pointHoverBackgroundColor:() => {
                return "white";},
            pointHoverBorderColor: () => {
                return "white";},
            borderWidth: 3,
            pointHitRadius: 6,
            pointRadius: () => {
                let arr = Array(chartData7d.length).fill(1);
                arr[arr.length - 1] = 3;
                return arr;
              },
            pointHoverRadius: 6,
            fill: 'start',
            datalabels: {
            align: 'right',
            }
        },
        ],
    });

    const [options, setOptions] = useState({
        layout: {
    },
        plugins: {
            tooltip: {
                    mode: 'nearest',
                    intersect: false,
                    usePointStyle: true
            },
        datalabels: false,
        legend: {
            display: false,
        },
    },
    interaction: {
        intersect: false,
        mode: 'nearest'
    },
        scales: {
        x: {
            display: true,
            title: {
            display: true,
            },
            grid: {
            display: true,
            },
            ticks: {
            maxRotation: 0,
        },
        },
        y: {
            position: 'left',
            title: {
            display: true,
            },
            display: true,
            grid: {
            display: true,
            }
        }
        }
    });

    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.right, 0, chartArea.left);
            gradient.addColorStop(0.66, "#131313");
            if (number > 0) {
                gradient.addColorStop(1.0, "rgb(102 197 123)");
            } else if (number == 0){
                gradient.addColorStop(1, "rgb(102 197 123)");
            } else {
                gradient.addColorStop(1, "rgb(236 98 98)");
            }
    }

    return gradient;
    }

    return (
        <>
        <div className="container flex mt-[50px] md:mt-[120px] header:mt-[120px]">
            <div className="flex w-full md:flex-col">
                <div className="flex flex-col w-[50%] md:w-[100%] my-auto mb-[140px] md:mx-auto md:mb-[50px]">
                    <label className="mb-[15px] text-3xl md:mx-auto md:text-center"><T uk = "Загальна вартість ваших акцій:" en = "The total value of your shares:">Общая стоимость ваших акций:</T></label>
                    <div className="md:text-center mb-[10px]"><span className="text-5xl">{Math.round(totalValue)}$</span></div>
                    <label className="mb-[15px] text-3xl md:mx-auto md:text-center"><T uk = "Ваш поточний баланс:" en = "Your current balance:">Ваш баланс:</T></label>
                    <div className="md:text-center"><span className="text-5xl">{Math.round(parsedData.balance)}$</span></div>
                    <Popup nested ref={ref} modal className="testbl" trigger={<button className="ButtonColor AlternativeButtonText btn bg-primary w-[160px] text-xl mt-[25px] md:mx-auto"><T uk = "Поповнити" en = "Replenish">Пополнить</T></button>} position="right center">
                    {close => (<Pay closePopUp={close}></Pay>)}
                    </Popup>
                </div>
                <div className="flex flex-col w-[50%] md:w-[100%]">
                    <label className="mb-[10px] text-2xl mx-auto md:text-center"><T uk = "Історія зміни вартості ваших акцій" en = "History of changes in the value of your shares">История изменения стоимости ваших акций</T></label>
                    <div className="flex justify-center gap-4 mb-[20px]">
                        {is30d ? <button className="px-2 py-1 relative" onClick={changeData}><T uk = "за 7 днів" en = "in 7 days">за 7 дней</T></button>
                        : <button className="ButtonColor AlternativeButtonText rounded-lg px-2 py-1 text-white outline-none relative"><T uk = "за 7 днів" en = "in 7 days">за 7 дней</T></button>
                        }
                        {is30d ? <button className="ButtonColor AlternativeButtonText rounded-lg px-2 py-1 text-white outline-none relative"><T uk = "за 30 днів" en = "in 30 days">за 30 дней</T></button>
                        : <button className="px-2 py-1 relative" onClick={changeData}><T uk = "за 30 днів" en = "in 30 days">за 30 дней</T></button>
                        }
                    </div>
                    {is30d ? <Line className="md:ml-[-20px]" id = {'chart'} data={userData} options = {options} redraw = {true}/>
                        : <Line className="md:ml-[-20px]" id = {'chart'} data={userData2} options = {options} redraw = {true}/>
                    }
                </div>
            </div>
        </div>
        <div className="flex justify-center container">
            <div className="w-full">
                <div className="mx-auto mb-[10px] md:pt-[10px]" >
                    <div className="flex md:flex-col md:flex-nowrap">
                        <div className="flex border InputBorder input-group placeholder:pl-[12px] font-roboto md:mb-[-10px] bg-transparent">
                        <Ru>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Поиск"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Ru>
                        <Uk>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Пошук"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Uk>
                        <En>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Search"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </En>
                            <div className="input-group-append w-[48px] h-[48px]">
                                <CiSearch className="rounded mx-auto my-[10px] w-[28px] h-[28px]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto mb-[60px]">
                    <div className="w-full mx-auto overflow-auto">
                        <table className="table-auto w-full text-left whitespace-no-wrap">
                            <thead className="lg:hidden">
                                <tr>
                                    <th className="pr-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl w-[220px]">
                                        <div className="flex"><div className="flex cursor-pointer" onClick={sortByName}><span><T uk = "Назва акції" en = "Name of the share">Название акции</T></span>
                                        { nameSortState === 'natural' ? <FaSort className="ml-[3px] mt-[3px] cursor-pointer"></FaSort> : nameSortState === 'asc' ?
                                        <FaSortUp className="ml-[3px] mt-[3px] cursor-pointer"></FaSortUp> : <FaSortDown className="ml-[3px] mt-[3px] cursor-pointer"></FaSortDown>}</div></div>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 w-[180px]">
                                        <div className="flex"><div className="flex cursor-pointer" onClick={sortByQuantity}><span><T uk = "Кількість" en = "Quantity">Количество</T></span>
                                        { quantitySortState === 'natural' ? <FaSort className="ml-[3px] mt-[3px] cursor-pointer"></FaSort> : quantitySortState === 'asc' ?
                                        <FaSortUp className="ml-[3px] mt-[3px] cursor-pointer"></FaSortUp> : <FaSortDown className="ml-[3px] mt-[3px] cursor-pointer"></FaSortDown>}</div></div>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 w-[220px]">
                                        <div className="flex"><div className="flex cursor-pointer" onClick={sortByCost}><span><T uk = "Вартість акції" en = "Share price">Стоимость акции</T></span>
                                        { costSortState === 'natural' ? <FaSort className="ml-[3px] mt-[3px] cursor-pointer"></FaSort> : costSortState === 'asc' ?
                                        <FaSortUp className="ml-[3px] mt-[3px] cursor-pointer"></FaSortUp> : <FaSortDown className="ml-[3px] mt-[3px] cursor-pointer"></FaSortDown>}</div></div>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                        <div className="flex"><div className="flex cursor-pointer" onClick={sortByValue}><span><T uk = "Загальна вартість" en = "Total price">Общая стоимость</T></span>
                                        { valueSortState === 'natural' ? <FaSort className="ml-[3px] mt-[3px] cursor-pointer"></FaSort> : valueSortState === 'asc' ?
                                        <FaSortUp className="ml-[3px] mt-[3px] cursor-pointer"></FaSortUp> : <FaSortDown className="ml-[3px] mt-[3px] cursor-pointer"></FaSortDown>}</div></div>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                        <div className="flex"><div className="flex"><span><T uk = "Динаміка за 24г" en = "Dynamics in 24h">Динамика за 24 часа</T></span>
                                        </div></div>
                                    </th>
                                    <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br pr-[72px]"></th>
                                </tr>
                            </thead>
                            {filteredList.map((item) => (
                                <UserStockList key={item.id} {...item} />
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
export default UserStock;
