import React from "react";

import {useState, useEffect, useMemo} from "react";
import SwiperPersonalities from "../components/SwiperPersonalities";
import Pers from "../components/Pers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import LineChart from '../components/LineChart';
import searchimg from "../assets/img/search.png";

import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/swiper.css";
import "../assets/css/swiper-personalitiess.css";
import { CiSearch } from "react-icons/ci"
import Select from "react-select";
import PeopleImg from "../assets/img/Vector.png";
import imgtop from "../assets/img/vectorph.png";
import up from "../assets/img/chart.png";
import {useStore} from '../Store';

const Personalities = () => {

  function defaultPersons(){
    const defaultPers = [];
    const fetchData = async () => {
      const response = (await directus.items('Person').readByQuery({
        limit: 10,
        fields: ['*', 'activity.directus_files_id', 'category.person_category_id.name'],
        filter: {
          is_approved: {
            '_eq': true,
          }
        }
      })).data;
      response.forEach( (x) => {

        let person = {};

        person.id = x.id;
        person.img = `https://directus.${window.location.host}/assets/${x.head_photo}`;
        person.title = x.name || x.first_name + ' ' + x.last_name;
        person.categories = x.category.map(item => (item.person_category_id.name));
        person.subtitle = x.category.map(item => (item.person_category_id.name)).join(', ');
        person.amount = x.share_quantity;
        person.priceone = x.share_price;
        person.percentimg = up;
        person.activity = x.activity.map(item => `https://directus.${window.location.host}/assets/${item.directus_files_id}`);
        person.biography = x.biography;
        person.cap = x.capitalisation;
        person.date = x.date_created;
        person.RSS = x.RSS;
        person.is_ipo = x.is_ipo
        person.listing_date = x.listing_date;
        person.purchase_price = x.purchase_price;
        person.listing_price = x.listing_price;
        person.token_sold = x.token_sold ;
        person.token_amount = x.token_amount;

        defaultPers.push(person);

      });

      setIsLoaded(x => true);


    }

    fetchData();

    return defaultPers;
  };



  const [persons, setPersons] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSearchable] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({ label: "Все категории", value: '' });
  const [selectedIsIpo, setSelectedIsIpo] = useState({ label: "Все персоны", value: '' });
  function getFilteredByCategoryList() {
    if (selectedCategory.value == "") {
    return persons;
    }

    return persons.filter((item) => item.categories.includes(selectedCategory.value));
  };

  function handleCategoryChange(event) {
      setSelectedCategory(event);
  }


  useEffect( () => {
    setPersons(defaultPersons());
  }, []);


  const ruCategoryOptions = [
    { label: "Все категории", value: '' },
    { label: "Певец", value: 'Певец' },
    { label: "Певица", value: 'Певица' },
    { label: "Исполнитель", value: 'Исполнитель' },
    { label: "Исполнительница", value: 'Исполнительница' },
    { label: "Боксёр", value: 'Боксёр' }
  ];

  const ukCategoryOptions = [
    { label: "Усі категорії", value: '' },
    { label: "Співак", value: 'Певец' },
    { label: "Співачка", value: 'Певица' },
    { label: "Виконавець", value: 'Исполнитель' },
    { label: "Виконавиця", value: 'Исполнительница' },
    { label: "Боксер", value: 'Боксёр' }
  ];

  const enCategoryOptions = [
    { label: "All categories", value: '' },
    { label: "Male singer", value: 'Певец' },
    { label: "Female singer", value: 'Певица' },
    { label: "Male performer", value: 'Исполнитель' },
    { label: "Female performer", value: 'Исполнительница' },
    { label: "Boxer", value: 'Боксёр' }
  ];


  const [categoryOptions, setСategoryOptions] = useState(ruCategoryOptions);


  const language = useStore(x => x.language.language)

  useEffect(() => {

    if (language == 'ru'){

      setСategoryOptions(ruCategoryOptions);

      for(const category of ruCategoryOptions){
        if (selectedCategory.value == category.value) {
          setSelectedCategory({ label: category.label, value: category.value })
        }
      }

    };

    if (language == 'uk'){

      setСategoryOptions(ukCategoryOptions);

      for(const category of ukCategoryOptions){
        if (selectedCategory.value == category.value) {
          setSelectedCategory({ label: category.label, value: category.value })
        }
      }
    };

    if (language == 'en'){

      setСategoryOptions(enCategoryOptions);

      for(const category of enCategoryOptions){
        if (selectedCategory.value == category.value) {
          setSelectedCategory({ label: category.label, value: category.value })
        }
      }

    };

  }, [language]);


  const filteredByCategoryList = useMemo(getFilteredByCategoryList, [selectedCategory, persons]);
  //const filteredList = getFilteredList();


  const ruIsIpoOptions = [
    { label: "Все персоны", value: '' },
    { label: "Акции выпущены", value: 'Акции выпущены' },
    { label: "Предпродажа", value: 'Передпокупка' },
  ];

  const ukIsIpoOptions = [
    { label: "Усі персони", value: '' },
    { label: "Акції випущені", value: 'Акции выпущены' },
    { label: "Передпродаж", value: 'Передпокупка' },
  ];

  const enIsIpoOptions = [
    { label: "All persons", value: '' },
    { label: "Shares issued", value: 'Акции выпущены' },
    { label: "Pre-sale", value: 'Передпокупка' },
  ];


 const [isIpoOptions, setIsIpoOptions] = useState(ruIsIpoOptions);

 useEffect(() => {

  if (language == 'ru'){

    setIsIpoOptions(ruIsIpoOptions);

    for(const category of ruIsIpoOptions){
      if (selectedIsIpo.value == category.value) {
        setSelectedIsIpo({ label: category.label, value: category.value })
      }
    }

  };

  if (language == 'uk'){

    setIsIpoOptions(ukIsIpoOptions);

    for(const category of ukIsIpoOptions){
      if (selectedIsIpo.value == category.value) {
        setSelectedIsIpo({ label: category.label, value: category.value })
      }
    }
  };

  if (language == 'en'){

    setIsIpoOptions(enIsIpoOptions);

    for(const category of enIsIpoOptions){
      if (selectedIsIpo.value == category.value) {
        setSelectedIsIpo({ label: category.label, value: category.value })
      }
    }

  };

}, [language]);


function getIsIpoList() {
    if (selectedIsIpo.value == "") {
    return filteredByCategoryList;
    }
    if (selectedIsIpo.value == 'Акции выпущены') {
      return filteredByCategoryList.filter((item) => !item.is_ipo);
    }
    return filteredByCategoryList.filter((item) => item.is_ipo);
  };

  function handleIsIpoChange(event) {
      setSelectedIsIpo(event);
  }

  const filteredByIsIpoList = getIsIpoList();

  const [textQuery, setTextQuery] = useState('');


  function getFilteredByTextList() {

      if (textQuery === "" || textQuery.length < 3){
          return filteredByIsIpoList;
      }

      return filteredByIsIpoList.filter(pearson => pearson.title.toLowerCase().includes(textQuery.toLowerCase()) ||
      pearson.biography.toLowerCase().includes(textQuery.toLowerCase()) ||
      pearson.subtitle.toLowerCase().includes(textQuery.toLowerCase()));

  };


  function handleTextChange(event){
      setTextQuery(event.target.value);
  }

  const filteredByTextList = getFilteredByTextList();

  function debounce(func, timeout = 300){
      let timer;
      return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
  }

  function saveInput(){
      console.log('Saving data');
  }

  const processChange = debounce(() => saveInput());

  const [sortQuery, setSortQuery] = useState({ label: "Сортировать", value: 'default' });

  const ruSortOptions = [
    { label: "Сортировать", value: 'default' },
    { label: "Имя (А-Я)", value: 'name' },
    { label: "Капитализация", value: 'capitalization' },
    { label: "Новые", value: 'date' }
  ];

  const ukSortOptions = [
    { label: "Сортувати", value: 'default' },
    { label: "Від (А-Я)", value: 'name' },
    { label: "Капіталізація", value: 'capitalization' },
    { label: "Нові", value: 'date' }
  ];

  const enSortOptions = [
    { label: "Sort", value: 'default' },
    { label: "Name (A-Z)", value: 'name' },
    { label: "Capitalization", value: 'capitalization' },
    { label: "New", value: 'date' }
  ];

  const [sortOptions, setSortOptions] = useState(ruSortOptions);

  useEffect(() => {

   if (language == 'ru'){

      setSortOptions(ruSortOptions);

     for(const option of ruSortOptions){
       if (sortQuery.value == option.value) {
          setSortQuery({ label: option.label, value: option.value })
       }
     }

   };

   if (language == 'uk'){

      setSortOptions(ukSortOptions);

     for(const option of ukSortOptions){
       if (sortQuery.value == option.value) {
          setSortQuery({ label: option.label, value: option.value })
       }
     }
   };

   if (language == 'en'){

      setSortOptions(enSortOptions);

     for(const option of enSortOptions){
       if (sortQuery.value == option.value) {
          setSortQuery({ label: option.label, value: option.value })
       }
     }

   };

 }, [language]);

  function handleSortChange(event){
      setSortQuery(event);
  }

  function getSortedList() {

      if(sortQuery.value === "name"){
          return filteredByTextList.sort((a, b) => (a.title > b.title) ? 1 : -1);
       }else if(sortQuery.value === "date"){
           return filteredByTextList.sort((a, b) => (a.date > b.date) ? 1 : -1);
      }else if(sortQuery.value === "capitalization"){
           return filteredByTextList.sort((a, b) => (a.cap > b.cap) ? 1 : -1);
      }else {
        return filteredByTextList.sort((a, b) => {
          if (a.is_ipo === b.is_ipo) {
              return b.id - a.id;
          }
          return a.is_ipo ? -1 : 1;
      });
      }

  };
const customStyles = {
        option: (provided, state) => ({
          ...provided,
          marginTop: "-5px",
          height: "30px",
          fontWeight: state.isSelected ? "900" : "normal",
          color: state.isSelected ? "var(--color)" : "#fffffff",
          backgroundColor: state.isSelected ? "transparent" : "transparent",
          borderRadius: "4px",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--color)",
          backgroundColor: "transparent",
        }),
        control: (base, state) => ({
          ...base,
          background: "transparent",
          color: "#ffffff",
          borderColor: "none",
          borderWidth: "0",
          boxShadow: state.isFocused ? null : null,
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 0,
          paddingTop: 0,
          backgroundColor: "rgba(49,49,49,0.25) !important",
          backdropFilter: "blur(4px) !important",
        }),
        input: (provided) => ({
          ...provided,
          color: "transparent",
          boxShadow: "none",
        }),
      };

  const sortedList = getSortedList();

  if( !isLoaded ) {
    return (<></>)
  };

  return (
    <section className="py-[40px] fadeIn md:mt-[70px] sm:mt-[64px]">
      <div className="flex mb-[50px]">
        <h2 className="text-[44px] font-semibold container"><T uk = "Персони" en = "Persons">Личности</T></h2>
      </div>
      <div className="container">
      <div className="mx-auto pt-[70px] md:pt-[10px] mb-[10px]" >
                    <div className="flex md:flex-col md:flex-nowrap">
                      <div className="flex border InputBorder input-group placeholder:pl-[12px] font-roboto md:mb-[10px] bg-transparent">
                        <Ru>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Поиск"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Ru>
                        <Uk>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Пошук"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Uk>
                        <En>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Search"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </En>
                            <div className="input-group-append w-[48px] h-[48px]">
                                <CiSearch className="rounded mx-auto my-[10px] w-[28px] h-[28px]" />
                            </div>
                        </div>
                        <div className="w-full justify-between flex md:grid md:grid-cols-2 md:gap-[0px] z-40">
                            <Select
                                className="my-auto border md:justify-self-start InputBorder md:border-none md:col-span-1 py-[6px] w-[200px] h-[50px] md:flex md:w-full md:w-[100%] ml-[20px] md:ml-[0px] md:mx-auto mr-[1px] font-roboto option:pl-[12px] text-center md:text-left"
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                                styles={customStyles}
                                options={categoryOptions}
                                isSearchable={isSearchable}
                            />
                            <Select
                                className="my-auto border md:justify-self-end InputBorder md:border-none md:col-span-1 py-[6px] w-[200px] h-[50px]  ml-[20px] md:ml-[0px] md:mr-[0px] mr-[1px] font-roboto option:pl-[12px] text-center md:text-right"
                                onChange={handleIsIpoChange}
                                value={selectedIsIpo}
                                styles={customStyles}
                                options={isIpoOptions}
                                isSearchable={isSearchable}
                            />
                            {/*<select className="w-[268px] md:w-[100px] md:text-[10px] h-[48px] bg-[#404040] ml-[1px] font-roboto option:pl-[12px]">
                            <option>Все страны</option>
                                <option>Все страны</option>
                            </select>*/}
                            <Select
                                className="md:mr-0 ml-auto md:justify-self-end md:col-span-2 h-[38px] w-[170px] md:w-[50%] text-right my-auto"
                                onChange={handleSortChange}
                                value={sortQuery}
                                styles={customStyles}
                                options={sortOptions}
                                isSearchable={isSearchable}
                            />
                        </div>
                    </div>
        </div>
      </div>
        <section>
          <div className="container">
            {sortedList.map((item) => (
              <Pers key={item.id} {...item}/>
            ))}
          </div>
          </section>
    </section>
  );
};
export default Personalities;
