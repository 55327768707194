import React from "react";
import {useState, useEffect, useRef} from "react";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Popup from 'reactjs-popup';
import Avatar from "react-avatar-edit";
import StockAvatar from "../assets/img/stock_avatar.png";
import { Link } from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';

function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

const schema = yup.object().shape({
    name: yup
    .string()
    .required("Обязательное поле"),
    offerText: yup
    .string()
    .required("Обязательное поле")
}, []);


const SpecialOfferEdit = (props) => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })

  const Toast2 = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: true,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert = () => {
    Toast.fire({
      icon: 'warning',
      title: "Вы не загрузили изображение"
    })
  }

  const submitAler2 = () => {
    Toast2.fire({
      icon: 'success',
      title: "Ваше специальное предложение успешно создано"
    })
  }

  const submitErrorAlert = (m) => {
    Toast.fire({
      icon: 'error',
      title: m,
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (x) => {
    if (imgCrop) {
      let avatarFormData = new FormData();
      avatarFormData.append('file', DataURIToBlob(imgCrop), imageFilename);

      await fetch(`https://${window.location.host}/api/files/${props.offer.image}`, {
        method: 'DELETE'
      })

      let x = await fetch(`https://${window.location.host}/api/files`, {
        method: 'POST',
        body: avatarFormData
      })

      x = (await x.json()).data;

      await fetch(`https://${window.location.host}/api/items/offer/${props.offer.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          image: x.id
          })
      })
    }

    await fetch(`https://${window.location.host}/api/items/offer/${props.offer.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        name: x.name,
        text: x.offerText,
        })
    })

    submitAler2();
    props.rer(); //Обновить отображаемые данные
    props.closePopUp();
  };


  const watchName = watch("name");
  const watchOfferText = watch("offerText");

  const [imgCrop, setImgCrop] = useState(false);
  const [imageFilename, setImageFilename] = useState([]);
  const [isCropped, setIsCropped] = useState(false);
  const [storeImage, setStoreImage] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);

  const onCrop = view => {
    setImgCrop(view);
  }

  const onFileLoad = file => {
    setImageFilename(file.name)
  }

  const saveImage = () => {
    if (imgCrop) {
      setIsCropped(true);
      setStoreImage([...storeImage, {imgCrop}]);
    }
  }

  const specialOfferImageShow = storeImage.map( item => item.imgCrop );

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const [uploaded, setUploaded] = useState(false);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path.length >20 ? "..." + file.path.substring(file.path.length - 20) : file.path.substring(file.path.length - 20)}
    </li>
  ));
  //const files = acceptedFiles.length;

  if( !isLoaded ) {
    return (<></>)
  };

  return (
    <section className="overflow-hidden relative sm:-[20px]">
      <div className="container">
        <h2 className="font-semibold text-[33px] text-center sm:text-3xl mb-[30px] md:sm:text-xl md:mb-[20px]" onClick={() => props.closePopUp()}>
          <T uk = "Змінити спеціальну пропозицію" en = "Edit a special offer">Изменить специальное предложение</T>
        </h2>
        <form
          className="bg-darkGray px-[40px] py-[30px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-8 sm:md:pt-5 md:gap-[20px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <MdOutlineClose className="absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl md:right-[10px]" onClick={() => props.closePopUp()} />
            <div>
            <Popup
                trigger={ <div className="flex min-w-[50px] min-h-[50px] mx-auto md:px-3 md:py-3 md:mx-auto md:mb-0 md:mt-0">
                <img
                    className="w-[150px] h-[150px] rounded-full mx-auto md:w-[150px] md:h-[150px] md:mx-auto md:mb-0 md:mt-0"
                    src={specialOfferImageShow.length ? specialOfferImageShow[specialOfferImageShow.length - 1] : `https://directus.${window.location.host}/assets/${props.offer.image}`}
                    alt=""
                />
                </div>}
                modal
                nested
                className="testbl">

              {close => (
                  <div className="modal">
                      <div className="content">
                      <div className="confirmation-content flex flex-col items-center">
                        <div className="flex flex-col items-center mt-5 w-12">
                          <div className="flex flex-col justify-around w-12 mt-4 items-center">
                            <MdOutlineClose className="ButtonText absolute cursor-pointer right-[-170px] top-1 text-3xl sm:text-2xl md:right-[10px]" onClick={() => close()} />
                            <Avatar width={400} height={300} onCrop={onCrop} onFileLoad={onFileLoad} labelStyle={{color:"var(--alt-text-color)",fontSize:"25px"}}/>
                            <button onClick={() => { saveImage(); close();}} className="ButtonText btn btn-primary col-span-1 mt-2 w-[90px] text-xs cursor-pointer"><T uk = "Обрізати зображення" en = "Crop image">Обрезать изображение</T></button>
                          </div>
                        </div>
                      </div>
                      </div>
                  </div>
              )}
            </Popup>
            </div>
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="name"
          >
            <T uk = "Заголовок Спецпропозиції" en = "Special Offer Title">Заголовок спецпредложения</T>
            <input
              className={`InputField
              ${errors?.name ? "border-[#D81F26]" : "border-gray"}
              ${
                watchName && !errors?.name ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="name"
              id="name"
              name="name"
              defaultValue={props.offer.name}
              autoComplete="off"
              {...register("name")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.name?.message}
            </p>
          </label>
          <label
            className="flex flex-col col-span-4 row-span-2 gap-1 text-xs text-lightGray relative"
            htmlFor="offerText"
            >
            <T uk = "Текст спецпропозиції" en = "Text of the special offer">Текст спецпредложения</T>
            <textarea
              className={`InputField
              ${errors?.offerText ? "border-[#D81F26]" : "border-gray"}
              ${
                watchOfferText && !errors?.offerText ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="offerText"
              id="offerText"
              name="offerText"
              defaultValue={props.offer.text}
              autoComplete="off"
              {...register("offerText")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.offerText?.message}
            </p>
            </label>

            <button
            className="ButtonText btn btn-primary col-span-2 text-xs cursor-pointer"
            type="submit"
            >
            <T uk = "Зберегти" en = "Save">Сохранить</T>
            </button>
        </form>
      </div>
    </section>
  );
};

export default SpecialOfferEdit;
