import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


import { useLocation } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import aboutmain from "../assets/img/aboutmain.png";
import aboutimg1 from "../assets/img/aboutimg1.png";
import aboutimg2 from "../assets/img/aboutimg2.png";
import aboutimg3 from "../assets/img/aboutimg3.png";
import Cart1Img from "../assets/img/about/cart1.png";
import Cart2Img from "../assets/img/about/cart2.png";
import Cart3Img from "../assets/img/about/cart3.png";
import MonitImg from "../assets/img/about/monit.png";
import LineImg from "../assets/img/about/line.png";
import Request from "../components/Request";

const About = () => {

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const location = useLocation()
  React.useEffect(() => {
    closeTooltip();
  }, [location])

  return (
    <div className="md:overflow-x-hidden fadeIn md:mt-[70px] sm:mt-[64px]">
      <div className="flex">
      <div className="container">
          <h1 className="ml-[20%] mt-[20%] md:ml-auto md:mr-auto block sm:text-[30px] md:mt-[1%] md:text-center md:mb-[5%] Shadow text-5xl font-semibold w-[500px] sm:w-full">
            <T uk = "Ставай медійною персоною і монетизуй свою популярність" en = "Become a media person and monetize your popularity">Cтановись медийной личностью и монетизируй свою популярность</T>
          </h1>
        </div><Zoom duration={2500} bottom><div
        style={{ backgroundImage: `url("${aboutmain}")`, width: `700px`, height: `700px` }}
        className="mr-[10%] md:hidden bg-contain bg-right bg-no-repeat items-center h-[calc(100vh_-_78px)]"
      >
        
      </div></Zoom></div>
      
      <div className="Background2 bg-[#1A1A1A] pb-[160px] pt-[70px] md:py-[50px] sm:py-[30px]">
        <div className="max-w-[90vw] my-0 mx-auto md:max-w-none md:mx-0">
          <Fade bottom><div className="flex items-center gap-[115px] lg:gap-[50px] sm:flex-col sm:mb-7">
              <img className="w-[656px] lg:w-1/2 sm:w-full" src={aboutimg1} />
              <div className="w-[370px] px-5 lg:w-1/2 sm:w-full">
                <h3 className="mb-[40px] font-semibold text-3xl">
                  <T uk = "Хто така медійна персона?" en = "Who is a media person?">Кто такая медийная личность?</T>
                </h3>
                <p className="MainText text-white/70">
                  <T
                    uk = "Незалежно від того чи володієш ти великою компанією або просто ведеш свій блог в Інстаграмі, ти вже медійна персона. Ти лідер думок або просто круто робиш те, чим захоплюються маси. У будь-якому разі тепер ти можеш залучати інвестиції в свої проєкти, використовуючи платформу."
                    en = "Regardless of whether you own a large company or just run your blog on Instagram, you are already a media person. You are an opinion leader or just cool doing what the masses admire. In any case, now you can attract investments in your projects using the platform."
                    >Независимо от того владеешь ли ты большой компанией или просто
                  ведешь свой блог в Instagram, ты уже медийная личность. Ты лидер
                  мнений или просто круто делаешь то, чем могут восхититься массы или отдельные личности. В любом
                  случае теперь ты можешь привлекать инвестиции в свои проекты,
                  используя платформу.</T>
                </p>
              </div>
            </div>
          </Fade>

          <Fade bottom><div className="flex items-center justify-end gap-[115px] lg:gap-[50px] sm:flex-col-reverse sm:mb-7">
            <div className="w-[370px] lg:w-1/2 px-5 sm:w-full">
                <h3 className="mb-[40px] font-semibold text-3xl">
                  <T uk = "Як відбувається випуск акцій" en = "How to issue your shares">Как происходит выпуск акций</T>
                </h3>
                <p className="MainText text-white/70">
                  <T
                    uk = "Випускайте свої акції, як це роблять великі компанії та корпорації. Звучить як фантастика? Так, це дійсно унікальна можливість, тільки зробити це дуже просто, досить зареєструватися та скористатися своїм особистим кабінетом. Наші співробітники, допоможуть все правильно оформити."
                    en = "Issue your shares as big companies and corporations do. Does it sound like science fiction? Yes, this is really a unique opportunity and it's very easy to do it, just register and use your personal account. Our staff will help you to arrange everything correctly."
                    >
                    Выпускайте свои акции, как это делают большие компании и
                    корпорации. Звучит как фантастика? Да, это действительно
                    уникальная возможность, только сделать это очень просто,
                    достаточно зарегистрироваться и воспользовыаться своим личным
                    кабинетом. Наши сотрудники, помогут все правильно оформить.
                    Есть три варианта возможностей и регистрации:
                    * Регистрация гостя (все доступно только для просмотра)
                    * Регистрация для покупки акций (нужно подвязать собственную платежную карту)
                    * Регистрация для продажи акций (нужно пройти верификацию)
                  </T>
                </p>
              </div>
              <img className="w-[656px] lg:w-1/2 sm:w-full" src={aboutimg2} />
            </div>
          </Fade>

          <Fade bottom><div className="flex items-center gap-[115px] lg:gap-[50px] sm:flex-col">
            <img className="w-[656px] lg:w-1/2 sm:w-full" src={aboutimg3} />
            <div className="w-[370px] lg:w-1/2 px-5 sm:w-full">
              <h3 className="mb-[40px] font-semibold text-3xl">
                <T uk = "Бенефіти для шанувальників" en = "Benefits for fans">Бенифиты для поклонников</T>
              </h3>
              <p className="MainText text-white/70">
                <T
                  uk = "Ви можете наблизитися до своїх фанатів та інвесторів. Придумайте свої унікальні пропозиції, якими хочете порадувати своїх шанувальників, щоб виділитися та залучити якомога більше уваги, а відповідно і інвестицій."
                  en = "You can get closer to your fans and investors. Come up with your own unique offers that you want to please your fans in order to stand out and attract as much attention as possible, and, accordingly, investments."
                  >
                  Вы можете стать ближе к своим фанатам и инвесторам.
                  Придумайте свои уникальные предложения, которыми хотите порадовать
                  своих поклонников, чтобы выделиться и привлечь как можно
                  больше внимания, а соответственно и инвестиций.
                  Став эмитентом, выпускающим акции, вы сможете быть ближе к своим фанатам и инвесторам. 
                  Время от времени, вы сможете создавать уникальные предложения (приятные подарки, совместные ужины, поздравления, прогулки и т.п.), которыми сможете радовать своих поклонников, чтобы выделиться и привлечь как можно больше внимания, а соответственно и инвестиций.
                  
                </T>
              </p>
            </div>
          </div></Fade>
        </div>
      </div>
      <div className="container py-[100px] lg:py-[80px] md:py-[50px] sm:py-[30px]">
      <Fade bottom><h2 className="text-3xl">
            <T uk = "Переваги для медійної персони" en = "Advantages for the media person">Преимущества для медийной личности</T>
          </h2></Fade>
          <Fade bottom><div className="mt-[50px] flex gap-4 md:flex-col">
          <div className="flex flex-col w-1/3 md:w-full">
            <div className="p-10 bg-[#4D475B] h-full">
              <img className="w-[227px] mb-[50px] md:mx-auto" src={Cart1Img} alt="" />
              <h3 className="AlternativeText font-semibold text-2xl mb-5">
                <T uk = "Монетизуйте свою популярність" en = "Monetize your popularity">Монетизируй свою популярность</T>
              </h3>
              <p className="text-white/70">
                <T
                  uk = "Незалежно від того, чим ти зараз займаєшся і наскільки ти сьогодні популярний, за допомогою нашого ресурсу та активності в ньому, ти зможете зробити так, щоб люди в тебе інвестували."
                  en = "Regardless of what you are doing now and how popular you are today, with the help of our resource and activity in it, you can make people invest in you."
                  > Независимо от того чем ты сейчас занимаешься и насколько ты сегодня популярен, с помощью нашего ресурса и активности в нем ты сможешь сделать так, чтобы люди в тебя инвестировали.</T>
              </p>
            </div>
          </div>
          <div className="flex flex-col w-1/3 md:w-full">
            <div className="p-10 bg-[#5A5441] h-full">
              <img className="w-[227px] mb-[50px] md:mx-auto" src={Cart2Img} alt="" />
              <h3 className="AlternativeText font-semibold text-2xl mb-5">
                <T uk = "Винагороджуйте найвідданіших фанатів" en = "Reward the most loyal fans">Вознаграждайте самых преданных фанатов</T>
              </h3>
              <p className="text-white/70">
                <T
                  uk = "Найхаризматичніші люди вміють ділитися. Заробляй славу і репутацію через шанувальників."
                  en = "The most charismatic people know how to share. Earn fame and reputation through fans."
                  >
                  Самые харизматичные люди умеют делиться. Зарабатывай славу и репутацию через поклонников.
                </T>
              </p>
            </div>
          </div>
          <div className="flex flex-col w-1/3 md:w-full">
            <div className="p-10 bg-[#3D465B] h-full">
              <img className="w-[227px] mb-[50px] md:mx-auto" src={Cart3Img} alt="" />
              <h3 className="AlternativeText font-semibold text-2xl mb-5">
                <T uk = "Персональні акції" en = "Personal promotions">Персональные акции</T>
              </h3>
              <p className="text-white/70">
                <T
                  uk = "Так, дійсно. Ти і твоя праця є цінністю, а люди будуть в це вкладатися."
                  en = "Yes, exactly. You and your work are valuable, and people will invest in it."
                  >
                  Да, именно. Ты и твой труд являются ценностью, а люди будут в это вкладываться.
                </T>
              </p>
            </div>
          </div>
        </div></Fade>
      </div>
      <div className="bg-[#1A1A1A]">
      <div className="flex flex-auto justify-center mx-auto Background2 bg-[#1A1A1A] gap-[80px] pb-[50px] lg:flex-col lg:container max-w-[90vw] md:max-w-auto">
        <div className="Background2 bg-[#1A1A1A] w-[1200px] lg:w-auto">
          <div className="container">
            <div className="flex flex-col justify-between gap-4 sm:flex-col ">
              <h2 className="py-[100px] text-4xl text-center font-semibold lg:text-3xl lg:py-[90px] sm:py-[30px]">
                <T uk = "Як стати публічною персоною?" en = "How to become a public person?">Как стать публичной личностью?</T>
              </h2>
              <Fade bottom cascade><div className="flex flex-col">
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] MainButtonText lg:text-[100px]">
                    1
                  </h2>
                  <p className="font-semibold text-2xl">
                    <T uk = "Зареєструйся на платформі" en = "Register on the platform">Зарегистрируйся на платформе</T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] MainButtonText lg:text-[100px]">
                    2
                  </h2>
                  <p className="font-semibold text-2xl pr-2">
                    <T uk = "Поповни власний рахунок" en = "Top up your account">Пополни свой счёт</T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] text-stroke text-transparent lg:text-[100px]">
                    3
                  </h2>
                  <p className="font-semibold text-2xl">
                    <T uk = "Вибери акції які тебе цікавлять" en = "Choose the stocks you are interested in">
                    Выбери акции которые тебя интересуют
                    </T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px] pt-[100px] border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] text-stroke text-transparent lg:text-[100px]">
                    4
                  </h2>
                  <p className="font-semibold text-2xl">
                    <T uk = "Зайди на сторінку біржі і купи акції на обрану суму" en = "Go to the exchange page and buy shares for the selected amount">
                      Зайди на страницу биржи и купи акции на выбранную сумму
                    </T>
                  </p>
                </div>
              </div></Fade>
            </div>
          </div>
        </div>
        <div className="Background2 bg-[#1A1A1A] w-[1200px] lg:w-auto lg:mt-[-40px]">
          <div className="container">
            <div className="flex flex-col justify-between gap-4 sm:flex-col ">
              <h2 className="py-[100px] text-4xl text-center font-semibold lg:text-3xl lg:py-[90px] sm:py-[30px]">
                <T uk = "Як стати емітентом?" en = "How to become an issuer?">Как стать эмитентом?</T>
              </h2>
              <Fade bottom cascade><div className="flex flex-col">
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] MainButtonText lg:text-[100px]">
                    1
                  </h2>
                  <p className="font-semibold text-2xl lg:text-lg">
                    <T uk = "Зареєструйся на платформі" en = "Register on the platform">Зарегистрируйся на платформе</T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] MainButtonText lg:text-[100px]">
                    2
                  </h2>
                  <p className="font-semibold text-2xl lg:text-lg">
                    <T uk = "Заповни анкету для створення власних акцій" en = "Fill in the form to create your own shares">Заполни анкету для создания собственных акций</T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px] border-b pt-[100px] border-b-gray border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] text-stroke text-transparent lg:text-[100px]">
                    3
                  </h2>
                  <p className="font-semibold text-2xl lg:text-lg">
                    <T uk = "Пройди верифікацію" en = "Complete the verification process">
                      Пройди верификацию
                    </T>
                  </p>
                </div>
                <div className="flex gap-[80px] pb-[72px] pl-[90px]  pt-[100px] border-l AboutBorder lg:pl-[50px] lg:pt-[90px] lg:pb-[60px] lg:gap-[40px]">
                  <h2 className="font-semibold text-[134px] text-stroke text-transparent lg:text-[100px]">
                    4
                  </h2>
                  <p className="font-semibold text-2xl lg:text-lg">
                  <T uk = "Наші адміністратори зв'яжуться з тобою і обговорять умови розміщення акцій" en = "Our administrators will contact you and discuss the terms of the share placement">
                      Наши администраторы свяжутся с тобой и обсудят условия размещения акций
                    </T>
                  </p>
                </div>
              </div></Fade>
          </div>
        </div>
        </div>
        </div>
      </div>
      <div className="AboutBackround py-[83px] md:py-[50px] sm:py-[30px] relative overflow-hidden">
        <img
          className="absolute top-[100px] right-0 lg:w-3/4 sm:w-full sm:bottom-0 sm:right-0 sm:top-auto"
          src={LineImg}
          alt=""
        />
        <Fade bottom ><div className="container">
          <div className="flex items-center gap-[70px] md:gap-5 sm:flex-col">
            <div className="flex-col md:w-1/2 sm:w-full">
              <h2 className="AlternativeText font-bold text-5xl mb-10 md:text-3xl">
                <T uk = "Хочеш монетизувати свою популярність?" en = "Do you want to monetize your popularity?">Хочешь монетизировать свою популярность?</T>
              </h2>
              <p className="AlternativeText mb-12">
                <T uk = "Реєструйся на платформі і залишай заявку!" en = "Register on the platform and leave a request!">Регистрируйся на платформе и оставляй заявку!</T>
              </p>
              <Popup ref={ref} trigger={<button className="btn btn-white w-[183px] sm:w-full"> <T uk = "Залишити заявку" en = "Submit your Request">Оставить заявку</T></button>} modal className="testbl">
                                  {close => (
                                      <div className="modal">
                                          <div className="content">
                                              <Request closePopUp={close}/>
                                          </div>
                                      </div>
                                  )}
              </Popup>
            </div>
            <img
              className="w-[554px] h-[554px] relative z-10 lg:w-1/2 lg:h-auto sm:w-full"
              src={MonitImg}
              alt=""
            />
          </div>
        </div></Fade>
      </div>
    </div>
  );
};
export default About;
