import React, { useEffect, useRef , useState, useContext} from "react";
import { useLocation } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Registration from "../pages/Registration";
import Singin from "../pages/Singin";

import Select from "react-select";
import ThemeSelect from "../components/ThemeSelect"

import HeaderMobile from "./HeaderMobile";
import {LanguageSwitcher} from '../Translation'

import { FaBars } from "react-icons/fa";
import "../assets/css/main.css";

import LogoImg from "../assets/img/logo.png";
import {LoginContext} from "../App.js"

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: state.isSelected ? "#ffffff" : "#ffffff",
    backgroundColor: state.isSelected ? "transparent" : "transparent",
    borderRadius: "4px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    backgroundColor: "transparent",
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    color: "#ffffff",
    borderColor: "none",
    borderWidth: "0",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided) => ({
    ...provided,
    padding: 10,
    backgroundColor: "transparent",
  }),
  input: (provided) => ({
    ...provided,
    color: "transparent",
    boxShadow: "none",
  }),
};

const Header = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);

  const menuList = [
    { title: "О платформе", url: "/about" },
    { title: "Личности", url: "/personalities" },
    { title: "Рейтинг", url: "/market" },
  ];

  const personalities = [
    {
      name: 'Александр Усик',
      prices: [
        207.61,  206.3, 207.65, 212.89,
        209.45,  208.3, 203.94, 200.88,
        198.24, 198.41, 193.78, 194.74,
        194.81, 198.93, 203.99, 206.12,
        201.89, 202.43, 202.92, 202.83,
        200.68, 200.21, 202.81, 197.99,
        197.14, 193.87, 193.01, 195.59,
        197.03, 198
      ]
    },
    {
      name: 'Alyona Alyona',
      prices: [
        182.75, 182.89,  181.9, 177.64,
        179.85, 182.33, 179.83, 184.12,
        180.94, 183.51, 181.72, 184.29,
         181.5, 181.65, 180.46, 183.98,
        181.06, 180.16, 182.25, 178.03,
        174.67, 174.37, 176.63, 175.68,
         172.4,    171, 168.92, 172.28,
        174.55,    169
      ]
    },
    {
      name: 'Макс Барских',
      prices: [
        104.46, 105.78, 108.05, 108.63,
        106.29, 106.83, 108.74, 108.58,
        110.21, 109.49, 110.98, 108.59,
         108.7, 111.04, 111.85, 113.59,
        116.27, 116.85, 118.03,  117.9,
        116.19, 118.17, 117.57, 119.04,
        116.22, 118.95, 117.94, 115.87,
        113.33,    111
      ]
    },
    {
      name: 'Monatik',
      prices: [
        117.73, 117.51, 116.17, 117.13,
        119.28, 119.38, 117.67, 118.01,
        117.23, 118.75,  120.8,  120.2,
         121.4, 120.53, 120.64, 123.25,
         124.4, 123.02, 120.18, 120.31,
        122.43, 125.19, 123.02, 124.32,
        126.17, 126.39, 124.24, 125.29,
         127.6,    133
      ]
    },
  ];

  function generateData(personalities) {
    const data = {};

    for (const person of personalities) {
      data[person.name] = Array.from({ length: 30 }, (v, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i);
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${day}-${month}-${year}`;
        const price = person.prices[i];
        return { date: formattedDate, price };
      });
    }

    return data;
  }

  if (localStorage.getItem('stockData') === null) {
    const data = generateData(personalities);
    localStorage.setItem('stockData', JSON.stringify(data));
  }

  const ref = useRef();
  const reff = useRef();
  const closeTooltip = () => ref.current.close();
  const closeTooltipp = () => reff.current.close();
  const location = useLocation()
  React.useEffect(() => {
    //if user is not logged in
    if (!isLoggedIn) {
      closeTooltip();
      closeTooltipp();
  }
  }, [location])

  // React.useEffect(() => {
  //   alert(isLoggedIn)
  // })

  const customStyles = {
      option: (provided, state) => ({
        ...provided,
        marginTop: "-5px",
        height: "30px",
        fontWeight: state.isSelected ? "900" : "normal",
        color: state.isSelected ? "var(--color)" : "#fffffff",
        backgroundColor: state.isSelected ? "transparent" : "transparent",
        borderRadius: "4px",
        cursor: "pointer",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "var(--color)",
        backgroundColor: "transparent",
      }),
      control: (base, state) => ({
        ...base,
        background: "transparent",
        color: "#ffffff",
        borderColor: "none",
        borderWidth: "0",
        boxShadow: state.isFocused ? null : null,
        cursor: "pointer",
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: 0,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 0,
        paddingTop: 0,
        backgroundColor: "rgba(49,49,49,0.25) !important",
        backdropFilter: "blur(4px) !important",
      }),
      input: (provided) => ({
        ...provided,
        color: "transparent",
        boxShadow: "none",
      }),
    };

  return (
    <>
      <HeaderMobile
        menuList={menuList}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
      <header className="py-5 container header:fixed header:top-0 HeaderMobile header:z-40 pr-0 header:pr-[20px]">
        <div className="flex justify-between items-center">
          <div className="flex">
            <Link to="/">
              <img
                className="Logo w-[190px] h-[22px] mr-[60px] cursor-pointer"
                src={LogoImg}
                alt="Logo Personal shares"
              />
            </Link>
            <ul className="flex gap-[30px] header:hidden">
                {menuList.map((item) => (
                  <li key={item.title}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `MainButtonText`
                          : `text-white duration-300 Hover`
                      }
                      to={item.url}
                    >
                      <T>{item.title}</T>
                    </NavLink>
                  </li>
                ))}
              <li>
               <a href={Blog("tag/baza-znanii")} rel="noopener noreferrer">
               <T uk = "Як користуватись" en = "How to use">Как пользоваться</T>
                </a>
              </li>
              <li>
                <a href={Blog("tag/news")} rel="noopener noreferrer">
                  <T uk = "Новини" en = "News">Новости</T>
                </a>
              </li>
            </ul>
          </div>
          <FaBars
            onClick={() => setShowMenu(true)}
            className="hidden header:flex header:text-3xl sm:text-2xl"
          />
          <div className="flex items-center gap-[0px] header:hidden z-10">
            <ThemeSelect
              customStyles={customStyles}
            />
            <div className=""><LanguageSwitcher customStyles = {customStyles}/></div>
            {!isLoggedIn ? (
              //<>
              //  <Link
              //    className="px-[30px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold"
              //    to="/singin"
              //  >
              //    Вход
              //  </Link>
              //  <Link
              //    className="underline-offset-2 underline py-1 text-primary font-bold text-sm"
              //    to="/registration"
              //    onClick={() => posthog.capture("registration")}
              //  >
              //    Регистрация
              //  </Link>
              //</>
              <>

                              <Popup nested ref={ref} trigger={<button className="w-[73px] px-[15px] text-sm underline-offset-2 underline py-1 font-bold"> <T uk = "Вхід" en = "Sign in">Вход</T></button>} modal className="testbl">
                                  {close => (
                                      <div className="modal">
                                          <div className="content">
                                              <Singin closePopUp={close}/>
                                          </div>
                                      </div>
                                  )}
                              </Popup>
                              <Popup ref={reff} trigger={<button className="MainButtonText min-w-[106px] underline-offset-2 underline py-1 pl-[15px] pr-[15px] font-bold text-sm"> <T uk = "Реєстрація" en = "Sign up">Регистрация</T></button>} modal className="testbl">
                                  {close => (
                                      <div className="modal">
                                          <div className="content">
                                              <Registration closePopUp={close}/>
                                          </div>
                                      </div>
                                  )}
                              </Popup>
                </>
            ) : (
              <>
                <a
                  className="w-[74px] px-[15px] text-sm border-x border-[#2B2B2B] underline-offset-2 underline py-1 font-bold text-center"
                  href={`https://${window.location.host}/api/logout`}
                >
                  <T uk = "Вийти" en = "Exit">Выйти</T>
                </a>
                <Link reloadDocument className="w-[109px] underline-offset-2 text-sm pl-[15px] underline py-1 font-bold text-center" to="/user">
                  <T uk = "Моя сторінка" en = "My page">Моя страница</T>
                </Link>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
