import React from "react";

import {useState, useEffect, useRef} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import searchimg from "../assets/img/search.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from './LineChart';
import BloggersList from './BloggersList';
import Select, { AriaOnFocus } from 'react-select';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from "react-chartjs-2";
import BriefCase from "../assets/img/briefcase.png";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CiCircleQuestion } from "react-icons/ci";
import {useStore} from '../Store';
import Pay from '../components/Pay';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

let tokenName;
let tokenCost;

const Converter = (props) => {

  if (localStorage.getItem('userData') === null) {

    const userData = {
      balance: 4000,
      shares: {
        DRFVA: 0,
        MNTK: 2,
        BRSKH: 1,
        ALNA: 3,
        USYK: 0
      }
    };
    localStorage.setItem('userData', JSON.stringify(userData));
  }
  const retrievedData = localStorage.getItem('userData');
  const parsedData = JSON.parse(retrievedData);

  useEffect(() => {

    let name = props.name;

    if (name === "Александр Усик") {
        tokenName = "USYK";
        tokenCost = 198;
    } else if (name === "Alyona Alyona") {
        tokenName = "ALNA";
        tokenCost = 169;
    } else if (name === "Макс Барских") {
        tokenName = "BRSKH";
        tokenCost = 111;
    } else if (name === "Monatik") {
        tokenName = "MNTK";
        tokenCost = 133;
    } else if (name === "DOROFEEVA") {
        tokenName = "DRFVA";
        tokenCost = 176;
    } else {
        tokenName = "COIN";
        tokenCost = 150;
    }

  }, []);

  const getMoneyBalance = () => {
    if (parsedData.balance != null) {
      return parsedData.balance;
    }else{
      return 3000;
    }
  }
  const getSharesBalance = () => {
    let name = props.name;
    let balance;
    console.log(name)
    if (name === "Александр Усик") {
      balance = parsedData.shares.USYK;
    } else if (name === "Alyona Alyona") {
        balance = parsedData.shares.ALNA;
    } else if (name === "Макс Барских") {
        balance = parsedData.shares.BRSKH;
    } else if (name === "Monatik") {
        balance = parsedData.shares.MNTK;
    } else if (name === "DOROFEEVA") {
        balance = parsedData.shares.DRFVA;
    } else {
        balance = 22;
    }
      return balance;
  }

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isSearchable] = useState(false);
  const [moneyBalance, setMoneyBalance] = useState(getMoneyBalance);
  const [sharesBalance, setSharesBalance] = useState(getSharesBalance);

  // const deadline = "August, 30, 2023, 20:00:00 GMT";
  const deadline = props.person.listing_date

  const getTime = () => {
    const time = Date.parse(props.person.listing_date) >= Date.now() ? Date.parse(props.person.listing_date) - Date.now() : 0;

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  const [num, setNum] = useState(0);

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  useEffect( () => {
    setNum(randomNumberInRange(300, 898));

  }, [])

const [isBuying, setIsBuying] = useState(true);


useEffect( () => {

  setIsBuying(props.buySellSwitch);

}, [props.buySellSwitch])

const handleMenuChange = () => {
  setIsBuying(!isBuying);
}

Chart.register(ChartDataLabels);

const generateUserData = (UserData) => {
    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    UserData.push(
        {
            "point": 1,
            "number": getRandomInt(-80, 80)
        }
    )
    for (let i = 2; i <= 20; i++) {
        let n = UserData[i-2].number + getRandomInt(-25, 25);
        UserData.push(
            {
                "point": i,
                "number": n
            }
        );
    }
}

function computePercentageDifference(num1, num2) {
  let difference = num1 - num2;
  if (difference === 0) {
    return 0;
  }
  let percentageDifference = (difference / num2) * 100;
  return percentageDifference;
}

function getStockData(personName) {
  const data = JSON.parse(localStorage.getItem('stockData'));
  return data[personName];
}

const chartData = getStockData(props.name)

let UserData = [];

if(chartData && chartData.length > 0){
  UserData = chartData;
}else{
  generateUserData(UserData);
}

let number = computePercentageDifference(UserData[UserData.length-1].price, UserData[0].price);

    const [userData, setUserData] = useState({
        labels: UserData.map((data) => data.date),
        datasets: [
        {
            label: "",
            data: UserData.map(data => Math.round(data.price)),
            backgroundColor: "transparent",
            cubicInterpolationMode: 'monotone',
            pointStyle: 'circle',
            borderColor: () => {
                if (number > 0) {
                return "#32CD32";
            } else if (number == 0){
                return "#32CD32";
            } else {
                return "#DC143C";
            }},
            pointBackgroundColor: () => {
                return "white";
            },
            pointBorderColor: () => {
                return "white";
            },
            pointHoverBackgroundColor:() => {
                return "white";},
            pointHoverBorderColor: () => {
                return "white";},
            borderWidth: 3,
            pointHitRadius: 6,
            pointRadius: () => {
                let arr = Array(UserData.length).fill(1);
                arr[arr.length - 1] = 3;
                return arr;
              },
            pointHoverRadius: 6,
            fill: 'start',
            datalabels: {
            align: 'right',
            }
        },
        ],
    });

    const [options, setOptions] = useState({
      layout: {
  },
      plugins: {
          tooltip: {
                  mode: 'nearest',
                  intersect: false,
                  usePointStyle: true
          },
      datalabels: false,
      legend: {
          display: false,
      },
  },
  interaction: {
      intersect: false,
      mode: 'nearest'
  },
      scales: {
      x: {
          display: true,
          title: {
          display: true,
          },
          grid: {
          display: true,
          },
          ticks: {
          maxRotation: 0,
      },
      },
      y: {
          position: 'left',
          title: {
          display: true,
          },
          display: true,
          grid: {
          display: true,
          }
      }
      }
  });

const [selltextQuery, setSellTextQuery] = useState('');

const [buytextQuery, setBuyTextQuery] = useState('');

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

function saveInput(){
  console.log('Saving data');
}



const sellProcessChange = debounce(() => saveInput());

const buyProcessChange = debounce(() => saveInput());

function handleSellTextChange(event){
  let newValue = event.target.value;
  if (newValue == ".") {
    newValue = 0;
  }
  setSellTextQuery(newValue);
}


function handleInput(event) {
  let value = event.target.value;

  if (!/^\d*\.?\d*$/.test(value)) {
    value = value.replace(/[^\d.]/g, '');
  }

  const dotIndex = value.indexOf('.');
  if (dotIndex !== value.lastIndexOf('.')) {
    value = value.substring(0, dotIndex + 1) + value.substring(dotIndex + 1).replace('.', '');
  }

  event.target.value = value;
}

function handleBuyTextChange(event){
  let newValue = event.target.value;
  if (newValue == ".") {
    newValue = 0;
  }
    setBuyTextQuery(newValue);
  }

const buySwitchInputStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: state.isSelected ? "var(--color)" : "#fffffff",
    backgroundColor: state.isSelected ? "transparent" : "transparent",
    borderRadius: "4px",
    cursor: state.isFocused ? "pointer" : "default",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "var(--color)",
    backgroundColor: "transparent",
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    color: "var(--color)",
    borderColor: "#4d4d4d",
    borderWidth: "2px",
    borderRadius: "0",
    padding: "3",
    height: "56px",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    paddingRight: 0,
    paddingTop: 0,
    backgroundColor: "rgba(49,49,49,0.25) !important",
    backdropFilter: "blur(4px) !important",
  }),
  input: (provided) => ({
    ...provided,
    color: "var(--color)",
    boxShadow: "none",
  }),
};

const [quantityLabel, setQuantityLabel] = useState("По количеству");
const [amountLabel, setAmountLabel] = useState("По сумме");
const [buySwitch, setBuySwitch] = useState({ label: quantityLabel, value: 'quantity'});

const handleBuySwitchChange = (e) => {
  setBuySwitch(e);
}

const buySwitchInputOptions = [
  { label: amountLabel, value: 'amount' },
  { label: quantityLabel, value: 'quantity' }
];
const [transactionErrorMessage, setTransactionErrorMessage] = useState('Ошибка транзакции');
const [transactionSuccessMessage, setTransactionSuccessMessage] = useState('Транзакция прошла успешно');
const language = useStore(x => x.language.language)

useEffect(() => {

  if (language == 'ru'){

    setQuantityLabel("По количеству");
    setAmountLabel("По сумме");

    setTransactionErrorMessage('Ошибка транзакции');
    setTransactionSuccessMessage('Транзакция прошла успешно');

    if (buySwitch.value === 'quantity') {
      setBuySwitch({ label: "По количеству", value: 'quantity'})
    }else{
      setBuySwitch({ label: "По сумме", value: 'ammount'})
    }

  };

  if (language == 'uk'){

    setQuantityLabel("За кількістю");
    setAmountLabel("За сумою");

    setTransactionErrorMessage('Помилка транзакції');
    setTransactionSuccessMessage('Транзакція пройшла успішно');

    if (buySwitch.value === 'quantity') {
      setBuySwitch({ label: "За кількістю", value: 'quantity'})
    }else{
      setBuySwitch({ label: "За сумою", value: 'ammount'})
    }

  };

  if (language == 'en'){

    setQuantityLabel("By quantity");
    setAmountLabel("By amount");

    setTransactionErrorMessage('Transaction Error');
    setTransactionSuccessMessage('The transaction was successful');

    if (buySwitch.value === 'quantity') {
      setBuySwitch({ label: "By quantity", value: 'quantity'})
    }else{
      setBuySwitch({ label: "By amount", value: 'ammount'})
    }

  };

}, [language]);


const buyShares = async () => {
  if (buySwitch.value === 'amount') {
    let tokensToBuy = props.person.is_ipo ? window.round(buytextQuery / props.person.purchase_price, 2) : window.round(buytextQuery / props.person.listing_price, 2);
    if (buytextQuery < moneyBalance && buytextQuery > 0 && (Number(props.person.token_sold) + tokensToBuy <= Number(props.person.token_amount) )) {
      setMoneyBalance(moneyBalance - buytextQuery)
      setSharesBalance(sharesBalance + (buytextQuery / (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price)))

      let response = await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          token_sold: Number(props.person.token_sold) + tokensToBuy
          })
      })
      //console.log(response)
      if (!response.ok) {
        switch(response.status) {
          case 401:
            buyErrorAlert()
            return;
            break;
          default:
            buyErrorAlert()
            return;
        }
      }

      let activity = {
        share_buy: true,
        shares: tokensToBuy,
        share_price: (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price),
        person_id: props.person.nickname,
        user: props.currentUserPersonID, /// Взяти id залогіненого юзера
      };

      await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          last_activity: {
            create: [activity]
          }
        })
      })

      buyAlert();
      props.fr()

      //
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
          const userData = JSON.parse(storedUserData);

          userData.balance = moneyBalance - buytextQuery;

          switch (tokenName) {
            case "USYK":
              userData.shares.USYK = sharesBalance + (buytextQuery / tokenCost);
              break;
            case "ALNA":
              userData.shares.ALNA = sharesBalance + (buytextQuery / tokenCost);
              break;
            case "BRSKH":
              userData.shares.BRSKH = sharesBalance + (buytextQuery / tokenCost);
              break;
            case "MNTK":
              userData.shares.MNTK = sharesBalance + (buytextQuery / tokenCost);
              break;
            case "DRFVA":
              userData.shares.DRFVA = sharesBalance + (buytextQuery / tokenCost);
              break;
            default:
              break;
          }

          const updatedUserData = JSON.stringify(userData);

          localStorage.setItem('userData', updatedUserData);
      } else {
          console.log("userData does not exist in localStorage.");
      }
      //

    }else{
      buyErrorAlert()
    }
  }else{
    if (window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2) < moneyBalance && window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2) > 0 && ((Number(props.person.token_sold) + Number(buytextQuery) <= Number(props.person.token_amount) ))) {
      setMoneyBalance(moneyBalance - window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2))
      setSharesBalance(sharesBalance + parseFloat(buytextQuery))

      let response = await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          token_sold: Number(props.person.token_sold) + window.round(buytextQuery, 2)
          })
      })
      //console.log(response)
      if (!response.ok) {
        switch(response.status) {
          case 401:
            buyErrorAlert()
            return;
            break;
          default:
            buyErrorAlert()
            return;
        }
      }

      let activity = {
        share_buy: true,
        shares: window.round(buytextQuery, 2),
        share_price: (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price),
        person_id: props.person.nickname,
        user: props.currentUserPersonID, /// Взяти id залогіненого юзера
      };

      await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          last_activity: {
            create: [activity]
          }
        })
      })

      buyAlert();
      props.fr()

      //
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
          const userData = JSON.parse(storedUserData);

          userData.balance = moneyBalance - window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2)

          switch (tokenName) {
            case "USYK":
              userData.shares.USYK = sharesBalance + parseFloat(buytextQuery);
              break;
            case "ALNA":
              userData.shares.ALNA = sharesBalance + parseFloat(buytextQuery);
              break;
            case "BRSKH":
              userData.shares.BRSKH = sharesBalance + parseFloat(buytextQuery);
              break;
            case "MNTK":
              userData.shares.MNTK = sharesBalance + parseFloat(buytextQuery);
              break;
            case "DRFVA":
              userData.shares.DRFVA = sharesBalance + parseFloat(buytextQuery);
              break;
            default:
              break;
          }

          const updatedUserData = JSON.stringify(userData);

          localStorage.setItem('userData', updatedUserData);
      } else {
          console.log("userData does not exist in localStorage.");
      }
      //
    }else{
      buyErrorAlert()
    }
  }
}

const sellShares = async () => {

  if (selltextQuery <= sharesBalance && selltextQuery > 0) {
    setMoneyBalance(moneyBalance + window.round(selltextQuery * tokenCost, 2))
    setSharesBalance(sharesBalance - selltextQuery)

    let response = await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        token_sold: Number(props.person.token_sold) - window.round(selltextQuery, 2)
        })
    })
    //console.log(response)
    if (!response.ok) {
      switch(response.status) {
        case 401:
          buyErrorAlert()
          return;
          break;
        default:
          buyErrorAlert()
          return;
      }
    }

    let activity = {
      share_buy: false,
      shares: window.round(selltextQuery, 2),
      share_price: (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price),
      person_id: props.person.nickname,
      user: props.currentUserPersonID, /// Взяти id залогіненого юзера
    };

    await fetch(`https://${window.location.host}/api/items/Person/${props.person.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        last_activity: {
          create: [activity]
        }
      })
    })

    buyAlert();
    props.fr()



    buyAlert();

    //
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
        const userData = JSON.parse(storedUserData);

        userData.balance = moneyBalance + window.round(selltextQuery * tokenCost, 2)

        switch (tokenName) {
          case "USYK":
            userData.shares.USYK = sharesBalance - selltextQuery;
            break;
          case "ALNA":
            userData.shares.ALNA = sharesBalance - selltextQuery;
            break;
          case "BRSKH":
            userData.shares.BRSKH = sharesBalance - selltextQuery;
            break;
          case "MNTK":
            userData.shares.MNTK = sharesBalance - selltextQuery;
            break;
          case "DRFVA":
            userData.shares.DRFVA = sharesBalance - selltextQuery;
            break;
          default:
            break;
        }

        const updatedUserData = JSON.stringify(userData);

        localStorage.setItem('userData', updatedUserData);
    } else {
        console.log("userData does not exist in localStorage.");
    }
    //

  }else{
    buyErrorAlert()
  }
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  width:'40em',
  showConfirmButton: true,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const buyAlert = () => {
  Toast.fire({
    icon: 'success',
    title: transactionSuccessMessage
  })
}

const buyErrorAlert = () => {
  Toast.fire({
    icon: 'error',
    title: transactionErrorMessage,
  })
}

    return (
      <div className="py-[60px]">
        <div className="flex mb-[30px]">
            {props.is_ipo ?  <h2 className="font-semibold text-3xl container md:whitespace-normal"><T uk = "Передпродаж " en = "Pre-sale ">Предпродажа </T>{props.name}</h2>: <h2 className="font-semibold text-3xl container md:whitespace-normal"><T uk = "Акції " en = "Shares ">Акции </T>{props.name}</h2>}
        </div>
         <div className="flex mt-[40px] mb-[70px] gap-4 md:mt-[10px] md:flex-col-reverse md:gap-10">
         {props.is_ipo ? <></> : <div className="Background3 flex flex-col h-[480px] w-[50%] md:w-full md:h-auto md:mt-[80px]">
              <div className="w-[100%] h-[75%] p-10 pl-0 pb-0 mt-[0px] md:h-[180px] md:mb-[20px] md:p-0 md:ml-[-10px] md:mt-[35px]">
                    <Line id = {'chart'} data={userData} options = {options} redraw = {true}/>
              </div>
                <div className="w-[100%] h-[30%] p-10 pl-0 pb-0 mt-[-20px] AboutBackround md:mt-[10px]">
                  <div className="flex justify-between text-xs p-5 pt-0 gap-5 pl-14 shrink-0 md:pl-12 md:pb-10">
                      <div className="flex flex-col w-[25%] gap-1 AlternativeButtonText">
                        <label>1.</label>
                        <label><T uk = "Введіть суму та виберіть метод оплати" en = "Enter an amount and select a payment method">Введите сумму и выберите метод оплаты</T></label>
                      </div>
                      <div className="flex flex-col w-[25%] gap-1 AlternativeButtonText">
                        <label>2.</label>
                        <label><T uk = "Уточніть деталі платежу" en = "Check the details of the payment">Уточните детали платежа</T></label>
                      </div>
                      <div className="flex flex-col w-[25%] gap-1 AlternativeButtonText">
                        <label>3.</label>
                        <label><T uk = "Пройдіть 3Ds перевірку" en = "Pass a 3Ds check">Пройдите 3Ds проверку</T></label>
                      </div>
                      <div className="flex flex-col w-[25%] gap-1 AlternativeButtonText">
                        <label>4.</label>
                        <label><T uk = "Платіж завершено" en = "Payment completed">Платёж завершён</T></label>
                      </div>
                  </div>
                </div>
            </div>}
            {isBuying ?
            <div className="h-[480px] w-[50%] md:w-full md:h-[480px]">
                <div className="flex h-[14%]">
                    <div className="TransitionOff ConverterBuy border-t-2 w-1/3">
                      <button className="TransitionOff btn h-full text-sm py-[24px] md:py-auto xs:py-[14px] whitespace-nowrap sm:whitespace-normal"><T uk = "КУПИТИ АКЦІЇ" en = "BUY SHARES">КУПИТЬ АКЦИИ</T></button>
                    </div>
                    {props.is_ipo ? <></>: <div className="TransitionOff AboutBackround border-t-2 border-t-transparent w-1/3">
                      <button className="TransitionOff btn h-full text-sm py-[24px] AlternativeButtonText md:py-auto xs:py-[14px] whitespace-nowrap sm:whitespace-normal" onClick = {handleMenuChange}><T uk = "ПРОДАТИ АКЦІЇ" en = "SELL SHARES">ПРОДАТЬ АКЦИИ</T></button>
                    </div>}
                    <div className="Background1 w-1/3"></div>
                </div>
                <div className="Background3 flex flex-col gap-[70px] p-10 pb-8 h-[412px] md:h-auto">
                  <div className="flex gap-7 md:flex-col">
                    <div className="flex flex-col gap-7 w-[60%] md:w-full">
                      <div className="flex">
                        <label htmlFor="currencyinput" className="flex flex-col gap-4 text-xs text-white relative w-[100%] whitespace-nowrap">
                          <T uk = "Мій баланс" en = "My balance">Мой баланс</T>
                          <div className="flex gap-5">
                        <div
                            className={`p-3 pl-0 text-white text-2xl outline-none w-[50%]`}
                        >
                          <span className={`text-lightGray`}>$</span> <span>{round(moneyBalance, 2)}</span>
                        </div>
                        </div>
                        </label>
                        <label htmlFor="currencyinput" className="flex flex-col gap-4 text-xs text-white relative w-[100%] whitespace-nowrap">
                        <T uk = "Ціна за акцію" en = "Price per share">Цена за акцию</T>
                          <div className="flex gap-5">
                        <div
                            className={`p-3 pl-0 text-white text-2xl outline-none w-[50%]`}
                        >
                          <span className={`text-lightGray`}>$</span> <span>{round(props.person.is_ipo ? props.person.purchase_price : props.person.listing_price)}</span>
                        </div>
                        </div>
                        </label>
                      </div>
                      <div className="flex gap-5">
                        <label htmlFor="suminput" className="flex flex-col gap-4 text-xs text-white relative w-full whitespace-nowrap">
                          <T uk = "Хочу купити" en = "I want to buy">Хочу купить</T>
                        <div className="flex gap-2">
                        <Select
                          className={`w-[50%]`}
                          value={buySwitch}
                          styles = {buySwitchInputStyles}
                          inputId="suminput"
                          name="aria-live-color"
                          options={buySwitchInputOptions}
                          onChange={handleBuySwitchChange}
                          isSearchable={isSearchable}
                        />
                        <input
                            className={`bg-transparent p-3 text-white border-2 border-gray outline-none w-[35%]`}
                            onChange={handleBuyTextChange}
                            onKeyUp={buyProcessChange}
                            onInput={handleInput}
                        />
                        <div
                        className={`p-3 pl-0 text-white text-2xl outline-none mr-[-20px]`}
                        >
                          <span className={`text-lightGray`}>{buySwitch.value == 'amount' ? <>$</> : <>{tokenName}</> }</span>
                        </div>
                        </div>
                        </label>
                      </div>
                    </div>
                    <div className="w-[1px] bg-gray md:h-[1px] md:w-full"></div>
                    {buySwitch.value == 'amount' ?
                    <div className="flex flex-col gap-12 w-[30%] md:flex-row md:gap-7 md:w-full">
                    <label className="mx-auto text-xs text-white md:my-auto ">
                      <T uk = "Ви отримаєте:" en = "You will receive:">Вы получите:</T>
                      {/* <CiCircleQuestion className="mr-[-15px] mt-[-5px]" size="25px"></CiCircleQuestion> */}
                    </label>
                    <img className="w-[60px] mx-auto " src = {BriefCase} alt="" />
                    <label className="mx-auto text-2xl md:text-lg md:my-auto whitespace-nowrap md:whitespace-normal">
                      { (buytextQuery > moneyBalance) ? <span className="text-sm"><T uk = "Недостатньо коштів" en = "Insufficient funds">Недостаточно средств</T><Popup modal className="testbl" trigger={<button className="ButtonColor absolute AlternativeButtonText btn bg-primary w-[160px] md:w-[80px] text-l md:text-sm mt-[25px] md:mx-auto"><T uk = "Поповнити" en = "Replenish">Пополнить</T></button>} position="right center">
                        <Pay></Pay>
                    </Popup></span>
                      : <> {window.round(buytextQuery / (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2)}<T uk = " шт." en = " pcs."> шт.</T></>
                      }
                    </label>
                    </div>
                    :
                    <div className="flex flex-col gap-12 w-[30%] md:flex-row md:gap-7 md:w-full">
                      <label className="mx-auto text-xs text-white md:my-auto ">
                        <T uk = "Ви витратите:" en = "You will spend:">Вы потратите:</T>
                        {/* <CiCircleQuestion className="mr-[-15px] mt-[-5px]" size="25px"></CiCircleQuestion> */}
                      </label>
                      <img className="w-[60px] h-[60px] mx-auto " src = {BriefCase} alt="" />
                      <label className="mx-auto text-2xl md:text-lg md:my-auto whitespace-nowrap md:whitespace-normal">
                        { (window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2) > moneyBalance) ? <span className="text-sm"><T uk = "Недостатньо коштів" en = "Insufficient funds">Недостаточно средств</T><Popup modal className="testbl" trigger={<button className="ButtonColor md:text-sm AlternativeButtonText btn bg-primary md:w-[80px] w-[160px] absolute mt-[10px] text-l"><T uk = "Поповнити" en = "Replenish">Пополнить</T></button>} position="right center">
                        {close => (<Pay closePopUp={close}></Pay>)}
                    </Popup></span>
                        : <> {window.round(buytextQuery * (props.person.is_ipo ? props.person.purchase_price : props.person.listing_price), 2)} $</>
                        }
                      </label>
                    </div>
                    }
                  </div>
                  <div className="flex gap-4 md:mt-[-20px]">
                    <div className="bg-[#0ec40e] h-[50px] w-1/3 md:w-1/2">
                        <button className="btn h-full text-sm py-[17px]" onClick={buyShares}><T uk = "КУПИТИ" en = "BUY">КУПИТЬ</T></button>
                      </div>
                  </div>
                </div>
            </div>
            :
            <div className="h-[480px] w-[50%] md:w-full md:h-[480px]">
            <div className="flex h-[14%]">
            <div className="TransitionOff AboutBackround border-t-2 border-t-transparent w-1/3">
                  <button className="TransitionOff btn h-full text-sm py-[24px] AlternativeButtonText md:py-auto xs:py-[14px] whitespace-nowrap sm:whitespace-normal" onClick = {handleMenuChange}><T uk = "КУПИТИ АКЦІЇ" en = "BUY SHARES">КУПИТЬ АКЦИИ</T></button>
                </div>
                <div className="ConverterBuy TransitionOff border-t-2 w-1/3">
                  <button className="TransitionOff btn h-full text-sm py-[24px] md:py-auto xs:py-[14px] whitespace-nowrap sm:whitespace-normal"><T uk = "ПРОДАТИ АКЦІЇ" en = "SELL SHARES">ПРОДАТЬ АКЦИИ</T></button>
                </div>
                <div className="Background1 w-1/3"></div>
            </div>
            <div className="Background3 flex flex-col gap-[70px] p-10 pb-8 h-[412px] md:h-auto">
              <div className="flex gap-7 md:flex-col">
                <div className="flex flex-col gap-7 w-[60%] md:w-full">
                <div className="flex">
                <label htmlFor="currencyinput" className="flex flex-col gap-4 text-xs text-white relative w-[100%] whitespace-nowrap">
                      <T uk = "Мій баланс" en = "My balance">Мой баланс</T>
                      <div className="flex gap-5">
                    <div
                        className={`p-3 pl-0 text-white text-2xl outline-none w-[50%]`}
                    >
                      <span>{window.round(sharesBalance, 2)}</span> <span className={`text-lightGray`}>{tokenName}</span>
                    </div>
                    </div>
                    </label>

                        <label htmlFor="currencyinput" className="flex flex-col gap-4 text-xs text-white relative w-[100%] whitespace-nowrap">
                          <T uk = "Ціна за акцію" en = "Price per share">Цена за акцию</T>
                          <div className="flex gap-5">
                        <div
                            className={`p-3 pl-0 text-white text-2xl outline-none w-[50%]`}
                        >
                          <span className={`text-lightGray`}>$</span> <span>{round(tokenCost)}</span>
                        </div>
                        </div>
                        </label>
                      </div>
                  <div className="flex gap-5">
                    <label htmlFor="suminput" className="flex flex-col gap-4 text-xs text-white relative w-full whitespace-nowrap">
                      <T uk = "Хочу продати" en = "I want to sell">Хочу продать</T>
                    <div className="flex gap-2">
                    <input
                        className={`bg-transparent p-3 text-white border-2 border-gray outline-none w-[50%]`}
                        onChange={handleSellTextChange}
                        onKeyUp={sellProcessChange}
                        onInput={handleInput}
                    />
                    <div
                        className={`p-3 pl-0 text-white text-2xl outline-none w-[50%]`}
                    >
                      <span className={`text-lightGray`}>{tokenName}</span>
                    </div>
                    </div>
                    </label>
                  </div>
                </div>
                <div className="w-[1px] bg-gray md:h-[1px] md:w-full"></div>
                <div className="flex flex-col gap-12 w-[30%] md:flex-row md:gap-7 md:w-full">
                  <label className="mx-auto text-xs text-white md:my-auto ">
                    <T uk = "Ви отримаєте:" en = "You will receive:">Вы получите:</T>
                    {/* <CiCircleQuestion className="mr-[-15px] mt-[-5px]" size="25px"></CiCircleQuestion> */}
                  </label>
                  <img className="w-[60px] mx-auto " src = {BriefCase} alt="" />
                  <label className="mx-auto text-2xl md:text-lg md:my-auto">
                    { (selltextQuery > sharesBalance) ? <span className="text-sm"><T uk = "Недостатньо акцій" en = "Insufficient shares">Недостаточно акций</T></span>
                        : <>{round(selltextQuery * tokenCost, 2)} $</>
                    }
                  </label>
                </div>
              </div>
              <div className="flex gap-4 md:mt-[-20px]">
                <div className="bg-[#E32636] h-[50px] w-1/3 md:w-1/2">
                    <button className="btn h-full text-sm py-[17px]" onClick={sellShares}><T uk = "ПРОДАТИ" en = "SELL">ПРОДАТЬ</T></button>
                  </div>
              </div>
            </div>
        </div>
            }
        </div>
      </div>
    );
};
export default Converter;
