import React from "react";

import {useState, useEffect, useMemo} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import searchimg from "../assets/img/search.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from './LineChart';
import BloggersList from './BloggersList';
import Select, { AriaOnFocus } from 'react-select';
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from "react-chartjs-2";
import BriefCase from "../assets/img/briefcase.png";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CiCircleQuestion } from "react-icons/ci";

const Timer = (props) => {


  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isSearchable] = useState(false);

  // const deadline = "August, 30, 2023, 20:00:00 GMT";
  const deadline = props.person.listing_date

  const getTime = () => {
    const time = Date.parse(deadline) >= Date.now() ? Date.parse(deadline) - Date.now() : 0;

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      //Если стадия размещения ещё не наступила
      if (new Date(props.person.listing_date).getTime() >= Date.now()) {
        getTime(props.person.listing_date)
      } else {
        //Отключить таймер
        console.log("clearInterval")
        clearInterval(interval)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);


function saveInput(){
  console.log('Saving data');
}


    return ( <>
              <div className="timer flex place-content-center text-center mt-[10px]" role="timer">
                <div className="col-4">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] sm:text-[15px] ">
                    <p id="day">{days < 10 ? "0" + days : days}</p>
                    <span className="text"><T uk = "дн" en = "d">дн</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] sm:text-[15px] ">
                    <p id="hour">{hours < 10 ? "0" + hours : hours}</p>
                    <span className="text"><T uk = "год" en = "hr">ч</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] sm:text-[15px] ">
                    <p id="minute">{minutes < 10 ? "0" + minutes : minutes}</p>
                    <span className="text"><T uk = "хв" en = "min">мин</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] sm:text-[15px] ">
                    <p id="second">{seconds < 10 ? "0" + seconds : seconds}</p>
                    <span className="text"><T uk = "сек" en = "sec">сек</T></span>
                  </div>
                </div>
              </div>
              </>
    );
};
export default Timer;
