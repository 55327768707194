import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../assets/css/swiper-personalities.css";

import PersonalitiesMain from "../assets/img/persmain.png";

const SwiperPersonalities = () => {
  const SwiperPers = [
    {
      img: PersonalitiesMain,
      title: "Поддержи своего любимого блогера, спортсмена, музыканта",
    },
    {
      img: PersonalitiesMain,
      title: "Поддержи своего любимого блогера, спортсмена, музыканта",
    },
    {
      img: PersonalitiesMain,
      title: "Поддержи своего любимого блогера, спортсмена, музыканта",
    },
    {
      img: PersonalitiesMain,
      title: "Поддержи своего любимого блогера, спортсмена, музыканта",
    },
  ];
  return (
    <section className="mb-10">
      <div className="container">
        <div className="flex items-center w-full">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            grabCursor={true}
            navigation={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            className="swiper-pers"
          >
            {SwiperPers.map((item, index) => (
                <SwiperSlide key={index}>
                    <img className="w-[1240px] h-[360px] object-cover" src={item.img} alt={item.title} />
                <div className="text-[32px] max-w-[400px] font-semibold absolute md:top-[10%] top-[40%]">
                  <div>{item.title}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default SwiperPersonalities;
