import React from "react";
import {useRef} from 'react';
import * as R from 'ramda'
import {useState, useEffect, useContext} from "react";
import { Link, useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Manipulation } from "swiper";
import { useSwiper, useSwiperSlide } from "swiper/react";
import Fade from 'react-reveal/Fade';
import "swiper/css";
import "swiper/css/navigation";
import "../../assets/css/swiper-blog.css";

import { LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,  TelegramShareButton,  TelegramIcon, ViberShareButton, ViberIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';

import ArrowRightImg from "../../assets/img/arrow-right.png";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import LineChart from '../../components/LineChart';
import Biography from "../../components/Biography";
import Offers from "../../components/Offers";
import LastActivity from "../../components/LastActivity";
import Converter from "../../components/Converter";
import Comments from "../../components/Comments";
import Stock from "../../components/Stock";
import RegistrationPage from "../../pages/Registration"
import Registration from "../../components/Registration"

import { HiVideoCamera } from "react-icons/hi";

import YoutubeIconImg from "../../assets/img/bloggerbuy/youtube-logo.png";
import TwitterIconImg from "../../assets/img/bloggerbuy/twitter-logo.png";
import TikTokIconImg from "../../assets/img/bloggerbuy/tiktok-logo.png";
import InstagramIconImg from "../../assets/img/bloggerbuy/instagram-logo.png";
import Popup from 'reactjs-popup';
import Singin from "../../pages/Singin";

import { Helmet } from 'react-helmet';

import {LoginContext} from "../../App.js"

window.round = (x, n) => parseFloat(parseFloat(x).toFixed(n))


const Bloggerbuy = () => {

  useEffect(() => {
    const scrollToIfNeeded = () => {
      const params = new URLSearchParams(window.location.search);
      console.log(params);
      if (params.get('scrollTo')) {
        const elementId = params.get('scrollTo');
        if (elementId != `option-video`) {
          const observer = new MutationObserver((mutations, observer) => {
            const element1 = document.getElementById(`offers-container`);
            const element2 = document.getElementById(elementId);
      
            if (element2) {
              element1.scrollIntoView();
              element2.click();
              observer.disconnect();
            }

          });
      
          observer.observe(document, { childList: true, subtree: true });
        }else{
          const observer = new MutationObserver((mutations, observer) => {
            const element1 = document.getElementById(`video-container`);
            const element2 = document.getElementById(elementId);
      
            if (element2) {
              element1.scrollIntoView();
              observer.disconnect();
            }

          });
      
          observer.observe(document, { childList: true, subtree: true });
        }
      }
    }
  
    scrollToIfNeeded();
  }, []);

  const [buySellSwitch, setBuySellSwitch] = useState(true);

  const ref = useRef(null);

  const handleClickbuy = () => {
    if (isLoggedIn) {
      setBuySellSwitch(true);
      ref.current?.scrollIntoView({behavior: 'smooth'});
      () => props.sellbuy();
    } else {
      //Показать pop-up для авторизации
      setOpen(true)
    }

  };
  const handleClicksell = () => {
    if (isLoggedIn) {
      setBuySellSwitch(false);
      ref.current?.scrollIntoView({behavior: 'smooth'});
    } else {
      //Показать pop-up для авторизации
      setOpen(true)
    }

  };
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  //true - логин, false - регистрация
  const [popupLoginToggle, setPopupLoginToggle] = useState(true);

  //Нужно для рендеринга страницы вручную
  const [rerender, setRerender] = useState(false);
  const rer = () => setRerender(x=> !x)

  //
  const didMount = React.useRef(false);

  //Pop-up для авторизации (открыть/закрыть)
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    rer(); //Отрисовать страницу заново
  };

  const deadline = "August, 30, 2023, 20:00:00 GMT";

  const getTime = () => {
    const time = Date.parse(person.listing_date) >= Date.now() ? Date.parse(person.listing_date) - Date.now() : 0;

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  // useEffect(() => {
  //   const interval = setInterval(() => getTime(deadline), 1000);
  //
  //   return () => clearInterval(interval);
  // }, []);

  const [num, setNum] = useState(0);

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);
  const {id} = useParams();
  const [person, setPerson] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentUserPersonID, setCurrentUserPersonID] = useState()

  useEffect( () => {
    const fetchData = async () => {
      let currentUserResponse = await fetch(`https://${window.location.host}/api/current-user`, {
        method: 'GET',
      })
      if (currentUserResponse.ok) {
        console.log('User is logged in...')
        let asf = await currentUserResponse.json()
        setCurrentUserPersonID(asf.person)
        console.log(currentUserPersonID)
      } else {
        console.log('User is NOT logged in...')
        setIsLoggedIn(false)
      }

      const response = await directus.items('Person').readOne(id, {fields: ['*', 'activity.directus_files_id', 'category.person_category_id.name', 'offers.offer_id.*', 'last_activity.*', 'last_activity.user.head_photo', 'last_activity.user.first_name', 'last_activity.user.last_name', 'last_activity.user.nickname', 'last_activity.user.name'], deep: {"last_activity": {"_sort": "-date_created", "_limit": 10}}});
      const response2 = ((await directus.items('Instrss').readByQuery({filter: {iduser: {'_eq': id}}, fields: ['id', 'image.filename_disk, title, urlpost']})).data).reverse();
      response.instRSSContent = response2;
      response.act = response2.map(item => `https://directus.${window.location.host}/assets/${item.image.filename_disk}`);
      response.act2 = response2.map(title => `${title.title}`);
      response.act3 = response2.map(urlpost => `${urlpost.urlpost}`);
      response.sm = [];
      let sm = [
        {name: "instagram", link: response.Instagram, logo: InstagramIconImg},
        {name: "tiktok", link: response.tiktok, logo: TikTokIconImg},
        {name: "twitter", link: response.twitter, logo: TwitterIconImg},
        {name: "youtube", link: response.youtube, logo: YoutubeIconImg},
      ];
      sm.forEach((item, i) => {
        if (item.link) response.sm.push(item);
      });
      response.offers.reverse();
      setPerson(response);
      setIsLoaded(x => true);
      //console.log(response.act2)
    }
    fetchData();
    setNum(randomNumberInRange(300, 898));
  }, [rerender])


  useEffect(() => {
    //Если компонент уже отрисован (не запускается при первоначальной отрисовке)
    if (didMount.current) {
      const interval = setInterval(() => {
        //Если стадия размещения ещё не наступила
        if (new Date(person.listing_date).getTime() >= Date.now()) {
          getTime(person.listing_date)
        } else {
          if (person.is_ipo) {
            //Ждать пока в person.is_ipo не станет false в directus
            setRerender(r => !r);
          } else {
            //Отключить таймер
            console.log("clearInterval")
            clearInterval(interval)
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      //Запускается только когда компонент отрисовывается в первый раз
      didMount.current = true
    }
  }, [isLoaded, person]);

  //Применить сохраненную тему личности
  useEffect( () => {
    const getTheme = theme => {
      if(theme == "Dark"){
        return "theme-dark"
      }
      if(theme == "Light") {
        return "theme-light"
      } if(theme == "Blue") {
        return "theme-blue"
      } if(theme == "Pink") {
        return "theme-pink"
      } if(theme == "Green") {
        return "theme-green"
      }if(theme == "Red") {
        return "theme-red"
      }if(theme == "Orange") {
        return "theme-orange"
      }if(theme == "Purple") {
        return "theme-purple"
      }if(theme == "Yellow") {
        return "theme-yellow"
      }if(theme == "Pink") {
        return "theme-pink"
      }
    }

    const themes = ["theme-dark","theme-light","theme-blue","theme-green","theme-red","theme-orange","theme-purple","theme-yellow","theme-pink"];
    const savedTheme = JSON.parse(window.localStorage.getItem('Current_theme'));
    //Применить пользоваельскую тему для этой страницы, если она указана в настройках
    if (person.theme) {
      document.querySelector('body').classList.remove(...themes);
      document.querySelector('body').classList.add(getTheme(person.theme));
      window.localStorage.setItem('preventThemeChange', true);
    }

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // is mobile..
      const videos = document.getElementsByTagName('video');

      for(let i = 0; i < videos.length; i++) {
        videos[i].setAttribute("autoplay", "autoplay");
        videos[i].setAttribute("playsinline", "playsinline");
      }
    }

    return () => {
      //Если тема страницы отличается от темы сайта, то при выходе из страницы вернуть тему сайта обратно
      if (person.theme) {
        window.localStorage.setItem('preventThemeChange', false)
        document.querySelector('body').classList.remove(...themes);
        document.querySelector('body').classList.add(getTheme(JSON.parse(window.localStorage.getItem('Current_theme')).label));
      }
    }
  }, [isLoaded]);

  function getStockData(personName) {
    const data = JSON.parse(localStorage.getItem('stockData'));
    return data[personName];
  }

  function shortenText(text) {
    if (text.length > 120) {
      return text.substring(0, 140) + "...";
    } else {
      return text;
    }
  }

  // if (isLoaded) console.log(person);
  if( !isLoaded ) {
    return (<></>)
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy and sell personal shares of public persons.</title>
        <meta name="description" content={shortenText(person.biography)} />

        <meta property="og:title" content={person.name || person.first_name + ' ' + person.last_name} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://personalshares.com/bloggerbuy/${person.id}`} />
        <meta property="og:image" content= {`https://directus.${window.location.host}/assets/${person.head_photo}`}  />
        <meta property="og:description" content={shortenText(person.biography)} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@personalshares" />
        <meta name="twitter:title" content={person.name || person.first_name + ' ' + person.last_name} />
        <meta name="twitter:description" content={shortenText(person.biography)} />
        <meta name="twitter:image" content={`https://directus.${window.location.host}/assets/${person.head_photo}`} />
      </Helmet>
    <Popup open={open} onClose={closeModal} nested modal className="testbl">
        {close => (
            <div className="modal">
                <div className="content">
                    {popupLoginToggle ? <Singin redirect={window.location.pathname} togglePopUp={() => setPopupLoginToggle(x => !x)} buyButtonPopUp closePopUp={closeModal}/> : <RegistrationPage redirect={window.location.pathname} togglePopUp={() => setPopupLoginToggle(x => !x)} buyButtonPopUp closePopUp={closeModal}/>}
                </div>
            </div>
        )}
    </Popup>
      <div className="container py-10 sm:py-[30px] fadeIn">
        <div
          className="h-[240px] w-full flex bg-cover bg-center bg-no-repeat items-end"
          style={{ backgroundImage: `url(https://directus.${window.location.host}/assets/${person.background})` }}
        >
          <div className="flex items-center justify-between px-[60px] bg-darkGray/60 h-[60px] w-full md:px-5 md:h-auto md:py-3">
            <div className="flex items-center">
              <img
                className="w-[200px] h-[200px] mr-10 md:w-[100px] md:h-[100px] md:mr-4"
                src={`https://directus.${window.location.host}/assets/${person.head_photo}`}
                alt=""
              />
              <div className="flex items-center sm:flex-col md:gap-4 sm:items-start sm:gap-2">
                {/*<img className="w-10 h-10 mr-4" src={MainIconImg} alt="" />
                <h3 className="text-lg font-semibold mr-[52px] md:mr-5">
                  @ScrooodGE
                </h3>*/}
                <div className="flex">
                  {person.sm.map((social, index) => (
                    <a key={index} href={social.link} target="_blank">
                      <img src={social.logo} className="h-[50px] w-[50px]" alt="" />
                    </a>
                  ))}
                </div>
                {/* <a
                  href="#"
                  className="flex items-center gap-3 w-[140px] py-[5px] bg-white/90 text-sm font-medium justify-center text-black"
                >
                  <HiVideoCamera /> Про автора
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {person.is_ipo ?         <div className="Background3 bg-darkGray pt-6 pb-10">
          <div className="flex justify-between pl-[300px] pr-[60px] mb-10 md:pl-5 sm:flex-col sm:text-center sm:pl-0 sm:pr-[0px]">
            <div className="flex flex-col sm:gap-3">
              <h2 className="font-semibold text-4xl mb-1">{person.name || person.first_name + ' ' + person.last_name}</h2>
              <p className="text-xs sm:mb-5">
                  <T uk = "Категорія:" en = "Category:">Категория:</T>{" "}
                <span className="MainText text-white/50">
                  <T>{person.category.map(item => item.person_category_id.name).join(', ')}</T>
                </span>
              </p>
            </div>
            <div className="w-[330px] md:w-[300px] sm:w-auto">
            <h2 className="font-roboto text-center text-xs text-color-theme text-lightGray">
              <T uk = "Стадія розміщення починається через" en = "The placement stage starts after:">Стадия размещения начинается через:</T>
              </h2>
              <div className="timer flex place-content-center text-center mt-[10px]" role="timer">
                <div className="col-4">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] md:text-[15px]">
                    <p id="day">{days < 10 ? "0" + days : days}</p>
                    <span className="text"><T uk = "дн" en = "d">дн</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] md:text-[15px]">
                    <p id="hour">{hours < 10 ? "0" + hours : hours}</p>
                    <span className="text"><T uk = "год" en = "hr">ч</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] md:text-[15px]">
                    <p id="minute">{minutes < 10 ? "0" + minutes : minutes}</p>
                    <span className="text"><T uk = "хв" en = "min">мин</T></span>
                  </div>
                </div>
                <div className="col-4 ml-[10px]">
                  <div className="box ButtonText px-5 sm:px-3 py-1 bg-danger rounded-md font-bold text-[20px] md:text-[15px]">
                    <p id="second">{seconds < 10 ? "0" + seconds : seconds}</p>
                    <span className="text"><T uk = "сек" en = "sec">сек</T></span>
                  </div>
                </div>
              </div>
          </div>
          </div>
          <div className="h-[1px] bg-white/10 mx-auto w-[calc(100%_-_120px)] mb-10 md:w-[calc(100%_-_40px)]"></div>
          <div className="flex justify-between px-[60px] lg:flex-col lg:gap-5 md:px-5">
            <div className="flex gap-12 md:justify-between sm:flex-wrap">
              <div
                className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
              >
                 <h2 className="text-color-theme text-lightGray text-xs">
                <T uk = "Ціна зараз:" en = "Price now:">Цена сейчас:</T>
              </h2>
                <div className="flex items-start">

              <h2 className="line-through text-[22px] font-semibold mt-[15px] text-[#DC143C]">
                {person.listing_price} $
              </h2>
              <h2 className="text-[22px] font-semibold ml-[10px] mt-[15px] text-[#32CD32]">
                {person.purchase_price} $
              </h2>
              <span className="ButtonText ml-[5px] bg-danger rounded-md font-bold text-[20px] p-1">
                -{Math.round(100-(person.purchase_price*100)/person.listing_price, 2)}%
              </span>
              </div>
              </div>
            </div>
            <div className="flex gap-12 md:justify-between sm:flex-wrap">
              <div
                className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
              >
                 <h2 className="text-color-theme text-lightGray text-xs">
                <T uk = "Ціна після лістингу:" en = "Price after listing:">Цена после листинга:</T>
              </h2>
                 <div className="flex">

              <h2 className="text-[22px] font-semibold mt-[15px] text-[#32CD32]">
                {person.listing_price} $
              </h2>
              </div>
              </div>
            </div>
            <div className="flex gap-12 md:justify-between sm:flex-wrap">
              <div
                className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs">
                  <T uk = "К-сть акцій:" en = "Number of shares:">К-во акций:</T>
                </h4>
                <h3 className="font-semibold text-xl mt-[15px]">{person.token_amount}</h3>
              </div>
            </div>
            <div className="flex gap-12 md:justify-between sm:flex-wrap">
              <div
                className="flex flex-col gap-[10px] border-l border-lightGray/10 pl-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs">
                  <T uk = "Продано:" en = "Sold:">Продано:</T>
                </h4>
                <h3 className="font-semibold text-xl mt-[15px]">{person.token_sold}/{person.token_amount}</h3>
              </div>
            </div>
            <div className="flex items-center gap-4">
            <button onClick={handleClickbuy} className="ButtonText btn btn-green bg-[#0ec40e] w-[160px] text-xs">
                <T uk = "Купити акції" en = "Buy shares">Купить акции</T>
              </button>
              {person.is_ipo ? <></>:<button onClick={handleClicksell} className="ButtonText btn bg-[#E32636] btn-red w-[160px] text-xs">
                {" "}
                <T uk = "Продати акції" en = "Sell shares">Продать акции</T>
              </button>}

            </div>
          </div>
          <div className="flex place-content-end mt-[30px] mr-[60px] sm:mr-[20px] sm:flex-wrap ">
              <div className="font-semibold mr-[5px] mt-[4px]"><T uk = "Поширити:" en = "Share:">Поделиться:</T></div>
              <div className="place-content-center mr-[5px]">
              <FacebookShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                quote={`Торгуй вместе со мной на PersonalShares`}
                hashtag="#personalshares"
              >
                <FacebookIcon size={32} round></FacebookIcon>
              </FacebookShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <TwitterShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                quote={`Торгуй вместе со мной на PersonalShares`}
                hashtag="#personalshares"
              >
                <TwitterIcon size={32} round></TwitterIcon>
              </TwitterShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <ViberShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <ViberIcon size={32} round></ViberIcon>
              </ViberShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <WhatsappShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <WhatsappIcon size={32} round></WhatsappIcon>
              </WhatsappShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <RedditShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <RedditIcon size={32} round></RedditIcon>
              </RedditShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <LinkedinShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
                source="PersonalShares"
              >
                <LinkedinIcon size={32} round></LinkedinIcon>
              </LinkedinShareButton>
              </div>
              <div className="place-content-center">
              <TelegramShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <TelegramIcon size={32} round></TelegramIcon>
              </TelegramShareButton>
              </div>
            </div>
        </div>
        :         <div className="Background3 bg-darkGray pt-6 pb-[17px]">
        <div className="flex justify-between pl-[300px] pr-[60px] mb-[26px] md:pl-5 sm:flex-col sm:text-center sm:pr-0">
          <div className="flex flex-col justify-center sm:gap-3">
            <h2 className="font-semibold text-4xl mb-1">{person.name || person.first_name + ' ' + person.last_name}</h2>
            <p className="text-xs sm:mb-5">
                <T uk = "Категорія:" en = "Category:">Категория:</T>{" "}
              <span className="MainText text-white/50">
                <T>{person.category.map(item => item.person_category_id.name).join(', ')}</T>
              </span>
            </p>
          </div>
          <div className="w-[330px] md:w-[300px]">
            <LineChart className="mb-5"
            height = {100}
            backgroundColor = {"#313132"}
            lineColor = {"#0ec40e"}
            altLineColor = {"#E32636"}
            chartData = {getStockData(person.name || person.first_name + ' ' + person.last_name)}/>
          </div>
        </div>
        <div className="h-[1px] bg-white/10 mx-auto w-[calc(100%_-_120px)] mb-10 md:w-[calc(100%_-_40px)]"></div>
        <div className="flex justify-between px-[60px] lg:flex-col lg:gap-5 md:px-5">
          <div className="flex justify-between w-full lg:flex-col lg:gap-5 md:px-5 mr-[60px] mb-[21px]">
            <div className="flex gap-12 md:justify-between md:mx-auto sm:flex-wrap">
              <div
                className="flex flex-col justify-center gap-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs text-center">
                  <T uk = "Капіталізація:" en = "Capitalization:">Капитализация:</T>
                </h4>
                <h3 className="font-semibold text-xl text-center">$ {round(person.listing_price * person.token_sold, 2)}</h3>
              </div>
            </div>
            <div className="border-l border-lightGray/10"></div>
            <div className="flex gap-12 md:justify-between md:mx-auto sm:flex-wrap">
              <div
                className="flex flex-col justify-center gap-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs text-center">
                  <T uk = "К-сть акцій:" en = "Number of shares:">К-во акций:</T>
                </h4>
                <h3 className="font-semibold text-xl text-center">{round(person.token_amount - person.token_sold, 2)}</h3>
              </div>
            </div>
            <div className="border-l border-lightGray/10"></div>
            <div className="flex gap-12 justify-center md:justify-between md:mx-auto sm:flex-wrap">
              <div
                className="flex flex-col justify-center gap-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs text-center">
                  <T uk = "Ціна за акцію:" en = "Price per share:">Цена за акцию:</T>
                </h4>
                <h3 className="font-semibold text-xl text-center">$ {round(person.listing_price, 2)}</h3>
              </div>
            </div>
            <div className="border-l border-lightGray/10"></div>
            <div className="flex gap-12 justify-center md:justify-between md:mx-auto sm:flex-wrap">
              <div
                className="flex flex-col justify-center gap-[10px]"
              >
                <h4 className="text-color-theme text-lightGray text-xs text-center">
                  <T uk = "Акцій продано, %:" en = "Shares sold, %:">Акций продано, %:</T>
                </h4>
                <h3 className="font-semibold text-xl text-center">{round(100 / person.token_amount * person.token_sold, 2)}%</h3>
              </div>
            </div>
          </div>
          <div className="flex-col gap-4">
            <div className="flex justify-center gap-4">
              <button onClick={handleClickbuy} className="ButtonText btn bg-[#0ec40e] btn-green w-[160px] text-xs">
                <T uk = "Купити акції" en = "Buy shares">Купить акции</T>
              </button>
              <button onClick={handleClicksell} className="ButtonText btn btn-red bg-[#E32636] w-[160px] text-xs">
              {" "}
                <T uk = "Продати акції" en = "Sell shares">Продать акции</T>
              </button>
            </div>
            <div className="flex place-content-end mt-[20px] mx-auto sm:mr-[20px] sm:flex-wrap">
              <div className="font-semibold mr-[5px] mt-[4px]"><T uk = "Поширити:" en = "Share:">Поделиться:</T></div>
              <div className="place-content-center mr-[5px]">
              <FacebookShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                quote={`Торгуй вместе со мной на PersonalShares`}
                hashtag="#personalshares"
              >
                <FacebookIcon size={32} round></FacebookIcon>
              </FacebookShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <TwitterShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                quote={`Торгуй вместе со мной на PersonalShares`}
                hashtag="#personalshares"
              >
                <TwitterIcon size={32} round></TwitterIcon>
              </TwitterShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <ViberShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <ViberIcon size={32} round></ViberIcon>
              </ViberShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <WhatsappShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <WhatsappIcon size={32} round></WhatsappIcon>
              </WhatsappShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <RedditShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <RedditIcon size={32} round></RedditIcon>
              </RedditShareButton>
              </div>
              <div className="place-content-center mr-[5px]">
              <LinkedinShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
                source="PersonalShares"
              >
                <LinkedinIcon size={32} round></LinkedinIcon>
              </LinkedinShareButton>
              </div>
              <div className="place-content-center">
              <TelegramShareButton
                url={`https://personalshares.com/bloggerbuy/${person.id}`}
                title={`Торгуй вместе со мной на PersonalShares`}
              >
                <TelegramIcon size={32} round></TelegramIcon>
              </TelegramShareButton>
              </div>
          </div>
          </div>
        </div>
      </div>}
        <section className="py-[40px] md:py-[40px] sm:py-[20px]">
        {person.video_message && (
          <section className="py-[20px] md:py-[20px] sm:py-[20px] max-width:80vw max-height:80vh" id="video-container">
            <h2 className="font-semibold text-3xl text-center mb-[50px]">
              <T uk = "Моє відеопредставлення" en = "My video representation">Моё видеопредставление</T>
            </h2>
            <video
              className="VideoInput_video col-span-4 max-w-[80vw] mx-auto max-h-[80vh]"
              id="option-video"
              controls
              onMouseOver={event => {
                let isMouseOver = true;
                
                event.target.onmouseout = () => {
                  isMouseOver = false;
                  event.target.pause();
                  event.target.currentTime = 0;
                };
            
                if (event.target.readyState > 2) {
                  event.target.play();
                } else {
                  event.target.oncanplaythrough = () => {
                    if (isMouseOver) {
                      event.target.play();
                    }
                  };
                }
              }}
              muted='muted'
              src={`https://directus.${window.location.host}/assets/${person.video_message}`}
            />
            <div className="col-span-4 max-w-[80vw] mx-auto max-h-[80vh] w-fit">
              <div className="flex place-content-end mt-[20px] mx-auto sm:mr-[20px] sm:flex-wrap">
                <div className="font-semibold mr-[5px] mt-[4px]"><T uk = "Поширити:" en = "Share:">Поделиться:</T></div>
                <div className="place-content-center mr-[5px]">
                <FacebookShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  quote={`Торгуй вместе со мной на PersonalShares`}
                  hashtag="#personalshares"
                >
                  <FacebookIcon size={32} round></FacebookIcon>
                </FacebookShareButton>
                </div>
                <div className="place-content-center mr-[5px]">
                <TwitterShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  quote={`Торгуй вместе со мной на PersonalShares`}
                  hashtag="#personalshares"
                >
                  <TwitterIcon size={32} round></TwitterIcon>
                </TwitterShareButton>
                </div>
                <div className="place-content-center mr-[5px]">
                <ViberShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  title={`Торгуй вместе со мной на PersonalShares`}
                >
                  <ViberIcon size={32} round></ViberIcon>
                </ViberShareButton>
                </div>
                <div className="place-content-center mr-[5px]">
                <WhatsappShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  title={`Торгуй вместе со мной на PersonalShares`}
                >
                  <WhatsappIcon size={32} round></WhatsappIcon>
                </WhatsappShareButton>
                </div>
                <div className="place-content-center mr-[5px]">
                <RedditShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  title={`Торгуй вместе со мной на PersonalShares`}
                >
                  <RedditIcon size={32} round></RedditIcon>
                </RedditShareButton>
                </div>
                <div className="place-content-center mr-[5px]">
                <LinkedinShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  title={`Торгуй вместе со мной на PersonalShares`}
                  source="PersonalShares"
                >
                  <LinkedinIcon size={32} round></LinkedinIcon>
                </LinkedinShareButton>
                </div>
                <div className="place-content-center">
                <TelegramShareButton
                  url={`https://personalshares.com/bloggerbuy/${person.id}?scrollTo=option-video`}
                  title={`Торгуй вместе со мной на PersonalShares`}
                >
                  <TelegramIcon size={32} round></TelegramIcon>
                </TelegramShareButton>
                </div>
              </div>
            </div>
          </section>
        )}
      {person.biography && <Fade bottom><Biography content={person.biography}/></Fade>}
      <Fade bottom><div className="container">
        <h2 className="font-semibold text-3xl mt-[30px] mb-[60px]">
          <T uk = "Активність " en = "Activity ">Активность </T>
          {person.name || person.first_name + ' ' + person.last_name}:
        </h2>
        <Swiper
          breakpoints={{
            "@0.75": {
              slidesPerView: 2.3,
              spaceBetween: 15,
            },
            "@1.00": {
              slidesPerView: 4.2,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 4.2,
              spaceBetween: 16,
            },
          }}
          slidesPerView={1.3}
          spaceBetween={16}
          grabCursor={true}
          navigation={true}
          modules={[Navigation, Manipulation]}
          className="swiper-blog"
        >
          <div>
          {person.act.map((item, index) =>  {
            const filetype = R.head(R.filter(x => x, R.values(R.mapObjIndexed((pattern, type) => R.test(pattern, item) && type, {'image': /jpg$|jpeg$|png$/, 'video': /avi$|mpeg$|mp4$/ }))))

            let element = {
            image: <img className="object-cover w-[231px] h-[380px]" src={item}/>,
            video: <video controls onMouseOver={event => event.target.play()} onMouseOut={event => {event.target.pause(); event.target.currentTime = 0;}} muted='muted' loop className="object-cover w-[231px] h-[380px]"><source src={item}/></video>
            }[filetype]

            return <SwiperSlide key={index}>
              <div className="flex flex-col">

                {element}

               <div className="columns-1 w-[231px]">
                  <h2 className="text-left text-[14px] font-roboto opacity-80">
                  <a href={`${person.act3[index]}`} target="_blank" className="flex relative">{person.act2[index]}</a>
                  </h2>
                </div>
               </div>
             </SwiperSlide>
           })}
          </div>
        </Swiper>
      </div></Fade>
      {(person.offers.length != 0) && <Fade bottom><Offers offers={person.offers.map( x => x.offer_id)} isUserpage={false} personId = {person.id} name={person.name || person.first_name + ' ' + person.last_name}/></Fade>}
      <Fade bottom><Stock /></Fade>
      {(isLoggedIn) && <div ref={ref} id="converter"><Fade bottom><Converter currentUserPersonID={currentUserPersonID} fr={rer} buySellSwitch={buySellSwitch} person={person} image = {person.head_photo} is_ipo = {person.is_ipo} name = {person.name || person.first_name + ' ' + person.last_name}/></Fade></div>}
      {(person.last_activity.length != 0) && <Fade bottom><LastActivity activity={person.last_activity}/></Fade>}
      <Fade bottom><Comments /></Fade>
      {!isLoggedIn && <Fade bottom><Registration /></Fade>}
    </section>
      </div>
    </>
  );
};
export default Bloggerbuy;
