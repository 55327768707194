import React, { useState } from 'react';
import {useStore} from './Store';
import Select from "react-select";

const Lang = lang => props => {
  const language = useStore(x => x.language.language)
  if (language != lang) return <></>
  return <>{props.children}</>
}

window.Ru = Lang('ru')
window.Uk = Lang('uk')
window.En = Lang('en')

const options = [
  { label: "RU (USD)", value: 'ru' },
  { label: "UA (USD)", value: 'uk' },
  { label: "EN (USD)", value: 'en' },
];

function removeEdgeQuotes(text) {
  return text.replace(/^"(.*)"$/, '$1');
}

const getTranslation = async (text,original,to) =>{

  const sentences = text.match(/[^.!?]+[.!?]+/g) || [text]; 

  const translations = await Promise.all(sentences.map(async (sentence) => {
    const key = `${sentence.trim()}_${original}_${to}`;
    
    const cachedResult = localStorage.getItem(key);
    if (cachedResult) {
      return cachedResult;
    }
  
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
  
    try {
      let result = await fetch(`https://${window.location.host}/api/translate/text/${encodeURIComponent(sentence.trim())}/${original}-${to}`, requestOptions)
      .then(response => response.text());
  
      if(!result.includes("504 Gateway") && !result.includes("502 Bad Gateway") && !result.includes("translation error") && !result.includes("400 Bad Request") && !result.includes("<title>Error</title>")){
        localStorage.setItem(key, result);
      }
  
      return result;
    } catch (error) {
      console.log('error', error);
      return "translation error";
    }
  }));

  return translations.map(removeEdgeQuotes).join(' ');
}

const getLanguage = language => {
  switch (language) {
    case "ru":
      return "rus_Cyrl";
    case "uk":
      return "ukr_Cyrl";
    case "en":
      return "eng_Latn";
  }
}

const ongoingRequests = new Set();

window.T = x => {
  const [language, translations] = useStore(x => [x.language.language, x.translations[x.language.language]]);
  const ru = x.children
  if(ru == undefined) return <span className="fadeIn" key={language}></span>;
  if (language == 'ru') return <span className="fadeIn" key={language}>{ru}</span>;
  if (x[language] && !x[language].includes("504 Gateway Time-out")) return <span className="fadeIn" key={language}>{x[language]}</span>;
  if (translations[ru] && !translations[ru].includes("504 Gateway Time-out")){
    return <span className="fadeIn" key={language}>{translations[ru]}</span>
  }
  if (ru && ru.includes(", ")) {
    const arr = ru.split(", ");
    if (translations[arr[0]] && translations[arr[1]]) {
      return <span className="fadeIn" key={language}>{arr.map(item => translations[item]).join(', ')}</span>;
    }
  }

  if (x[language] || translations[ru] ) {
    if (translations[ru].includes("504 Gateway Time-out") || x[language].includes("504 Gateway Time-out")){
      return <span className="fadeIn" key={language}>{ru}</span>
    }
  }

  const requestKey = `${ru}_${getLanguage(language)}`;

  if (!ongoingRequests.has(requestKey)) {
    ongoingRequests.add(requestKey);

  getTranslation(ru,"rus_Cyrl",getLanguage(language))
    .then(result => {
      if(result.includes("502 Bad Gateway" || "504 Gateway Time-out" || "400 Bad Request" || "translation error" || "<title>Error</title>")) {
      (SET({translations: {[language]: {[ru]: ru}}}))
    }else{
      (SET({translations: {[language]: {[ru]: removeEdgeQuotes(result)}}}))
    }
  }).catch(error => console.log(ru, error))
    .finally(() => {
        ongoingRequests.delete(requestKey);
      });
  }

  return <></>;
}

export const LanguageSwitcher = (props) => {
  const {change, language} = useStore(x => x.language)
  const [isSearchable] = useState(false);


  return <Select
    onChange={x => change(x.value)}
    value={options.find(x => x.value == language)}
    styles={props.customStyles}
    options={options}
    isSearchable={isSearchable}
    closeMenuOnScroll={e => {
      if (e.target === document) {
        return true
      } else {
        return false
      }
    }}
  />
}
