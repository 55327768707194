import React, {useContext, useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Popup from 'reactjs-popup';

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import {LoginContext} from "../App.js"


const ForgotPassword = (props) => {

  const language = useStore(x => x.language.language)

  const [emailField, setEmailField] = useState("Введите корректный Email");
  const [minEmailField, setMinEmailField] = useState("Слишком короткий Email");
  const [requiredField, setRequiredField] = useState("Обязательное поле");
  const [resetLinkSentMessage, setResetLinkSentMessage] = useState("На ваш e-mail была отправлена ссылка для сброса пароля.");
  const [resetLinkErrorMessage, setResetLinkErrorMessage] = useState("Ошибка!");

useEffect(() => {

    if (language == 'ru'){
      setEmailField("Введите корректный Email");
      setMinEmailField("Слишком короткий Email");
      setRequiredField("Обязательное поле");
      setResetLinkSentMessage("На ваш e-mail была отправлена ссылка для сброса пароля.");
      setResetLinkErrorMessage("Ошибка!");
    };

    if (language == 'uk'){
      setEmailField("Введіть коректний Email");
      setMinEmailField("Занадто короткий Email");
      setRequiredField("Обов'язкове поле");
      setResetLinkSentMessage("На ваш e-mail було відправлене посилання для скидання паролю.");
      setResetLinkErrorMessage("Помилка!");
    };

    if (language == 'en'){
      setEmailField("Enter the correct Email");
      setMinEmailField("Email is too short");
      setRequiredField("Required field");
      setResetLinkSentMessage("A link to reset your password has been sent to your e-mail.");
      setResetLinkErrorMessage("Error!");
    };

  }, [language]);


  const schema = yup.object().shape({
    email: yup
      .string()
      .email(emailField)
      .min(2, minEmailField)
      .required(requiredField)
  });

  const [passShow, setPassShow] = React.useState("password");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);


  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert1 = () => {
    Toast.fire({
      icon: 'success',
      title: resetLinkSentMessage
    })
  }

  const submitAlert2 = () => {
    Toast.fire({
      icon: 'error',
      title: resetLinkErrorMessage
    })
  }

  const onSubmit = async (data) => {
    let response = await fetch(`https://${window.location.host}/directus/auth/password/request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        email: data.email,
        reset_url: `https://${window.location.host}/resetpw`
      })
    })

    if (response.ok) {
      submitAlert1();
      props.closeLoginPopUp();
      //navigate('/');
    } else {
      submitAlert2();
    }
  };

  const watchEmail = watch("email");

  let txt_rus = "Забыли пароль?";
  let txt_ukr = "Забули пароль?";
  let txt_eng = "Forgot your password?";

  return (
      <section className="overflow-hidden relative fadeIn">
      <div className="container h-full flex flex-col justify-center">
        <h2 className="AlternativeText font-semibold mb-5 text-[30px] text-center sm:text-3xl" onClick={() => props.closeThisPopUp()}>
          <T uk = {txt_ukr} en = {txt_eng}>{txt_rus}</T>
        </h2>
        <p className="text-white/70 text-center w-[560px] mx-auto mb-[60px] sm:w-full" onClick={() => props.closeThisPopUp()}>
          <T
            uk = "Введіть адресу електронної пошти, вказану в вашому обліковому записі"
            en = "Enter the email address asociated with your account"
            >Введите адрес электронной почты, указанный в вашей учётной записи
          </T>
        </p>
        <form
          className="bg-darkGray  p-[60px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12"
          onSubmit={handleSubmit(onSubmit)}
              >
          <MdOutlineClose className="ButtonText absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl" onClick={() => props.closeThisPopUp()} />
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="email"
          >
            <T uk = "Ваша пошта" en = "Your mail">Ваша почта</T>
            <input
              className={`InputField
              ${errors?.email ? "border-[#D81F26]" : "border-gray"}
              ${
                watchEmail && !errors?.email ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border`}
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              {...register("email")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.email?.message}
            </p>
          </label>
          <button
            className="ButtonColor AlternativeButtonText btn btn-primary text-xs cursor-pointer"
            type="submit"
          >
            <T uk = "СКИНУТИ" en = "RESET">СБРОСИТЬ</T>
          </button>
        </form>
      </div>
    </section>
  );
};

export default ForgotPassword;
