import React from "react";

import ArrowRightImg from "../assets/img/arrow-right.png";

const Biography = (props) => {
  return (
    <section className="py-[20px] md:py-[20px] sm:py-[20px]">
      <div className="">
        <div className="Background3 flex justify-between gap-[100px] bg-darkGray p-[60px] md:p-[40px] md:gap-14 sm:flex-col sm:gap-7">
          <h2 className="font-medium">
            <T uk = "Біографія" en = "Biography">Биография</T>
          </h2>
          <div className="flex flex-col">
            <p className="text-color-theme text-lightGray mb-8">
              <T>{props.content}</T>
            </p>
            {/* <a href="/" className="flex items-center gap-[10px]">
              <img className="w-[16px]" src={ArrowRightImg} alt="" />
              <h3>Показать больше</h3>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Biography;
