import React from "react";

import {useState, useEffect, useMemo, fetchData, setState} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from '../components/LineChart';
import { HashLink } from 'react-router-hash-link';

window.round = (x, n) => parseFloat(parseFloat(x).toFixed(n))


const UserStockList = (item, index) => {

  function getStockData(personName) {
      const data = JSON.parse(localStorage.getItem('stockData'));
      return data[personName];
    }

    return(
    <tbody key={index}>

        <div className="abouthidden Background3 flex md:flex mt-[20px] bg-[#313132] h-auto md:w-full">
            <div className="flex w-full mx-4 my-4 p-3 pr-0">
                <div className="flex flex-col w-[50%]">
                    <div className="flex mb-[10px] py-auto">
                        <Link to={`/bloggerbuy/${item.id}`}>
                                <img
                                    className="h-[70px] w-[70px] mobileM:w-[60px] mobileM:h-[60px] bg-cover bg-center"
                                    src={item.image}
                                    alt=""
                                />
                        </Link>
                        <Link to={`/bloggerbuy/${item.id}`}>
                            <h2 className="text-xl ml-[20px] mt-[20px] mobileM:mt-[15px] font-semibold text-center">
                                {item.tokenName}
                            </h2>
                        </Link>
                    </div>
                    <h2 className= "max-w-[220px] mb-[20px] ml-[-5px]">
                        {!item.is_ipo && <LineChart height={60} lineColor = {"#0ec40e"} altLineColor = {"#E32636"} chartData = {getStockData(item.title)}/>}
                    </h2>
                    <HashLink className="ButtonText btn bg-[#0ec40e] w-[100px] text-[16px]" to={`/bloggerbuy/${item.id}#converter`}>
                        <T uk = "Торгувати" en = "Trade">Торговать</T>
                    </HashLink>
                </div>
                <div className="flex flex-col my-auto w-[50%]">
                    <h2 className="text-[11px] font-roboto text-center opacity-50 mb-[-5px]">
                        <T uk = "Кількість" en = "Quantity">Количество</T>
                    </h2>
                    <h2 className="text-[16px] font-semibold text-center">
                        {window.round(item.quantity,2)}
                    </h2>
                    <h2 className="text-[11px] font-roboto text-center opacity-50 mt-[12px] mb-[-5px]">
                        <T uk = "Вартість акції" en = "Share price">Стоимость акции</T>
                    </h2>
                    <h2 className="text-[16px] font-semibold text-center">
                        {window.round(item.cost,2)}$
                    </h2>
                    <h2 className="text-[11px] font-roboto text-center opacity-50 mt-[12px] mb-[-5px]">
                        <T uk = "Загальна вартість" en = "Total price">Общая стоимость</T>
                    </h2>
                    <h2 className="text-[16px] font-semibold text-center">
                        {window.round(item.cost * item.quantity,2)}$
                    </h2>
                </div>
            </div>

          </div>

        <tr className="lg:hidden h-[85px]">
            <td className="pr-4 py-3 border-y border-white/10 md:pl-0">
                <div className="flex gap-[20px]">
                <img onClick={(e) => {e.preventDefault();window.location.href=`/bloggerbuy/${item.id}`;}} className="w-[50px] sm:w-[40px] sm:h-[40px] imghover" src={item.image} alt="" />
                    <div className="flex flex-col gap-1">
                        <Link to={`/bloggerbuy/${item.id}`}>
                        <h3 className="text-sm py-4">{item.tokenName}</h3>
                        </Link>
                    </div>
                </div>
            </td>
            <td className="md:text-center px-4 py-3 border-y border-white/10 text-sm">
                {window.round(item.quantity,2)}
            </td>
            <td className="md:text-center px-4 py-3 border-y border-white/10 text-sm">
                {window.round(item.cost,2)}$
            </td>
            <td className="md:text-center px-4 py-3 border-y border-white/10 text-sm">
                {window.round(item.cost * item.quantity,2)}$
            </td>
            <td className= "px-4 py-3 text-lg text-gray-900 border-y border-white/10">
                <div className= "w-[200px]">
                    {!item.is_ipo &&<LineChart height = {60} lineColor = {"#0ec40e"} altLineColor = {"#E32636"} chartData = {getStockData(item.title)}/>}
                </div>
            </td>
            <td className="w-10 text-center border-y border-white/10 md:pr-0">
                <HashLink className="ButtonText btn bg-[#0ec40e] w-[100px]" to={`/bloggerbuy/${item.id}#converter`}>
                    <T uk = "Торгувати" en = "Trade">Торговать</T>
                </HashLink>
            </td>
        </tr>
    </tbody>
);
};

export default UserStockList;
