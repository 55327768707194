import React, {useContext, useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams} from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Popup from 'reactjs-popup';

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


import {LoginContext} from "../App.js"


const ResetPassword = (props) => {

  const language = useStore(x => x.language.language)
  const [searchParams, setSearchParams] = useSearchParams();

  const [minPasswordField, setMinPasswordField] = useState("Слишком короткий пароль");
  const [requiredField, setRequiredField] = useState("Обязательное поле");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Ошибка!");
  const [passwordSuccessMessage, setPasswordSuccessMessage] = useState("Новый пароль успешно установлен.");

useEffect(() => {

    if (language == 'ru'){
      setMinPasswordField("Слишком короткий пароль");
      setRequiredField("Обязательное поле");
      setPasswordErrorMessage("Ошибка!");
      setPasswordSuccessMessage("Новый пароль успешно установлен.");
    };

    if (language == 'uk'){
      setMinPasswordField("Занадто короткий пароль");
      setRequiredField("Обов'язкове поле");
      setPasswordErrorMessage("Помилка!");
      setPasswordSuccessMessage("Новий пароль успішно встановлено.");
    };

    if (language == 'en'){
      setMinPasswordField("The password is too short");
      setRequiredField("Required field");
      setPasswordErrorMessage("Error!");
      setPasswordSuccessMessage("New password has been successfully set");
    };

  }, [language]);


  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, minPasswordField)
      .required(requiredField),
  });

  const [passShow, setPassShow] = React.useState("password");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);


  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    width:'40em',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const submitAlert1 = () => {
    Toast.fire({
      icon: 'success',
      title: passwordSuccessMessage
    })
  }

  const submitAlert2 = () => {
    Toast.fire({
      icon: 'error',
      title: passwordErrorMessage
    })
  }

  const onSubmit = async (data) => {
    let response = await fetch(`https://${window.location.host}/directus/auth/password/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        token: searchParams.get("token"),
        password: data.password
      })
    })

    if (response.ok) {
      submitAlert1();
      navigate('/')
    } else {
      submitAlert2();
    }
  };

  const watchEmail = watch("email");
  const watchPassword = watch("password");

  let txt_rus = "Сбросить пароль";
  let txt_ukr = "Скинути пароль";
  let txt_eng = "Reset your password";

  return (
    <section className="overflow-hidden relative fadeIn flex h-screen">
      <div className="container h-full flex flex-col justify-center">
        <h2 className="AlternativeText font-semibold mb-5 text-[30px] text-center sm:text-3xl" onClick={() => navigate('/')}>
          <T uk = {txt_ukr} en = {txt_eng}>{txt_rus}</T>
        </h2>
        <p className="text-white/70 text-center w-[560px] mx-auto mb-[60px] sm:w-full" onClick={() => navigate('/')}>
          <T
            uk = "Скинути пароль"
            en = "Reset your password"
            >Сбросить пароль
          </T>
        </p>
        <form
          className="bg-darkGray  p-[60px] w-[560px] mx-auto flex flex-col gap-[30px] mb-5 relative z-10 sm:w-full sm:px-5 sm:py-12"
          onSubmit={handleSubmit(onSubmit)}
              >
          <MdOutlineClose className="ButtonText absolute cursor-pointer right-2 top-2 text-3xl sm:text-2xl" onClick={() => navigate('/')} />
          <label
            className="flex flex-col gap-1 text-xs text-lightGray relative"
            htmlFor="password"
          >
            <T uk = "Пароль" en = "Password">Пароль</T>
            <input
              className={`InputField
              ${errors?.password ? "border-[#D81F26]" : "border-gray"}
              ${
                watchPassword && !errors?.password ? "!border-[green]" : ""
              } bg-gray p-3 text-white outline-none border tracking-[0.4em]`}
              type={passShow}
              id="password"
              name="password"
              autoComplete="off"
              {...register("password")}
            />
            <p className="text-[#D81F26] absolute -bottom-4">
              {errors?.password?.message}
            </p>
            {passShow === "password" ? (
              <AiOutlineEye
                onClick={() => setPassShow("text")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => setPassShow("password")}
                className="absolute top-1/2 right-2 text-2xl -mt-[2px] cursor-pointer"
              />
            )}
          </label>
          <button
            className="ButtonColor AlternativeButtonText btn btn-primary text-xs cursor-pointer"
            type="submit"
          >
            <T uk = "СКИНУТИ" en = "RESET">СБРОСИТЬ</T>
          </button>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
