import React from "react";

import {useState, useEffect, useMemo} from "react";
import bloggersimg from "../assets/img/mainbloggers.png";
import searchimg from "../assets/img/search.png";
import Elis from "../assets/img/elis.png";
import gr from "../assets/img/gr.png";
import { Link } from "react-router-dom";
import LineChart from '../components/LineChart';
import BloggersList from '../components/BloggersList';
import Select from "react-select";
import { CiSearch } from "react-icons/ci"
import {useCollapse} from 'react-collapsed';
import useLocalStorageState from 'use-local-storage-state';
import {useStore} from '../Store';

const Bloggers = () => {

    function defaultPersons(){
        const defaultPers = [];
        const fetchData = async () => {
          const response = (await directus.items('Person').readByQuery({
            limit: 10,
            fields: ['*', 'activity.directus_files_id', 'category.person_category_id.name'],
            filter: {
              is_approved: {
                '_eq': true,
              },
              is_ipo: {
                '_eq': false,
              }
            }
          })).data;
            response.forEach( (x) => {

                let person = {};

                person.id = x.id;
                person.img = `https://directus.${window.location.host}/assets/${x.head_photo}`;
                person.title = x.name;
                person.categories = x.category.map(item => (item.person_category_id.name));
                person.subtitle = x.category.map(item => (item.person_category_id.name)).join(', ');
                person.amount = x.share_quantity;
                person.last = x.share_price;
                person.cap = x.capitalisation;
                person.biography = x.biography;
                person.date = x.date_created;
                person.listing_date = x.listing_date;
                person.purchase_price = x.purchase_price;
                person.listing_price = x.listing_price;
                person.token_sold = x.token_sold ;
                person.token_amount = x.token_amount;
                defaultPers.push(person);

            });

            setIsLoaded(x => true);

            }
            fetchData();

            return defaultPers;
      };

    const [persons, setPersons] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSearchable] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({ label: "Все категории", value: '' });
    const [isExpanded, setExpanded] = useLocalStorageState('isExpanded', {
        defaultValue: true
    })

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    function getFilteredByCategoryList() {
        if (selectedCategory.value == "") {
            return persons;
        }

        return persons.filter((item) => item.categories.includes(selectedCategory.value));
    };

    function handleCategoryChange(event) {
        setSelectedCategory(event);
    }

    useEffect( () => {
        setPersons(defaultPersons());
    }, []);

    const ruCategoryOptions = [
        { label: "Все категории", value: '' },
        { label: "Певец", value: 'Певец' },
        { label: "Певица", value: 'Певица' },
        { label: "Исполнитель", value: 'Исполнитель' },
        { label: "Исполнительница", value: 'Исполнительница' },
        { label: "Боксёр", value: 'Боксёр' }
      ];

      const ukCategoryOptions = [
        { label: "Усі категорії", value: '' },
        { label: "Співак", value: 'Певец' },
        { label: "Співачка", value: 'Певица' },
        { label: "Виконавець", value: 'Исполнитель' },
        { label: "Виконавиця", value: 'Исполнительница' },
        { label: "Боксер", value: 'Боксёр' }
      ];

      const enCategoryOptions = [
        { label: "All categories", value: '' },
        { label: "Male singer", value: 'Певец' },
        { label: "Female singer", value: 'Певица' },
        { label: "Male performer", value: 'Исполнитель' },
        { label: "Female performer", value: 'Исполнительница' },
        { label: "Boxer", value: 'Боксёр' }
      ];


      const [categoryOptions, setСategoryOptions] = useState(ruCategoryOptions);


      const language = useStore(x => x.language.language)

      useEffect(() => {

        if (language == 'ru'){

          setСategoryOptions(ruCategoryOptions);

          for(const category of ruCategoryOptions){
            if (selectedCategory.value == category.value) {
              setSelectedCategory({ label: category.label, value: category.value })
            }
          }

        };

        if (language == 'uk'){

          setСategoryOptions(ukCategoryOptions);

          for(const category of ukCategoryOptions){
            if (selectedCategory.value == category.value) {
              setSelectedCategory({ label: category.label, value: category.value })
            }
          }
        };

        if (language == 'en'){

          setСategoryOptions(enCategoryOptions);

          for(const category of enCategoryOptions){
            if (selectedCategory.value == category.value) {
              setSelectedCategory({ label: category.label, value: category.value })
            }
          }

        };

      }, [language]);

    const filteredByCategoryList = useMemo(getFilteredByCategoryList, [selectedCategory, persons]);
    //const filteredList = getFilteredList();

    const [textQuery, setTextQuery] = useState('');


    function getFilteredByTextList() {

        if (textQuery === "" || textQuery.length < 3){
            return filteredByCategoryList;
        }

        return filteredByCategoryList.filter(pearson => pearson.title.toLowerCase().includes(textQuery.toLowerCase()) ||
        pearson.biography.toLowerCase().includes(textQuery.toLowerCase()) ||
        pearson.subtitle.toLowerCase().includes(textQuery.toLowerCase()));

    };


    function handleTextChange(event){
        setTextQuery(event.target.value);
    }

    const filteredByTextList = getFilteredByTextList();

    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    function saveInput(){
        console.log('Saving data');
    }

    const processChange = debounce(() => saveInput());

    const [sortQuery, setSortQuery] = useState({ label: "Сортировать", value: 'capitalization' });

    const ruSortOptions = [
        { label: "Сортировать", value: 'default' },
        { label: "Имя (А-Я)", value: 'name' },
        { label: "Капитализация", value: 'capitalization' },
        { label: "Новые", value: 'date' }
      ];

      const ukSortOptions = [
        { label: "Сортувати", value: 'default' },
        { label: "Від (А-Я)", value: 'name' },
        { label: "Капіталізація", value: 'capitalization' },
        { label: "Нові", value: 'date' }
      ];

      const enSortOptions = [
        { label: "Sort", value: 'default' },
        { label: "Name (A-Z)", value: 'name' },
        { label: "Capitalization", value: 'capitalization' },
        { label: "New", value: 'date' }
      ];

      const [sortOptions, setSortOptions] = useState(ruSortOptions);

      useEffect(() => {

       if (language == 'ru'){

          setSortOptions(ruSortOptions);

         for(const option of ruSortOptions){
           if (sortQuery.value == option.value) {
              setSortQuery({ label: option.label, value: option.value })
           }
         }

       };

       if (language == 'uk'){

          setSortOptions(ukSortOptions);

         for(const option of ukSortOptions){
           if (sortQuery.value == option.value) {
              setSortQuery({ label: option.label, value: option.value })
           }
         }
       };

       if (language == 'en'){

          setSortOptions(enSortOptions);

         for(const option of enSortOptions){
           if (sortQuery.value == option.value) {
              setSortQuery({ label: option.label, value: option.value })
           }
         }

       };

     }, [language]);

    function handleSortChange(event){
        setSortQuery(event);
    }

    function getSortedList() {

        if(sortQuery.value === "name"){
            return filteredByTextList.sort((a, b) => (a.title > b.title) ? 1 : -1);
        }else if(sortQuery.value === "date"){
            return filteredByTextList.sort((a, b) => (a.date > b.date) ? 1 : -1);
        }else if(sortQuery.value === "capitalization"){
            return filteredByTextList.sort((a, b) => (round(a.listing_price * a.token_sold, 2) > round(b.listing_price * b.token_sold, 2)) ? 1 : -1).reverse();
        }else {
            return filteredByTextList.sort((a, b) => (a.id > b.id) ? 1 : -1).reverse();
        }

    };

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          marginTop: "-5px",
          height: "30px",
          fontWeight: state.isSelected ? "900" : "normal",
          color: state.isSelected ? "var(--color)" : "#fffffff",
          backgroundColor: state.isSelected ? "transparent" : "transparent",
          borderRadius: "4px",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--color)",
          backgroundColor: "transparent",
        }),
        control: (base, state) => ({
          ...base,
          background: "transparent",
          color: "#ffffff",
          borderColor: "none",
          borderWidth: "0",
          boxShadow: state.isFocused ? null : null,
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 0,
          paddingTop: 0,
          backgroundColor: "rgba(49,49,49,0.25) !important",
          backdropFilter: "blur(4px) !important",
        }),
        input: (provided) => ({
          ...provided,
          color: "transparent",
          boxShadow: "none",
        }),
      };

    const sortedList = getSortedList();

    if( !isLoaded ) {
      return (<></>)
    };

    return (
        <>
        <div className="flex justify-center">
            <button
            className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl pl-[72px]"
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}
            >
              {isExpanded ?
                <T uk = "Приховати" en = "Collapse">Скрыть</T> :
                <T uk = "Показати" en = "Expand">Показать</T>}
            </button>
          </div>
            <div {...getCollapseProps()} className="container flex mt-[70px] fadeIn md:mt-[80px] sm:mt-[64px]">
                <h1 className="flex w-[464px] text-[44px] md:text-[28px] md:text-center md: font-semibold leading-[120%] mt-[70px] md:mx-auto md:my-auto md:mb-[10px]">
                    <T
                        uk = "Ставай медійною персоною та монетизуй свою популярність"
                        en = "Become a media person and monetize your popularity"
                        >
                        Становись медийной личностью и монетизируй свою популярность
                    </T>
                </h1>
                <div className="ml-[20px]">
                    <img src={bloggersimg} className="w-[652px] content-end md:hidden" />
                </div>
            </div>
            <div>
                <div className="mx-auto pt-[70px] md:pt-[20px] pl-[72px] md:pl-[5px]" >
                    <div className="flex md:flex-col md:flex-nowrap md:mx-[10px]">
                        <div className="flex border InputBorder input-group placeholder:pl-[12px] sm:ml-[10px] sm:mr-[10px] font-roboto md:mb-[10px] bg-transparent">
                        <Ru>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Поиск"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Ru>
                        <Uk>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Пошук"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </Uk>
                        <En>
                            <input
                                className="placeholder:text-white rounded-none form-control w-[350px] md:text-[10px] md:w-full h-[48px] ml-[1px] placeholder:pl-3 font-roboto shadow-none pl-3 outline-none bg-transparent"
                                type="text"
                                placeholder="Search"
                                onChange={handleTextChange}
                                onKeyUp={processChange}
                            />
                        </En>
                            <div className="input-group-append w-[48px] h-[48px]">
                                <CiSearch className="rounded mx-auto my-[10px] w-[28px] h-[28px]" />
                            </div>
                        </div>
                        <div className="w-full justify-between flex md:gap-[0px]">
                            <Select
                                className="my-auto border InputBorder md:flex md:w-full md:border-none py-[6px] w-[200px] h-[50px] md:w-[50%] ml-[20px] md:ml-[0px] md:mr-[0px] mr-[1px] font-roboto option:pl-[12px] text-center md:text-left"
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                                styles={customStyles}
                                options={categoryOptions}
                                isSearchable={isSearchable}
                            />
                            {/*<select className="w-[268px] md:w-[100px] md:text-[10px] h-[48px] bg-[#404040] ml-[1px] font-roboto option:pl-[12px]">
                            <option>Все страны</option>
                                <option>Все страны</option>
                            </select>*/}
                            <Select
                                className="mr-[72px] md:w-full md:mr-0 ml-auto h-[38px] w-[170px] md:w-[50%] text-right my-auto"
                                onChange={handleSortChange}
                                value={sortQuery}
                                styles={customStyles}
                                options={sortOptions}
                                isSearchable={isSearchable}
                            />
                        </div>
                    </div>
                </div>
                <div className="mx-auto mb-[60px]">
                    <div className="w-full mx-auto overflow-auto">
                        <table className="table-auto w-full text-left whitespace-no-wrap">
                            <thead className="md:hidden">
                                <tr>
                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl pl-[72px]">
                                        <T uk = "Персона" en = "Person">Личность</T>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                        <T uk = "Остання ціна" en = "Last price">Последняя цена</T>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                        <T uk = "Капіталізація" en = "Capitalization">Капитализация</T>
                                    </th>
                                    <th className="md:text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                        <T uk = "Динаміка за 24г" en = "Dynamics in 24h">Динамика за 24ч</T>
                                    </th>
                                    <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br pr-[72px]"></th>
                                </tr>
                            </thead>
                            {sortedList.map((item) => (
                                <BloggersList key={item.id} {...item} />
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Bloggers;
