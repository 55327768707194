import React from "react";
import {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const MainLayout = () => {

  return (
    <>
    <div
            className='min-h-screen flex justify-between flex-col from-zinc-900 via-zinc-700 to-zinc-900'
        >
      <Header />
      <Outlet />
     <Footer />
     </div>
    </>
  );
};

export default MainLayout;
