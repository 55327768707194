import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/swiper-blog.css";

import BlogImg from "../assets/img/blog.jpg";
import ArrowRightImg from "../assets/img/arrow-right.png";

const Baza = () => {
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    GhostAPI.posts.browse({filter: "tags:[baza-znanii]"}).then(setPosts)
  }, [])

  return (
    <>
      <section className="Background3 md:py-[30px] py-[80px] bg-black">
        <div className="container">
          <a target="_blank" href="https://blog.personalshares.com/tag/baza-znanii/">
            <h2 className="MainText text-[34px] font-semibold mb-[10px] md:text-center leading-9">
              <T uk = "База знань трейдера початківця" en = "Beginner trader's knowledge base">База знаний начинающего трейдера</T>
            </h2>
          </a>
          <h3 className="AlternativeText text-white/50 mb-[60px] md:mb-[35px] md:text-center">
            <T uk = "Дивись. Читай. Дій з Personal Shares" en = "Look. Read. Act with Personal Shares">Смотри. Читай. Действуй с Personal Shares</T>
          </h3>
          <Swiper
            breakpoints={{
              "@0.75": {
                slidesPerView: 1.1,
                spaceBetween: 0,
              },
              "@1.00": {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              "@1.50": {
                slidesPerView: 3.2,
                spaceBetween: 0,
              },
            }}
            slidesPerView={1}
            spaceBetween={16}
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            className="swiper-blog"
          >
            <div>
              {posts.map((item, index) => (
                <SwiperSlide key={`${item.title}_${index}`}>
                  <a target="_blank" href={`${item.url}`}>
                    <div className="flex flex-col overflow-hidden h-[490px] group duration-300">
                      <img className="h-[228px] bg-cover bg-center" src={item.feature_image} alt="" />
                      <div className="flex flex-col bg-[#313132] bg-opacity-25 backdrop-blur px-[40px] py-[30px] duration-300 group-hover:-translate-y-[250px]">
                        <h4 className="text-left font-semibold text-2xl mb-[30px]">
                          {item.title}
                        </h4>
                        <p className="MainText2 mb-[60px] text-white/60">
                          {item.excerpt.slice(0,300)}
                        </p>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          {/* <a href="/" className="flex items-center gap-[10px]">
            <img className="w-[16px]" src={ArrowRightImg} alt="" />
            <h3>Подробнее о платформе</h3>
          </a> */}
        </div>
      </section>
    </>
  );
};

export default Baza;
