import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";


import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/swiper-blog.css";

import NewsImg from "../assets/img/news.jpg";
import ArrowRightImg from "../assets/img/arrow-right.png";

const News = () => {
  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    GhostAPI.posts.browse({ filter: "tags:[news]" }).then(setPosts);
  }, []);

  return (
    <>
      <section className="Background3 py-5 pb-[80px] md:pb-[50px] sm:pb-[30px]">
        <div className="container">
          <a target="_blank" href="https://blog.personalshares.com/tag/news/">
            <h2 className="text-[34px] font-semibold mx-auto mb-[40px] mt-[15px] text-center">
              <T uk = "Новини платформи" en = "Platform News">Новости платформы</T>
            </h2>
          </a>
          <Swiper
            breakpoints={{
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 3.2,
                spaceBetween: 10,
              },
            }}
            slidesPerView={1}
            spaceBetween={16}
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            className="swiper-blog"
          >
            <div>
              {posts.map((item, index) => (
                <SwiperSlide key={`${item.title}_${index}`}>
                  <a target="_blank" href={`${item.url}`}>
                    <div className="flex flex-col overflow-hidden h-[490px] group duration-300">
                      <img
                        className="h-[228px] bg-cover bg-center"
                        src={item.feature_image}
                        alt=""
                      />
                      <div className="flex flex-col bg-[#313132] bg-opacity-25 backdrop-blur px-[40px] py-[30px] duration-300 group-hover:-translate-y-[250px]">
                        <h4 className="AlternativeText text-left font-semibold text-2xl mb-5">
                          {item.title}
                        </h4>
                        <p className="MainText2 mb-[60px] text-white/60 text-left">
                          {item.excerpt.slice(0, 300)}
                        </p>
                        <div className="flex gap-5 text-lightGray">
                          <p>11.11.2019</p>
                          <p><T uk = "5 хв читання" en = "5 min of reading">5 мин чтения</T></p>
                        </div>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          {/*<Link to="https://blog.personalshares.com/tag/news/" className="flex items-center gap-[10px]">
            <img className="w-[16px]" src={ArrowRightImg} alt="" />
             <h3><T uk = "Усі новини" en = "All news">Все новости</T></h3>
          </Link>*/}
        </div>
      </section>
    </>
  );
};

export default News;
