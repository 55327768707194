import React from "react";
import { Link } from "react-router-dom";

import StockImg from "../assets/img/stock.png";
import ArrowRightImg from "../assets/img/arrow-right.png";

const Stock = () => {
  return (
    <>
      <section className="py-[40px] md:py-[20px] sm:py-[20px]">
        <div className="container">
          <div className="flex items-center justify-between lg:flex-col">
            <img className="lg:order-2" src={StockImg} alt="" />
            <div className="flex flex-col lg:order-1">
              <h2 className="text-[34px] font-semibold mb-[40px] md:mb-[20px] sm:text-3xl">
                <T uk = "Як випустити персональні акції?" en = "How to issue personal shares?">Как выпустить персональные акции?</T>
              </h2>
              <p className="MainText text-white/70 mb-5">
                <T
                  uk = "Якщо ти блогер, артист, спортсмен початківець, або соціальний лідер з армією послідовників, тоді на платформі Personal Shares твоя популярність може дати стартовий капітал для чергового проекту без кредитів і позик. Випускай свої персональні акції і торгуй ними."
                  en = "If you are a novice blogger, artist, athlete, or already well-established social leader with army of followers, then on the Personal Shares platform your fame can give you the starting capital for another project without loans. Issue your personal shares and trade them."
                  >Если ты начинающий блоггер, артист, спортсмен, или уже вполне
                  состоявшийся социальный лидер с армией последователей, тогда на
                  платформе Personal Shares твоя известность может дать стартовый капитал
                  для очередного проекта без кредитов и займов. Выпускай свои
                  персональные акции и торгуй ими.
                </T>
              </p>
              <p className="MainText text-white/70 mb-[60px]">
                <T
                  uk = "Переходь на сторінку за кнопкою нижче, де ми зібрали всю інформацію, яка допоможе тобі випустити свої персональні акції."
                  en = "Go to the page by clicking the button below, where we have collected all the information that will help you to issue your personal shares."
                  >Переходи на страницу по кнопке ниже, где мы собрали всю
                  информацию, которая поможет тебе выпустить свои персональные
                  акции.
                </T>
              </p>
              <Link to="/about" className="flex items-center gap-[10px]" onClick={() => window.scrollTo(0, 0)}>
                <img className="w-[16px] h-[16px]" src={ArrowRightImg} alt="" />
                <h4><T uk = "Детальніше про випуск акцій" en = "Learn more about the issue of shares">Подробнее про выпуск акций</T></h4>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stock;
