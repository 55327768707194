import React from "react";
import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';


const LoginRedirect = () => {
  const navigate = useNavigate();

useEffect( () => {
  const login = async () => {
    await directus.auth.refresh();
    let currentUser = await directus.users.me.read();
    localStorage.setItem('user', JSON.stringify(currentUser))
    navigate('/');
  }
  login();

}, []);

return (
  <>
  </>
)};

export default LoginRedirect;
