import { Line } from "react-chartjs-2";
import { useState } from "react";
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import s from "../Data.json";

Chart.register(ChartDataLabels);

const generateUserData = (UserData) => {
    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    for (let i = 0; i < 15; i++) {

        UserData.push(
            {
                "date": i,
                "price": getRandomInt(-25, 25)
            }
        );

    }
}

function computePercentageDifference(num1, num2) {
  let difference = num1 - num2;
  if (difference === 0) {
    return 0;
  }
  let percentageDifference = (difference / num2) * 100;
  return percentageDifference;
}

function LineChart(props) {

  let UserData = [];
  if(props.chartData && props.chartData.length > 0){
    UserData = props.chartData;
  }else{
    generateUserData(UserData);
  }

  let number = computePercentageDifference(UserData[UserData.length-1].price, UserData[0].price);

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.date),
    datasets: [
      {
        label: "",
        data: UserData.map((data) => data.price),
        backgroundColor: [
          "white"
        ],
        cubicInterpolationMode: 'monotone',
        pointStyle: 'circle',
        borderColor: function(context) {
          const chart = context.chart;
          const {ctx, chartArea} = chart;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        borderWidth: 2,
        pointRadius: () => {
          let arr = Array(UserData.length).fill(0);
          arr[arr.length - 1] = 3;
          return arr;
        },
        datalabels: {
          align: 'right',
        }
      },
    ],
  });

    //const totalDuration = 200;
    //const delayBetweenPoints = totalDuration / UserData.length;
    //const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
  const [options, setOptions] = useState({
    // animation: {
    //     x: {
    //       type: 'number',
    //       easing: 'linear',
    //       duration: delayBetweenPoints,
    //       from: NaN, // the point is initially skipped
    //       delay(ctx) {
    //         if (ctx.type !== 'data' || ctx.xStarted) {
    //           return 0;
    //         }
    //         ctx.xStarted = true;
    //         return ctx.index * delayBetweenPoints;
    //       }
    //     },
    //     y: {
    //       type: 'number',
    //       easing: 'linear',
    //       duration: delayBetweenPoints,
    //       from: previousY,
    //       delay(ctx) {
    //         if (ctx.type !== 'data' || ctx.yStarted) {
    //           return 0;
    //         }
    //         ctx.yStarted = true;
    //         return ctx.index * delayBetweenPoints;
    //       }
    //     }
    // },
    interaction: {
        intersect: false
      },
    events: [],
    tooltips: {enabled: false},
    hover: {mode: null},
    layout: {
      padding: {
        top: () => {
            if (props.height >= 80) return props.height/5
             else return 0;
        },
        bottom: () => {
            if (props.height >= 80) return props.height/5
             else return 0;
        },
        right: 50
      }
  },
    plugins: {
      datalabels: {
        font: {
          weight: 'bold'
        },

        color: () => {
            if (number > 0) {
                return props.lineColor;
            } else if (number === 0){
                return props.lineColor;
            } else {
                return props.altLineColor;
            }
        },
        formatter: function(value, context) {
            if (context.dataIndex === context.dataset.data.length - 1)
            {
                if (number > 0) {
                    return "  + " + Math.round(number) + "%";
                } else if (number === 0){
                    return "   " + Math.round(number) + "%";
                } else {
                    return "  - " + Math.round(Math.abs(number)) + "%";
                }
            }
            return "";
        }
      },
      legend: {
          display: false,
      },
  },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
        },
        grid: {
          display: false,
        }
      },
      y: {
        title: {
          display: false,
        },
        display: false,
        grid: {
          display: false,
        }
      }
    }
  });

  let width, height, gradient;

  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
      
      if (number > 0) {
          gradient.addColorStop(1, props.lineColor);
      } else if (number == 0){
          gradient.addColorStop(1, props.lineColor);
      } else {
          gradient.addColorStop(1, props.altLineColor);
      }
    }

  return gradient;
}


  return (
        <Line id = {'chart'} data={userData} options = {options}
        style={{ minHeight: props.height, maxHeight: props.height }} redraw = {true}/>
  );
}

export default LineChart;
