import React, {useContext} from "react";
import Catalog from "../components/Catalog";
import About from "../components/About";
import Table from "../components/Table";
import Baza from "../components/Baza";
import Stock from "../components/Stock";
import News from "../components/News";
import Registration from "../components/Registration";

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import MainImg from "../assets/img/main.png";
import MainTitle from "../assets/img/main-title.png";
import { Link } from "react-router-dom";

import {LoginContext} from "../App.js"

const Home = () => {
const [isLoggedIn, setIsLoggedIn] = useContext(LoginContext);

  return (
    <>
      <div className="container fadeIn md:mt-[70px] sm:mt-[64px]">
        <div className="flex items-center justify-between gap-5 pt-7 pb-[90px] h-[calc(100vh_-_78px)] lg:h-full lg:py-[80px] md:py-[50px] sm:py-[30px]">

        <Zoom duration={2000} bottom><img
            className="w-[900px] h-[646px] ml-[-150px] mt-[-70px] md:w-[760px] md:h-[538px] md:ml-[0px] md:mt-[0px] lg:w-1/2 lg:h-auto md:hidden z-[-10]"
            src={MainImg}
            alt="Personal shares"
          /></Zoom>

          <div className="flex flex-col w-[385px] lg:w-full">
            <img
              className="Logo mb-[50px] md:w-[400px] md:mx-auto"
              src={MainTitle}
              alt="Personal shares"
            />
            <p className="text-lg mb-[80px] md:text-center">
              <T
                uk = 'Купуй і продавай персональні акції публічних осіб на єдиній в світі біржі персональних акцій.'
                en = "Buy and sell personal shares of public persons on the world's only personal shares market."
              >
                Покупай и продавай персональные акции публичных личностей на
                единственной в мире бирже персональных акций.
              </T>
            </p>
            <div className="flex gap-6">
              <Link to="/personalities" className="btn btn-green bg-[#0ec40e] text-xs font-bold" onClick={() => posthog.capture('cta')}>
                <T uk = "Купити акції" en = "Buy shares">Купить акции</T>
              </Link>
              <Link to="/personalities" className="btn btn-red bg-[#E32636] text-xs font-bold" onClick={() => posthog.capture('cta')}>
                <T uk = "Продати акції" en = "Sell shares">Продать акции</T>
              </Link>


            </div>
          </div>
        </div>
      </div>
      <Fade bottom><Catalog /></Fade>
      <About />
      {/* <Fade bottom><Table /></Fade> */}
      <Fade bottom><Baza /></Fade>
      <Fade bottom><Stock /></Fade>
      <Fade bottom> <News /></Fade>
      {(!isLoggedIn) ? (
        <Fade bottom><Registration /></Fade>): (
          <></>
      )}
    </>
  );
};

export default Home;
