import React from "react";
import { Link } from "react-router-dom";

import UserImg from "../assets/img/usik.png";
import ArrowRightImg from "../assets/img/arrow-right.png";
import DefaultAvatar from "../assets/img/default_avatar.png";

window.round = (x, n) => parseFloat(parseFloat(x).toFixed(n))

const LastActivity = (props) => {
  function formatTime(date) {

  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return hours + ':' + minutes;
}

  
  return (
    <section className="py-[40px] md:py-[40px] sm:py-[20px]">
      <div className="container">
        <h2 className="font-semibold text-3xl mb-[50px]">
          <T uk = "Остання активність" en = "Last activity">Последняя активность</T>
        </h2>
        <div className="w-full mx-auto overflow-auto mb-10">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            {props.activity.map((item, index) => (
              <tbody key={index}>
                <tr>
                  <td className="px-4 py-3 border-y border-white/10 pl-[72px] md:pl-0">
                    <div className="flex gap-[35px] sm:gap-[10px] sm:w-[130px] align-middle content-center">
                      <img
                        src={item.user.head_photo ? `https://directus.${window.location.host}/assets/${item.user.head_photo}` : DefaultAvatar}
                        className="w-[53px] sm:w-[40px] md:w-[45px] sm:py-4"
                        alt=""
                      />
                      <h3 className="flex content-center flex-wrap text-sm py-4 md:text-[12px]">{item.user.name || item.user.first_name + ' ' + item.user.last_name}</h3>
                    </div>
                  </td>
                  <td className="px-10 sm:px-4 sm:pl-0 sm:pr-2 py-3 border-y border-white/10 text-sm md:text-[12px]">
                    <span className="text-white">
                      {item.share_buy ? <T uk = "купив" en = "bought">купил</T> : <T uk = "виставив на продаж" en = "put up for sale">выставил на продажу</T>}
                    </span>{" "}
                    <T uk = {`${round(item.shares, 2)} акції`} en = {`${round(item.shares, 2)} shares`}>{`${round(item.shares, 2)} акции`}</T>
                  </td>
                  <td className="px-4 py-3 sm:pr-0 sm:pl-2 border-y border-white/10 text-sm md:text-[12px]">
                    <T uk = {`$${round(item.share_price, 2)} за штуку`} en = {`$${round(item.share_price, 2)} per item`}>{`$${round(item.share_price, 2)} за штуку`}</T>
                  </td>
                  <td className="sm:hidden px-4 py-3 border-y border-white/10 text-sm md:text-[12px]">
                    {formatTime(new Date(item.date_created))}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        {/*<Link to="/" className="flex justify-center items-center gap-[10px]">
          <img className="w-[16px]" src={ArrowRightImg} alt="" />
          <h3><T uk = "Завантажити більше" en = "Download more">Загрузить еще</T></h3>
        </Link>*/}
      </div>
    </section>
  );
};

export default LastActivity;
